import Check from '@sats-group/icons/16/check';
import cn from 'classnames';
import React from 'react';
import LinkButton from '@sats-group/ui-lib/react/link-button';
import Text from '@sats-group/ui-lib/react/text';

import BackgroundImage from 'client/components/background-image/background-image';
import ContentContainer from 'client/components/content-container/content-container';
import Logo from 'client/components/logo/logo';
import RichText from 'client/components/rich-text/rich-text';

import type { SalesPoster as Props } from './sales-poster.types';

const FreeMembership: React.FC<Props> = ({
  background,
  description,
  disclaimer,
  link,
  logo,
  title,
  uniqueSellingPoints,
}) => (
  <div
    className={cn('sales-poster', {
      'sales-poster--custom-background': background,
    })}
  >
    {background ? (
      <div className="sales-poster__custom-background">
        <BackgroundImage {...background} />
      </div>
    ) : null}
    <div className="sales-poster__content">
      <ContentContainer>
        <div className="sales-poster__inner">
          <header className="sales-poster__logo">
            <Logo {...logo} size={Logo.sizes.large} />
          </header>
          <main className="sales-poster__card">
            <div className="sales-poster__section">
              <div className="sales-poster__title">
                <Text
                  elementName="h1"
                  italic
                  size={Text.sizes.headline1}
                  theme={Text.themes.headline}
                >
                  {title}
                </Text>
              </div>
              {description ? <RichText {...description} /> : null}
              {uniqueSellingPoints.length ? (
                <ul className="sales-poster__usps">
                  {uniqueSellingPoints.map(entry => (
                    <li className="sales-poster__usp" key={entry}>
                      <div aria-hidden>
                        <Check />
                      </div>
                      <Text>{entry}</Text>
                    </li>
                  ))}
                </ul>
              ) : null}
            </div>
            <div className="sales-poster__section">
              <div>
                <LinkButton {...link} variant={LinkButton.variants.cta} />
              </div>
              {disclaimer ? (
                <div className="sales-poster__disclaimer">
                  <RichText {...disclaimer} />
                </div>
              ) : null}
            </div>
          </main>
        </div>
      </ContentContainer>
    </div>
  </div>
);

export default FreeMembership;
