import React from 'react';

import Elixia from '@sats-group/ui-lib/react/logos/elixia';
import ElixiaSmall from '@sats-group/ui-lib/react/logos/elixia-small';
import Sats from '@sats-group/ui-lib/react/logos/sats';
import SatsSmall from '@sats-group/ui-lib/react/logos/sats-small';

import OccasionsLogo from '../occasions-logo/occasions-logo';

import type { Logo as Props } from './logo.types';
import { brands, colors, sizes } from './logo.types';

const Logo: React.FC<Props> & {
  colors: typeof colors;
  brands: typeof brands;
  sizes: typeof sizes;
} = ({
  brand = brands.sats,
  color = colors.light,
  href,
  label,
  size = sizes.small,
}) => {
  const useOccasionsLogo = true; //NOTE: This should only be temporary and not a permanent feature (atleast for now)

  const L =
    useOccasionsLogo && size === 'small' ? ( //NOTE: There's no occasions logo in 'large' size https://www.figma.com/design/ZigDYZUrzJDM8RQumAFozjYJ/sats-ds-assets?node-id=1-5&p=f&t=iDbtEJdcQNajFbqQ-0
      <OccasionsLogo brand={brand} color={color} />
    ) : brand === brands.sats ? (
      size === sizes.large ? (
        <Sats />
      ) : (
        <SatsSmall />
      )
    ) : size === sizes.large ? (
      <Elixia />
    ) : (
      <ElixiaSmall />
    );

  return (
    <div className={`logo logo--${color}`}>
      {href ? (
        <a aria-label={label} className="logo__link" href={href}>
          {L}
        </a>
      ) : (
        L
      )}
    </div>
  );
};

Logo.colors = colors;
Logo.brands = brands;
Logo.sizes = sizes;

export default Logo;
