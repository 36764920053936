import React from 'react';

import Text from '@sats-group/ui-lib/react/text';

import ContentContainer from 'components/content-container/content-container';
import ImageLink from 'components/image-link/image-link';
import Layout from 'components/layout/layout';
import List from 'components/list/list';

import type { ListPage as Props } from './list-page.types';

const ListPage: React.FunctionComponent<Props> = ({
  layout,
  list = [],
  title,
}) => (
  <Layout {...layout}>
    <div className="list-page">
      <ContentContainer>
        <div className="list-page__title">
          <Text
            elementName="h1"
            size={Text.sizes.headline2}
            theme={Text.themes.headline}
            italic
          >
            {title}
          </Text>
        </div>

        <div className="list-page__content">
          <List theme={List.themes.grid}>
            {list.map(link => (
              <ImageLink key={link.href} {...link} />
            ))}
          </List>
        </div>
      </ContentContainer>
    </div>
  </Layout>
);

export default ListPage;
