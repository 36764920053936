import ArrowRight from '@sats-group/icons/24/arrow-right';
import React from 'react';

import Text from '@sats-group/ui-lib/react/text';

import CleanLink from '../clean-link/clean-link';
import ContentContainer from '../content-container/content-container';

import type { Breadcrumbs as Props } from './breadcrumbs.types';

const Breadcrumbs: React.FunctionComponent<Props> = ({
  landmarkLabel,
  links,
  title,
  width,
}) => (
  <nav aria-label={landmarkLabel} className="breadcrumbs">
    <ContentContainer theme={width ? width : ContentContainer.themes.wide}>
      <ol className="breadcrumbs__list">
        {links.map((link, index) => (
          <li className="breadcrumbs__item" key={`${index}-${link.text}`}>
            {index === 0 ? null : (
              <div className="breadcrumbs__icon">
                <ArrowRight />
              </div>
            )}
            {link.href ? (
              <CleanLink
                className="breadcrumbs__link"
                href={link.href}
                text={link.text}
                theme={CleanLink.themes.noUnderline}
              />
            ) : (
              <span className="breadcrumbs__text">
                <Text>{link.text}</Text>
              </span>
            )}
          </li>
        ))}
        <li aria-current="page" className="breadcrumbs__item">
          <div className="breadcrumbs__icon">
            <ArrowRight />
          </div>
          <span className="breadcrumbs__text">
            <Text>{title}</Text>
          </span>
        </li>
      </ol>
    </ContentContainer>
  </nav>
);

export default Breadcrumbs;
