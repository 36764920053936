import { useCallback, useEffect, useRef, useState } from 'react';

import { tuple } from 'shared/tuple';

import { get, overrideStatus } from 'client/helpers/api-helper';

/** Requests `endpoint` at regular intervals and returns the response. */
export default function usePolling<T>(endpoint: string, interval = 60 * 1000) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<T>();
  const intervalRef = useRef<number>(null);

  const serverErrorOverride = () => {
    // NOTE: Clear data on failure so as to not show outdated data
    setData(undefined);
    if (typeof intervalRef.current === 'number') {
      clearInterval(intervalRef.current);
    }
    return true;
  };

  const doRequest = useCallback(() => {
    if (!endpoint) {
      return;
    }
    setIsLoading(true);
    //@ts-ignore
    get<T>(endpoint)
      .then(setData)
      .catch(
        overrideStatus({
          500: serverErrorOverride,
          502: serverErrorOverride,
          503: serverErrorOverride,
          504: serverErrorOverride,
        })
      )
      .catch(() => {
        serverErrorOverride();
      })
      .finally(() => setIsLoading(false));
  }, [endpoint]);

  useEffect(() => {
    doRequest();
    intervalRef.current = Number(setInterval(doRequest, interval));
    return () => {
      if (typeof intervalRef.current === 'number') {
        clearInterval(intervalRef.current);
      }
    };
  }, [doRequest]);

  return tuple([data, isLoading]);
}
