import {
  type Message,
  themes,
} from '@sats-group/ui-lib/react/message/message.types';

import { isString } from 'shared/is';

export type MessageDetail = Pick<Message, 'link' | 'text' | 'theme'>;

const isLink = (input: unknown): input is MessageDetail['link'] => {
  if (!input) {
    return true;
  }

  if (Array.isArray(input)) {
    return false;
  }

  if (typeof input !== 'object') {
    return false;
  }

  if (!('href' in input)) {
    return false;
  }

  if (!('text' in input)) {
    return false;
  }

  return isString(input.href) && isString(input.text);
};

const isTheme = (input: unknown): input is MessageDetail['theme'] => {
  switch (input) {
    case themes.default:
    case themes.error:
    case themes.success:
    case themes.warning: {
      return true;
    }
    default: {
      return false;
    }
  }
};

export const isMessageDetail = (input: unknown): input is MessageDetail => {
  if (!input) {
    return false;
  }

  if (Array.isArray(input)) {
    return false;
  }

  if (typeof input !== 'object') {
    return false;
  }

  if (!('link' in input)) {
    return false;
  }

  if (!isLink(input.link)) {
    return false;
  }

  if (!('text' in input)) {
    return false;
  }

  if (!isString(input.text)) {
    return false;
  }

  if (!('theme' in input)) {
    return false;
  }

  if (!isTheme(input.theme)) {
    return false;
  }

  return true;
};
