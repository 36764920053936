import React from 'react';

import type { OccasionsLogo as Props } from './occasions.logo.types';

const OccasionsLogo: React.FC<Props> = ({ brand = 'sats', color = 'dark' }) => {
  switch (brand) {
    case 'elixia': {
      switch (color) {
        case 'light': {
          return (
            <div className="occasions-logo">
              <svg
                width="127"
                height="41"
                viewBox="0 0 127 41"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_4421_68)">
                  <g clip-path="url(#clip1_4421_68)">
                    <path
                      d="M100.63 28.3908L100.272 24.5552H101.694L101.878 26.8017L103.583 24.5552H105.281L102.086 28.5399L101.563 30.3794H100.065L100.631 28.3908H100.63Z"
                      fill="#FA5333"
                    />
                    <path
                      d="M106.886 25.87L106.628 26.7525H108.392L108.018 28.0673H106.254L105.962 29.0661H108.441L108.067 30.3808H104.09L105.762 24.5567H109.631L109.256 25.8715H106.885L106.886 25.87Z"
                      fill="#FA5333"
                    />
                    <path
                      d="M113.84 24.5552V30.3794H112.493V29.1973H110.604L109.947 30.3794H108.341L111.835 24.5552H113.84ZM112.493 27.884V25.8208L111.345 27.884H112.493Z"
                      fill="#FA5333"
                    />
                    <path
                      d="M118.025 28.124L118.907 30.3794H117.343L116.512 28.0584L115.845 30.3794H114.349L116.012 24.5552H118.301C119.441 24.5552 120.048 25.2126 120.048 26.1115C120.048 27.1684 119.316 28.0256 118.026 28.124H118.025ZM118.615 26.2531C118.615 25.9788 118.415 25.8044 118.058 25.8044H117.151L116.844 26.8867H117.843C118.35 26.8867 118.617 26.6288 118.617 26.2546L118.615 26.2531Z"
                      fill="#FA5333"
                    />
                    <path
                      d="M119.182 29.5475L120.322 28.674C120.614 29.0064 121.021 29.1823 121.428 29.1823C121.935 29.1823 122.152 28.9662 122.152 28.6502C122.152 28.4251 121.986 28.2596 121.72 28.0345L121.337 27.7095C120.938 27.3682 120.605 26.9195 120.605 26.3366C120.605 25.2961 121.488 24.4404 122.826 24.4404C123.833 24.4404 124.499 24.8653 124.764 25.2886L123.616 26.1547C123.342 25.8804 123.017 25.7552 122.701 25.7552C122.327 25.7552 122.118 25.9296 122.118 26.2129C122.118 26.4454 122.318 26.6198 122.543 26.8121L122.934 27.1445C123.317 27.4695 123.674 27.9272 123.674 28.4921C123.674 29.6072 122.908 30.4971 121.361 30.4971C120.346 30.4971 119.597 30.1066 119.182 29.549V29.5475Z"
                      fill="#FA5333"
                    />
                    <path
                      d="M112.33 10.5695L107.91 13.773C109.692 14.3589 110.662 15.8257 110.662 17.6071C110.662 20.2233 108.678 23.1093 104.573 23.1093C101.415 23.1093 99.634 21.4397 99.002 19.7492L102.25 18.0349C102.68 18.9368 103.401 19.5912 104.573 19.5912C105.949 19.5912 106.603 18.6431 106.603 17.6295C106.603 16.8856 106.175 16.0061 104.933 16.0061C104.528 16.0061 104.167 16.0955 103.874 16.2312L102.52 14.0205L107.256 10.5695H102.204L103.286 7.00677H113.39L112.33 10.5695Z"
                      fill="white"
                    />
                    <path
                      d="M112.128 18.7117C112.128 13.8625 115.511 6.69077 121.33 6.69077C124.6 6.69077 125.976 8.6078 125.976 11.1107C125.976 15.9599 122.571 23.1093 116.774 23.1093C113.503 23.1093 112.128 21.1252 112.128 18.7117ZM121.826 11.8113C121.826 10.8409 121.51 10.3221 120.63 10.3221C118.578 10.3221 116.278 15.2846 116.278 17.9902C116.278 18.8474 116.48 19.4794 117.473 19.4794C119.526 19.4794 121.826 14.5184 121.826 11.8113Z"
                      fill="white"
                    />
                    <path
                      d="M124.754 23.3701C120.468 22.3162 120.551 20.186 120.551 20.186L121.44 18.8429C121.44 18.8429 121.355 20.9731 125.642 22.027L124.754 23.3701Z"
                      fill="#DBDEE0"
                    />
                    <path
                      d="M98.0465 11.8307C99.5088 12.4404 100.107 13.5956 100.107 13.5956L99.0601 14.818C99.0601 14.818 98.4624 13.6642 96.9985 13.053L98.0465 11.8307Z"
                      fill="#FA5333"
                    />
                    <path
                      d="M98.5667 5.8306C99.1928 5.69047 99.6609 5.93048 99.6609 5.93048L99.6013 6.57893C99.6013 6.57893 99.1332 6.33744 98.5086 6.47905L98.5682 5.8306H98.5667Z"
                      fill="#FA5333"
                    />
                    <path
                      d="M103.905 2.52424C104.468 1.64324 105.287 1.38684 105.287 1.38684L105.962 2.20672C105.962 2.20672 105.144 2.46461 104.58 3.34561L103.905 2.52573V2.52424Z"
                      fill="#FA5333"
                    />
                    <path
                      d="M107.879 5.7695C108.884 6.43435 109.166 7.38243 109.166 7.38243L108.209 8.14567C108.209 8.14567 107.927 7.19759 106.922 6.53274L107.879 5.7695Z"
                      fill="#FF9283"
                    />
                    <path
                      d="M113.894 2.23357C114.082 1.32723 114.678 0.856171 114.678 0.856171L115.495 1.32127C115.495 1.32127 114.899 1.79233 114.711 2.69867L113.894 2.23357Z"
                      fill="#FA5333"
                    />
                    <path
                      d="M119.723 4.02391C120.474 3.9449 120.988 4.29074 120.988 4.29074L120.83 5.04205C120.83 5.04205 120.316 4.69621 119.565 4.77522L119.723 4.02391Z"
                      fill="#DBDEE0"
                    />
                    <path
                      d="M101.184 11.7934C101.632 11.1852 102.232 11.0361 102.232 11.0361L102.681 11.6577C102.681 11.6577 102.079 11.8053 101.633 12.415L101.184 11.7934Z"
                      fill="#FA5333"
                    />
                    <path
                      d="M125.153 10.3146C126.073 9.66319 126.997 9.73772 126.997 9.73772L127.34 10.8319C127.34 10.8319 126.417 10.7574 125.496 11.4088L125.153 10.3146Z"
                      fill="#FF9283"
                    />
                    <path
                      d="M120.52 7.28257C121.31 6.68331 122.123 6.72356 122.123 6.72356L122.455 7.67462C122.455 7.67462 121.643 7.63437 120.851 8.23363L120.52 7.28257Z"
                      fill="#FA5333"
                    />
                    <path
                      d="M108.223 -1.59604C108.978 -1.00572 109.14 -0.236526 109.14 -0.236526L108.334 0.307577C108.334 0.307577 108.171 -0.461621 107.417 -1.05194L108.223 -1.59604Z"
                      fill="#DBDEE0"
                    />
                    <path
                      d="M98.6994 20.9448C98.2268 20.0146 98.4191 19.1813 98.4191 19.1813L99.4656 19.0143C99.4656 19.0143 99.2733 19.8491 99.7458 20.7778L98.6994 20.9448Z"
                      fill="#FA5333"
                    />
                    <path
                      d="M103.507 23.8263C102.942 22.7321 103.164 21.7453 103.164 21.7453L104.398 21.541C104.398 21.541 104.176 22.5264 104.741 23.6221L103.507 23.8263Z"
                      fill="#C6CDD1"
                    />
                    <path
                      d="M108.748 14.5422C109.37 13.8714 110.114 13.7775 110.114 13.7775L110.567 14.5869C110.567 14.5869 109.823 14.6809 109.201 15.3517L108.748 14.5422Z"
                      fill="#DBDEE0"
                    />
                    <path
                      d="M114.429 23.4342C114.757 22.3728 115.522 21.8779 115.522 21.8779L116.445 22.5264C116.445 22.5264 115.68 23.0213 115.352 24.0827L114.429 23.4342Z"
                      fill="#FF9283"
                    />
                  </g>
                  <path
                    d="M9.93959 14.2794L8.90242 17.8036H15.3848L14.1747 22.1669H7.60595L6.48234 26.1107H15.2983L14.0019 30.474H0L5.96376 10H19.9656L18.6691 14.3633H9.93959V14.2794Z"
                    fill="white"
                  />
                  <path
                    d="M32.5846 26.1946L31.2881 30.5579H17.8048L23.7686 10.0839H28.9545L24.2872 26.2785H32.5846V26.1946Z"
                    fill="white"
                  />
                  <path
                    d="M40.5362 10H45.7221L39.7584 30.474H34.5725L40.5362 10Z"
                    fill="white"
                  />
                  <path
                    d="M58.5139 20.4887L62.0576 30.474H56.7853L54.6245 24.0129L49.0065 30.474H43.0427L52.6366 19.8174L49.0929 10H54.3652L56.526 16.2932L61.9712 10H67.9349L58.5139 20.4887Z"
                    fill="white"
                  />
                  <path
                    d="M71.0465 10H76.2323L70.2686 30.474H65.0827L71.0465 10Z"
                    fill="white"
                  />
                  <path
                    d="M93 10V30.474H88.4191V26.1946H81.4182L78.9981 30.474H73.4665L85.9991 10H93ZM88.4191 21.8313V13.8598L83.8383 21.8313H88.4191Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_4421_68">
                    <rect width="127" height="41" fill="white" />
                  </clipPath>
                  <clipPath id="clip1_4421_68">
                    <rect
                      width="30.4102"
                      height="32.9443"
                      fill="white"
                      transform="translate(97 -2)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>
          );
        }
        default: {
          //Note set default to lightmode
          return (
            <div className="occasions-logo">
              <svg
                width="127"
                height="41"
                viewBox="0 0 127 41"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_4421_65)">
                  <g clip-path="url(#clip1_4421_65)">
                    <path
                      d="M100.63 28.3908L100.272 24.5552H101.694L101.878 26.8017L103.583 24.5552H105.281L102.086 28.5398L101.563 30.3794H100.065L100.631 28.3908H100.63Z"
                      fill="#FA5333"
                    />
                    <path
                      d="M106.886 25.87L106.628 26.7525H108.392L108.018 28.0673H106.254L105.962 29.0661H108.441L108.067 30.3809H104.09L105.762 24.5567H109.631L109.256 25.8715H106.885L106.886 25.87Z"
                      fill="#FA5333"
                    />
                    <path
                      d="M113.84 24.5552V30.3794H112.493V29.1972H110.604L109.947 30.3794H108.341L111.835 24.5552H113.84ZM112.493 27.8839V25.8208L111.345 27.8839H112.493Z"
                      fill="#FA5333"
                    />
                    <path
                      d="M118.025 28.1239L118.907 30.3794H117.343L116.512 28.0584L115.845 30.3794H114.349L116.012 24.5552H118.301C119.441 24.5552 120.048 25.2126 120.048 26.1115C120.048 27.1684 119.316 28.0256 118.026 28.1239H118.025ZM118.615 26.2531C118.615 25.9788 118.415 25.8044 118.058 25.8044H117.151L116.844 26.8867H117.843C118.35 26.8867 118.617 26.6288 118.617 26.2546L118.615 26.2531Z"
                      fill="#FA5333"
                    />
                    <path
                      d="M119.182 29.5476L120.322 28.674C120.614 29.0064 121.021 29.1823 121.428 29.1823C121.935 29.1823 122.152 28.9662 122.152 28.6502C122.152 28.4251 121.986 28.2596 121.72 28.0345L121.337 27.7095C120.938 27.3682 120.605 26.9195 120.605 26.3366C120.605 25.2961 121.488 24.4404 122.826 24.4404C123.833 24.4404 124.499 24.8653 124.764 25.2887L123.616 26.1547C123.342 25.8805 123.017 25.7552 122.701 25.7552C122.327 25.7552 122.118 25.9296 122.118 26.2129C122.118 26.4454 122.318 26.6198 122.543 26.8121L122.934 27.1446C123.317 27.4695 123.674 27.9272 123.674 28.4922C123.674 29.6072 122.908 30.4971 121.361 30.4971C120.346 30.4971 119.597 30.1066 119.182 29.5491V29.5476Z"
                      fill="#FA5333"
                    />
                    <path
                      d="M112.33 10.5695L107.91 13.773C109.692 14.3589 110.662 15.8257 110.662 17.6071C110.662 20.2233 108.678 23.1093 104.573 23.1093C101.415 23.1093 99.634 21.4397 99.002 19.7492L102.25 18.0349C102.68 18.9368 103.401 19.5912 104.573 19.5912C105.949 19.5912 106.603 18.6431 106.603 17.6295C106.603 16.8856 106.175 16.0061 104.933 16.0061C104.528 16.0061 104.167 16.0955 103.874 16.2312L102.52 14.0205L107.256 10.5695H102.204L103.286 7.00677H113.39L112.33 10.5695Z"
                      fill="#14233C"
                    />
                    <path
                      d="M112.128 18.7117C112.128 13.8625 115.511 6.69075 121.33 6.69075C124.6 6.69075 125.976 8.60778 125.976 11.1107C125.976 15.9599 122.571 23.1093 116.774 23.1093C113.503 23.1093 112.128 21.1251 112.128 18.7117ZM121.826 11.8113C121.826 10.8408 121.51 10.3221 120.63 10.3221C118.578 10.3221 116.278 15.2846 116.278 17.9902C116.278 18.8474 116.48 19.4794 117.473 19.4794C119.526 19.4794 121.826 14.5184 121.826 11.8113Z"
                      fill="#14233C"
                    />
                    <path
                      d="M124.754 23.3701C120.468 22.3162 120.551 20.186 120.551 20.186L121.44 18.8429C121.44 18.8429 121.355 20.9731 125.642 22.027L124.754 23.3701Z"
                      fill="#DBDEE0"
                    />
                    <path
                      d="M98.0465 11.8307C99.5088 12.4404 100.107 13.5956 100.107 13.5956L99.0601 14.818C99.0601 14.818 98.4624 13.6642 96.9985 13.053L98.0465 11.8307Z"
                      fill="#FA5333"
                    />
                    <path
                      d="M98.5667 5.83061C99.1928 5.69049 99.6609 5.93049 99.6609 5.93049L99.6013 6.57894C99.6013 6.57894 99.1332 6.33745 98.5086 6.47907L98.5682 5.83061H98.5667Z"
                      fill="#FA5333"
                    />
                    <path
                      d="M103.905 2.52426C104.468 1.64326 105.287 1.38686 105.287 1.38686L105.962 2.20674C105.962 2.20674 105.144 2.46463 104.58 3.34563L103.905 2.52575V2.52426Z"
                      fill="#FA5333"
                    />
                    <path
                      d="M107.879 5.7695C108.884 6.43435 109.166 7.38243 109.166 7.38243L108.209 8.14567C108.209 8.14567 107.927 7.19759 106.922 6.53274L107.879 5.7695Z"
                      fill="#FF9283"
                    />
                    <path
                      d="M113.894 2.23357C114.082 1.32723 114.678 0.856171 114.678 0.856171L115.495 1.32127C115.495 1.32127 114.899 1.79233 114.711 2.69867L113.894 2.23357Z"
                      fill="#FA5333"
                    />
                    <path
                      d="M119.723 4.0239C120.474 3.94489 120.988 4.29073 120.988 4.29073L120.83 5.04204C120.83 5.04204 120.316 4.6962 119.565 4.77521L119.723 4.0239Z"
                      fill="#DBDEE0"
                    />
                    <path
                      d="M101.184 11.7934C101.632 11.1852 102.232 11.0361 102.232 11.0361L102.681 11.6577C102.681 11.6577 102.079 11.8053 101.633 12.415L101.184 11.7934Z"
                      fill="#FA5333"
                    />
                    <path
                      d="M125.153 10.3146C126.073 9.66319 126.997 9.73772 126.997 9.73772L127.34 10.8319C127.34 10.8319 126.417 10.7574 125.496 11.4088L125.153 10.3146Z"
                      fill="#FF9283"
                    />
                    <path
                      d="M120.52 7.28255C121.31 6.68329 122.123 6.72354 122.123 6.72354L122.455 7.6746C122.455 7.6746 121.643 7.63436 120.851 8.23361L120.52 7.28255Z"
                      fill="#FA5333"
                    />
                    <path
                      d="M108.223 -1.59602C108.978 -1.00571 109.14 -0.236511 109.14 -0.236511L108.334 0.307593C108.334 0.307593 108.171 -0.461605 107.417 -1.05192L108.223 -1.59602Z"
                      fill="#DBDEE0"
                    />
                    <path
                      d="M98.6994 20.9448C98.2268 20.0146 98.4191 19.1813 98.4191 19.1813L99.4656 19.0143C99.4656 19.0143 99.2733 19.8491 99.7458 20.7778L98.6994 20.9448Z"
                      fill="#FA5333"
                    />
                    <path
                      d="M103.507 23.8263C102.942 22.7321 103.164 21.7453 103.164 21.7453L104.398 21.541C104.398 21.541 104.176 22.5264 104.741 23.6221L103.507 23.8263Z"
                      fill="#C6CDD1"
                    />
                    <path
                      d="M108.748 14.5422C109.37 13.8714 110.114 13.7775 110.114 13.7775L110.567 14.5869C110.567 14.5869 109.823 14.6809 109.201 15.3517L108.748 14.5422Z"
                      fill="#DBDEE0"
                    />
                    <path
                      d="M114.429 23.4342C114.757 22.3728 115.522 21.8779 115.522 21.8779L116.445 22.5264C116.445 22.5264 115.68 23.0213 115.352 24.0827L114.429 23.4342Z"
                      fill="#FF9283"
                    />
                  </g>
                  <path
                    d="M9.93959 14.2794L8.90242 17.8036H15.3848L14.1747 22.1669H7.60595L6.48234 26.1107H15.2983L14.0019 30.474H0L5.96376 10H19.9656L18.6691 14.3633H9.93959V14.2794Z"
                    fill="#14233C"
                  />
                  <path
                    d="M32.5846 26.1946L31.2881 30.5579H17.8048L23.7686 10.0839H28.9545L24.2872 26.2785H32.5846V26.1946Z"
                    fill="#14233C"
                  />
                  <path
                    d="M40.5362 10H45.7221L39.7584 30.474H34.5725L40.5362 10Z"
                    fill="#14233C"
                  />
                  <path
                    d="M58.5139 20.4887L62.0576 30.474H56.7853L54.6245 24.0129L49.0065 30.474H43.0427L52.6366 19.8174L49.0929 10H54.3652L56.526 16.2932L61.9712 10H67.9349L58.5139 20.4887Z"
                    fill="#14233C"
                  />
                  <path
                    d="M71.0465 10H76.2323L70.2686 30.474H65.0827L71.0465 10Z"
                    fill="#14233C"
                  />
                  <path
                    d="M93 10V30.474H88.4191V26.1946H81.4182L78.9981 30.474H73.4665L85.9991 10H93ZM88.4191 21.8313V13.8598L83.8383 21.8313H88.4191Z"
                    fill="#14233C"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_4421_65">
                    <rect width="127" height="41" fill="white" />
                  </clipPath>
                  <clipPath id="clip1_4421_65">
                    <rect
                      width="30.4102"
                      height="32.9443"
                      fill="white"
                      transform="translate(97 -2)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>
          );
        }
      }
    }
    case 'sats': {
      switch (color) {
        case 'light': {
          return (
            <div className="occasions-logo">
              <svg
                width="111"
                height="41"
                viewBox="0 0 111 41"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_4421_62)">
                  <path
                    d="M83.6299 30.3908L83.2721 26.5552H84.6942L84.8776 28.8017L86.5829 26.5552H88.2808L85.0863 30.5399L84.563 32.3794H83.0649L83.6313 30.3908H83.6299Z"
                    fill="#FA5333"
                  />
                  <path
                    d="M89.8863 27.87L89.6284 28.7525H91.3919L91.0177 30.0673H89.2542L88.9621 31.0661H91.4411L91.0669 32.3808H87.0898L88.7623 26.5567H92.6307L92.2565 27.8715H89.8848L89.8863 27.87Z"
                    fill="#FA5333"
                  />
                  <path
                    d="M96.8404 26.5552V32.3794H95.4928V31.1973H93.6041L92.9467 32.3794H91.3412L94.8354 26.5552H96.8404ZM95.4928 29.884V27.8208L94.345 29.884H95.4928Z"
                    fill="#FA5333"
                  />
                  <path
                    d="M101.025 30.124L101.907 32.3794H100.343L99.5117 30.0584L98.8453 32.3794H97.3487L99.0123 26.5552H101.301C102.441 26.5552 103.048 27.2126 103.048 28.1115C103.048 29.1684 102.316 30.0256 101.026 30.124H101.025ZM101.615 28.2531C101.615 27.9788 101.415 27.8044 101.058 27.8044H100.151L99.8441 28.8867H100.843C101.35 28.8867 101.617 28.6288 101.617 28.2546L101.615 28.2531Z"
                    fill="#FA5333"
                  />
                  <path
                    d="M102.182 31.5475L103.322 30.674C103.614 31.0064 104.021 31.1823 104.428 31.1823C104.935 31.1823 105.152 30.9662 105.152 30.6502C105.152 30.4251 104.986 30.2596 104.72 30.0345L104.337 29.7095C103.938 29.3682 103.605 28.9195 103.605 28.3366C103.605 27.2961 104.488 26.4404 105.826 26.4404C106.832 26.4404 107.499 26.8653 107.764 27.2886L106.616 28.1547C106.342 27.8804 106.017 27.7552 105.701 27.7552C105.327 27.7552 105.118 27.9296 105.118 28.2129C105.118 28.4454 105.318 28.6198 105.543 28.8121L105.934 29.1445C106.317 29.4695 106.674 29.9272 106.674 30.4921C106.674 31.6072 105.908 32.4971 104.361 32.4971C103.346 32.4971 102.597 32.1066 102.182 31.549V31.5475Z"
                    fill="#FA5333"
                  />
                  <path
                    d="M95.3303 12.5695L90.9104 15.773C92.6918 16.3589 93.6622 17.8257 93.6622 19.6071C93.6622 22.2233 91.6781 25.1093 87.5727 25.1093C84.4154 25.1093 82.6341 23.4397 82.002 21.7492L85.2502 20.0349C85.6795 20.9368 86.401 21.5912 87.5727 21.5912C88.9486 21.5912 89.603 20.6431 89.603 19.6295C89.603 18.8856 89.1752 18.0061 87.9335 18.0061C87.528 18.0061 87.1673 18.0955 86.8736 18.2312L85.52 16.0205L90.256 12.5695H85.204L86.2863 9.00677H96.3902L95.3303 12.5695Z"
                    fill="white"
                  />
                  <path
                    d="M95.1276 20.7117C95.1276 15.8625 98.5114 8.69077 104.33 8.69077C107.6 8.69077 108.976 10.6078 108.976 13.1107C108.976 17.9599 105.571 25.1093 99.7741 25.1093C96.5035 25.1093 95.1276 23.1252 95.1276 20.7117ZM104.826 13.8113C104.826 12.8409 104.51 12.3221 103.63 12.3221C101.578 12.3221 99.2777 17.2846 99.2777 19.9902C99.2777 20.8474 99.4804 21.4794 100.473 21.4794C102.526 21.4794 104.826 16.5184 104.826 13.8113Z"
                    fill="white"
                  />
                  <path
                    d="M107.754 25.3701C103.468 24.3162 103.551 22.186 103.551 22.186L104.44 20.8429C104.44 20.8429 104.355 22.9731 108.642 24.027L107.754 25.3701Z"
                    fill="#DBDEE0"
                  />
                  <path
                    d="M81.0465 13.8307C82.5088 14.4404 83.1066 15.5956 83.1066 15.5956L82.0601 16.818C82.0601 16.818 81.4624 15.6642 79.9985 15.053L81.0465 13.8307Z"
                    fill="#FA5333"
                  />
                  <path
                    d="M81.5667 7.8306C82.1928 7.69047 82.6609 7.93048 82.6609 7.93048L82.6013 8.57893C82.6013 8.57893 82.1332 8.33744 81.5086 8.47905L81.5682 7.8306H81.5667Z"
                    fill="#FA5333"
                  />
                  <path
                    d="M86.9049 4.52424C87.4684 3.64324 88.2868 3.38684 88.2868 3.38684L88.9621 4.20672C88.9621 4.20672 88.1437 4.46461 87.5802 5.34561L86.9049 4.52573V4.52424Z"
                    fill="#FA5333"
                  />
                  <path
                    d="M90.8791 7.7695C91.8838 8.43435 92.1656 9.38243 92.1656 9.38243L91.2085 10.1457C91.2085 10.1457 90.9268 9.19759 89.9221 8.53274L90.8791 7.7695Z"
                    fill="#FF9283"
                  />
                  <path
                    d="M96.894 4.23357C97.0819 3.32723 97.6781 2.85617 97.6781 2.85617L98.495 3.32127C98.495 3.32127 97.8988 3.79233 97.7109 4.69867L96.894 4.23357Z"
                    fill="#FA5333"
                  />
                  <path
                    d="M102.723 6.02391C103.474 5.9449 103.988 6.29074 103.988 6.29074L103.83 7.04205C103.83 7.04205 103.316 6.69621 102.565 6.77522L102.723 6.02391Z"
                    fill="#DBDEE0"
                  />
                  <path
                    d="M84.1844 13.7934C84.6316 13.1852 85.2323 13.0361 85.2323 13.0361L85.681 13.6577C85.681 13.6577 85.0788 13.8053 84.6331 14.415L84.1844 13.7934Z"
                    fill="#FA5333"
                  />
                  <path
                    d="M108.153 12.3146C109.073 11.6632 109.997 11.7377 109.997 11.7377L110.34 12.8319C110.34 12.8319 109.417 12.7574 108.496 13.4088L108.153 12.3146Z"
                    fill="#FF9283"
                  />
                  <path
                    d="M103.52 9.28257C104.31 8.68331 105.123 8.72356 105.123 8.72356L105.455 9.67462C105.455 9.67462 104.643 9.63437 103.851 10.2336L103.52 9.28257Z"
                    fill="#FA5333"
                  />
                  <path
                    d="M100.885 0C101.743 0.310064 102.12 0.957026 102.12 0.957026L101.552 1.69045C101.552 1.69045 101.175 1.042 100.317 0.733421L100.885 0Z"
                    fill="#FA5333"
                  />
                  <path
                    d="M91.2234 0.403961C91.9777 0.994276 92.1402 1.76347 92.1402 1.76347L91.3337 2.30758C91.3337 2.30758 91.1712 1.53838 90.4169 0.948065L91.2234 0.403961Z"
                    fill="#DBDEE0"
                  />
                  <path
                    d="M81.6994 22.9448C81.2268 22.0146 81.4191 21.1813 81.4191 21.1813L82.4656 21.0143C82.4656 21.0143 82.2733 21.8491 82.7458 22.7778L81.6994 22.9448Z"
                    fill="#FA5333"
                  />
                  <path
                    d="M86.5069 25.8263C85.9419 24.7321 86.164 23.7453 86.164 23.7453L87.3983 23.541C87.3983 23.541 87.1762 24.5264 87.7412 25.6221L86.5069 25.8263Z"
                    fill="#C6CDD1"
                  />
                  <path
                    d="M91.7482 16.5422C92.3698 15.8714 93.1136 15.7775 93.1136 15.7775L93.5668 16.5869C93.5668 16.5869 92.823 16.6809 92.2013 17.3517L91.7482 16.5422Z"
                    fill="#DBDEE0"
                  />
                  <path
                    d="M97.4292 25.4342C97.7571 24.3728 98.5219 23.8779 98.5219 23.8779L99.4446 24.5264C99.4446 24.5264 98.6799 25.0213 98.3519 26.0827L97.4292 25.4342Z"
                    fill="#FF9283"
                  />
                </g>
                <g clip-path="url(#clip1_4421_62)">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M4.51429 25.3482L0.28215 28.6174C1.88096 30.9251 4.70238 32.6559 8.65238 32.6559C14.4833 32.6559 17.8691 29.0982 17.8691 24.3866C17.8691 21.9828 16.4583 20.5405 14.9536 19.1943L13.4488 17.752C12.5083 16.8866 12.0381 16.2136 12.0381 15.3482C12.0381 14.1943 12.7905 13.1366 14.5774 13.1366C15.8 13.1366 17.0226 13.6174 18.1512 14.8674L22.3833 11.5982C21.3488 9.96356 18.9036 8.23279 15.1417 8.23279C10.0631 8.23279 6.30119 11.6943 6.30119 16.2136C6.30119 18.4251 7.52381 19.9636 9.12262 21.4059L10.6274 22.8482C11.6619 23.8097 12.1321 24.3866 12.1321 25.252C12.1321 26.4059 11.2857 27.6559 9.02857 27.6559C7.24167 27.6559 5.64286 26.7905 4.51429 25.3482ZM60.7548 25.3482L56.5226 28.6174C58.0274 30.9251 60.9429 32.6559 64.8929 32.6559C70.7238 32.6559 74.1095 29.0982 74.1095 24.3866C74.1095 21.9828 72.6988 20.5405 71.1941 19.1943L69.6893 17.752C68.7488 16.8866 68.2786 16.2136 68.2786 15.3482C68.2786 14.1943 69.031 13.1366 70.8179 13.1366C72.0405 13.1366 73.2631 13.6174 74.3917 14.8674L78.6238 11.5982C77.5893 9.96356 75.1441 8.23279 71.3821 8.23279C66.3036 8.23279 62.5417 11.6943 62.5417 16.2136C62.5417 18.4251 63.7643 19.9636 65.3631 21.4059L66.8679 22.8482C67.9024 23.8097 68.3726 24.3866 68.3726 25.252C68.3726 26.4059 67.5262 27.6559 65.2691 27.6559C63.4821 27.6559 61.8833 26.7905 60.7548 25.3482ZM60.0024 13.6174H54.5476L49.4691 32.0789H43.8262L48.9048 13.6174H43.0738L44.4845 8.6174H61.5071L60.0024 13.6174ZM39.3119 32.1751V8.71356H31.506L17.9631 32.1751H23.9821L26.6155 27.2713H34.3274V32.1751H39.3119ZM34.3274 13.2328V22.2712H29.3429L34.3274 13.2328Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_4421_62">
                    <rect
                      width="30.4102"
                      height="32.9443"
                      fill="white"
                      transform="translate(80)"
                    />
                  </clipPath>
                  <clipPath id="clip1_4421_62">
                    <rect
                      width="79"
                      height="25"
                      fill="white"
                      transform="translate(0 7.94434)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>
          );
        }
        default: {
          //Note set default to lightmode
          return (
            <div className="occasions-logo">
              <svg
                width="111"
                height="41"
                viewBox="0 0 111 41"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_4421_59)">
                  <path
                    d="M83.6299 30.3908L83.2721 26.5552H84.6942L84.8776 28.8017L86.5829 26.5552H88.2808L85.0863 30.5398L84.563 32.3794H83.0649L83.6313 30.3908H83.6299Z"
                    fill="#FA5333"
                  />
                  <path
                    d="M89.8863 27.87L89.6284 28.7525H91.3919L91.0177 30.0673H89.2542L88.9621 31.0661H91.4411L91.0669 32.3809H87.0898L88.7623 26.5567H92.6307L92.2565 27.8715H89.8848L89.8863 27.87Z"
                    fill="#FA5333"
                  />
                  <path
                    d="M96.8404 26.5552V32.3794H95.4928V31.1972H93.6041L92.9467 32.3794H91.3412L94.8354 26.5552H96.8404ZM95.4928 29.8839V27.8208L94.345 29.8839H95.4928Z"
                    fill="#FA5333"
                  />
                  <path
                    d="M101.025 30.1239L101.907 32.3794H100.343L99.5117 30.0584L98.8453 32.3794H97.3487L99.0123 26.5552H101.301C102.441 26.5552 103.048 27.2126 103.048 28.1115C103.048 29.1684 102.316 30.0256 101.026 30.1239H101.025ZM101.615 28.2531C101.615 27.9788 101.415 27.8044 101.058 27.8044H100.151L99.8441 28.8867H100.843C101.35 28.8867 101.617 28.6288 101.617 28.2546L101.615 28.2531Z"
                    fill="#FA5333"
                  />
                  <path
                    d="M102.182 31.5476L103.322 30.674C103.614 31.0064 104.021 31.1823 104.428 31.1823C104.935 31.1823 105.152 30.9662 105.152 30.6502C105.152 30.4251 104.986 30.2596 104.72 30.0345L104.337 29.7095C103.938 29.3682 103.605 28.9195 103.605 28.3366C103.605 27.2961 104.488 26.4404 105.826 26.4404C106.832 26.4404 107.499 26.8653 107.764 27.2887L106.616 28.1547C106.342 27.8805 106.017 27.7552 105.701 27.7552C105.327 27.7552 105.118 27.9296 105.118 28.2129C105.118 28.4454 105.318 28.6198 105.543 28.8121L105.934 29.1446C106.317 29.4695 106.674 29.9272 106.674 30.4922C106.674 31.6072 105.908 32.4971 104.361 32.4971C103.346 32.4971 102.597 32.1066 102.182 31.5491V31.5476Z"
                    fill="#FA5333"
                  />
                  <path
                    d="M95.3303 12.5695L90.9104 15.773C92.6918 16.3589 93.6622 17.8257 93.6622 19.6071C93.6622 22.2233 91.6781 25.1093 87.5727 25.1093C84.4154 25.1093 82.6341 23.4397 82.002 21.7492L85.2502 20.0349C85.6795 20.9368 86.401 21.5912 87.5727 21.5912C88.9486 21.5912 89.603 20.6431 89.603 19.6295C89.603 18.8856 89.1752 18.0061 87.9335 18.0061C87.528 18.0061 87.1673 18.0955 86.8736 18.2312L85.52 16.0205L90.256 12.5695H85.204L86.2863 9.00677H96.3902L95.3303 12.5695Z"
                    fill="#14233C"
                  />
                  <path
                    d="M95.1276 20.7117C95.1276 15.8625 98.5114 8.69075 104.33 8.69075C107.6 8.69075 108.976 10.6078 108.976 13.1107C108.976 17.9599 105.571 25.1093 99.7741 25.1093C96.5035 25.1093 95.1276 23.1251 95.1276 20.7117ZM104.826 13.8113C104.826 12.8408 104.51 12.3221 103.63 12.3221C101.578 12.3221 99.2777 17.2846 99.2777 19.9902C99.2777 20.8474 99.4804 21.4794 100.473 21.4794C102.526 21.4794 104.826 16.5184 104.826 13.8113Z"
                    fill="#14233C"
                  />
                  <path
                    d="M107.754 25.3701C103.468 24.3162 103.551 22.186 103.551 22.186L104.44 20.8429C104.44 20.8429 104.355 22.9731 108.642 24.027L107.754 25.3701Z"
                    fill="#DBDEE0"
                  />
                  <path
                    d="M81.0465 13.8307C82.5088 14.4404 83.1066 15.5956 83.1066 15.5956L82.0601 16.818C82.0601 16.818 81.4624 15.6642 79.9985 15.053L81.0465 13.8307Z"
                    fill="#FA5333"
                  />
                  <path
                    d="M81.5667 7.83061C82.1928 7.69049 82.6609 7.93049 82.6609 7.93049L82.6013 8.57894C82.6013 8.57894 82.1332 8.33745 81.5086 8.47907L81.5682 7.83061H81.5667Z"
                    fill="#FA5333"
                  />
                  <path
                    d="M86.9049 4.52426C87.4684 3.64326 88.2868 3.38686 88.2868 3.38686L88.9621 4.20674C88.9621 4.20674 88.1437 4.46463 87.5802 5.34563L86.9049 4.52575V4.52426Z"
                    fill="#FA5333"
                  />
                  <path
                    d="M90.8791 7.7695C91.8838 8.43435 92.1656 9.38243 92.1656 9.38243L91.2085 10.1457C91.2085 10.1457 90.9268 9.19759 89.9221 8.53274L90.8791 7.7695Z"
                    fill="#FF9283"
                  />
                  <path
                    d="M96.894 4.23357C97.0819 3.32723 97.6781 2.85617 97.6781 2.85617L98.495 3.32127C98.495 3.32127 97.8988 3.79233 97.7109 4.69867L96.894 4.23357Z"
                    fill="#FA5333"
                  />
                  <path
                    d="M102.723 6.0239C103.474 5.94489 103.988 6.29073 103.988 6.29073L103.83 7.04204C103.83 7.04204 103.316 6.6962 102.565 6.77521L102.723 6.0239Z"
                    fill="#DBDEE0"
                  />
                  <path
                    d="M84.1844 13.7934C84.6316 13.1852 85.2323 13.0361 85.2323 13.0361L85.681 13.6577C85.681 13.6577 85.0788 13.8053 84.6331 14.415L84.1844 13.7934Z"
                    fill="#FA5333"
                  />
                  <path
                    d="M108.153 12.3146C109.073 11.6632 109.997 11.7377 109.997 11.7377L110.34 12.8319C110.34 12.8319 109.417 12.7574 108.496 13.4088L108.153 12.3146Z"
                    fill="#FF9283"
                  />
                  <path
                    d="M103.52 9.28255C104.31 8.68329 105.123 8.72354 105.123 8.72354L105.455 9.6746C105.455 9.6746 104.643 9.63436 103.851 10.2336L103.52 9.28255Z"
                    fill="#FA5333"
                  />
                  <path
                    d="M100.885 0C101.743 0.310064 102.12 0.957026 102.12 0.957026L101.552 1.69045C101.552 1.69045 101.175 1.042 100.317 0.733421L100.885 0Z"
                    fill="#FA5333"
                  />
                  <path
                    d="M91.2234 0.403976C91.9777 0.994291 92.1402 1.76349 92.1402 1.76349L91.3337 2.30759C91.3337 2.30759 91.1712 1.53839 90.4169 0.94808L91.2234 0.403976Z"
                    fill="#DBDEE0"
                  />
                  <path
                    d="M81.6994 22.9448C81.2268 22.0146 81.4191 21.1813 81.4191 21.1813L82.4656 21.0143C82.4656 21.0143 82.2733 21.8491 82.7458 22.7778L81.6994 22.9448Z"
                    fill="#FA5333"
                  />
                  <path
                    d="M86.5069 25.8263C85.9419 24.7321 86.164 23.7453 86.164 23.7453L87.3983 23.541C87.3983 23.541 87.1762 24.5264 87.7412 25.6221L86.5069 25.8263Z"
                    fill="#C6CDD1"
                  />
                  <path
                    d="M91.7482 16.5422C92.3698 15.8714 93.1136 15.7775 93.1136 15.7775L93.5668 16.5869C93.5668 16.5869 92.823 16.6809 92.2013 17.3517L91.7482 16.5422Z"
                    fill="#DBDEE0"
                  />
                  <path
                    d="M97.4292 25.4342C97.7571 24.3728 98.5219 23.8779 98.5219 23.8779L99.4446 24.5264C99.4446 24.5264 98.6799 25.0213 98.3519 26.0827L97.4292 25.4342Z"
                    fill="#FF9283"
                  />
                </g>
                <g clip-path="url(#clip1_4421_59)">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M4.51429 25.3482L0.28215 28.6174C1.88096 30.9251 4.70238 32.6559 8.65238 32.6559C14.4833 32.6559 17.8691 29.0982 17.8691 24.3867C17.8691 21.9828 16.4583 20.5405 14.9536 19.1943L13.4488 17.752C12.5083 16.8866 12.0381 16.2136 12.0381 15.3482C12.0381 14.1943 12.7905 13.1366 14.5774 13.1366C15.8 13.1366 17.0226 13.6174 18.1512 14.8674L22.3833 11.5982C21.3488 9.96357 18.9036 8.2328 15.1417 8.2328C10.0631 8.2328 6.30119 11.6943 6.30119 16.2136C6.30119 18.4251 7.52381 19.9636 9.12262 21.4059L10.6274 22.8482C11.6619 23.8097 12.1321 24.3866 12.1321 25.252C12.1321 26.4059 11.2857 27.6559 9.02857 27.6559C7.24167 27.6559 5.64286 26.7905 4.51429 25.3482ZM60.7548 25.3482L56.5226 28.6174C58.0274 30.9251 60.9429 32.6559 64.8929 32.6559C70.7238 32.6559 74.1095 29.0982 74.1095 24.3867C74.1095 21.9828 72.6988 20.5405 71.1941 19.1943L69.6893 17.752C68.7488 16.8866 68.2786 16.2136 68.2786 15.3482C68.2786 14.1943 69.031 13.1366 70.8179 13.1366C72.0405 13.1366 73.2631 13.6174 74.3917 14.8674L78.6238 11.5982C77.5893 9.96357 75.1441 8.2328 71.3821 8.2328C66.3036 8.2328 62.5417 11.6943 62.5417 16.2136C62.5417 18.4251 63.7643 19.9636 65.3631 21.4059L66.8679 22.8482C67.9024 23.8097 68.3726 24.3866 68.3726 25.252C68.3726 26.4059 67.5262 27.6559 65.2691 27.6559C63.4821 27.6559 61.8833 26.7905 60.7548 25.3482ZM60.0024 13.6174H54.5476L49.4691 32.079H43.8262L48.9048 13.6174H43.0738L44.4845 8.61741H61.5071L60.0024 13.6174ZM39.3119 32.1751V8.71357H31.506L17.9631 32.1751H23.9821L26.6155 27.2713H34.3274V32.1751H39.3119ZM34.3274 13.2328V22.2713H29.3429L34.3274 13.2328Z"
                    fill="#14233C"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_4421_59">
                    <rect
                      width="30.4102"
                      height="32.9443"
                      fill="white"
                      transform="translate(80)"
                    />
                  </clipPath>
                  <clipPath id="clip1_4421_59">
                    <rect
                      width="79"
                      height="25"
                      fill="white"
                      transform="translate(0 7.94434)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>
          );
        }
      }
    }
    default: {
      return (
        <div className="logo">
          <svg
            width="111"
            height="41"
            viewBox="0 0 111 41"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_4421_59)">
              <path
                d="M83.6299 30.3908L83.2721 26.5552H84.6942L84.8776 28.8017L86.5829 26.5552H88.2808L85.0863 30.5398L84.563 32.3794H83.0649L83.6313 30.3908H83.6299Z"
                fill="#FA5333"
              />
              <path
                d="M89.8863 27.87L89.6284 28.7525H91.3919L91.0177 30.0673H89.2542L88.9621 31.0661H91.4411L91.0669 32.3809H87.0898L88.7623 26.5567H92.6307L92.2565 27.8715H89.8848L89.8863 27.87Z"
                fill="#FA5333"
              />
              <path
                d="M96.8404 26.5552V32.3794H95.4928V31.1972H93.6041L92.9467 32.3794H91.3412L94.8354 26.5552H96.8404ZM95.4928 29.8839V27.8208L94.345 29.8839H95.4928Z"
                fill="#FA5333"
              />
              <path
                d="M101.025 30.1239L101.907 32.3794H100.343L99.5117 30.0584L98.8453 32.3794H97.3487L99.0123 26.5552H101.301C102.441 26.5552 103.048 27.2126 103.048 28.1115C103.048 29.1684 102.316 30.0256 101.026 30.1239H101.025ZM101.615 28.2531C101.615 27.9788 101.415 27.8044 101.058 27.8044H100.151L99.8441 28.8867H100.843C101.35 28.8867 101.617 28.6288 101.617 28.2546L101.615 28.2531Z"
                fill="#FA5333"
              />
              <path
                d="M102.182 31.5476L103.322 30.674C103.614 31.0064 104.021 31.1823 104.428 31.1823C104.935 31.1823 105.152 30.9662 105.152 30.6502C105.152 30.4251 104.986 30.2596 104.72 30.0345L104.337 29.7095C103.938 29.3682 103.605 28.9195 103.605 28.3366C103.605 27.2961 104.488 26.4404 105.826 26.4404C106.832 26.4404 107.499 26.8653 107.764 27.2887L106.616 28.1547C106.342 27.8805 106.017 27.7552 105.701 27.7552C105.327 27.7552 105.118 27.9296 105.118 28.2129C105.118 28.4454 105.318 28.6198 105.543 28.8121L105.934 29.1446C106.317 29.4695 106.674 29.9272 106.674 30.4922C106.674 31.6072 105.908 32.4971 104.361 32.4971C103.346 32.4971 102.597 32.1066 102.182 31.5491V31.5476Z"
                fill="#FA5333"
              />
              <path
                d="M95.3303 12.5695L90.9104 15.773C92.6918 16.3589 93.6622 17.8257 93.6622 19.6071C93.6622 22.2233 91.6781 25.1093 87.5727 25.1093C84.4154 25.1093 82.6341 23.4397 82.002 21.7492L85.2502 20.0349C85.6795 20.9368 86.401 21.5912 87.5727 21.5912C88.9486 21.5912 89.603 20.6431 89.603 19.6295C89.603 18.8856 89.1752 18.0061 87.9335 18.0061C87.528 18.0061 87.1673 18.0955 86.8736 18.2312L85.52 16.0205L90.256 12.5695H85.204L86.2863 9.00677H96.3902L95.3303 12.5695Z"
                fill="#14233C"
              />
              <path
                d="M95.1276 20.7117C95.1276 15.8625 98.5114 8.69075 104.33 8.69075C107.6 8.69075 108.976 10.6078 108.976 13.1107C108.976 17.9599 105.571 25.1093 99.7741 25.1093C96.5035 25.1093 95.1276 23.1251 95.1276 20.7117ZM104.826 13.8113C104.826 12.8408 104.51 12.3221 103.63 12.3221C101.578 12.3221 99.2777 17.2846 99.2777 19.9902C99.2777 20.8474 99.4804 21.4794 100.473 21.4794C102.526 21.4794 104.826 16.5184 104.826 13.8113Z"
                fill="#14233C"
              />
              <path
                d="M107.754 25.3701C103.468 24.3162 103.551 22.186 103.551 22.186L104.44 20.8429C104.44 20.8429 104.355 22.9731 108.642 24.027L107.754 25.3701Z"
                fill="#DBDEE0"
              />
              <path
                d="M81.0465 13.8307C82.5088 14.4404 83.1066 15.5956 83.1066 15.5956L82.0601 16.818C82.0601 16.818 81.4624 15.6642 79.9985 15.053L81.0465 13.8307Z"
                fill="#FA5333"
              />
              <path
                d="M81.5667 7.83061C82.1928 7.69049 82.6609 7.93049 82.6609 7.93049L82.6013 8.57894C82.6013 8.57894 82.1332 8.33745 81.5086 8.47907L81.5682 7.83061H81.5667Z"
                fill="#FA5333"
              />
              <path
                d="M86.9049 4.52426C87.4684 3.64326 88.2868 3.38686 88.2868 3.38686L88.9621 4.20674C88.9621 4.20674 88.1437 4.46463 87.5802 5.34563L86.9049 4.52575V4.52426Z"
                fill="#FA5333"
              />
              <path
                d="M90.8791 7.7695C91.8838 8.43435 92.1656 9.38243 92.1656 9.38243L91.2085 10.1457C91.2085 10.1457 90.9268 9.19759 89.9221 8.53274L90.8791 7.7695Z"
                fill="#FF9283"
              />
              <path
                d="M96.894 4.23357C97.0819 3.32723 97.6781 2.85617 97.6781 2.85617L98.495 3.32127C98.495 3.32127 97.8988 3.79233 97.7109 4.69867L96.894 4.23357Z"
                fill="#FA5333"
              />
              <path
                d="M102.723 6.0239C103.474 5.94489 103.988 6.29073 103.988 6.29073L103.83 7.04204C103.83 7.04204 103.316 6.6962 102.565 6.77521L102.723 6.0239Z"
                fill="#DBDEE0"
              />
              <path
                d="M84.1844 13.7934C84.6316 13.1852 85.2323 13.0361 85.2323 13.0361L85.681 13.6577C85.681 13.6577 85.0788 13.8053 84.6331 14.415L84.1844 13.7934Z"
                fill="#FA5333"
              />
              <path
                d="M108.153 12.3146C109.073 11.6632 109.997 11.7377 109.997 11.7377L110.34 12.8319C110.34 12.8319 109.417 12.7574 108.496 13.4088L108.153 12.3146Z"
                fill="#FF9283"
              />
              <path
                d="M103.52 9.28255C104.31 8.68329 105.123 8.72354 105.123 8.72354L105.455 9.6746C105.455 9.6746 104.643 9.63436 103.851 10.2336L103.52 9.28255Z"
                fill="#FA5333"
              />
              <path
                d="M100.885 0C101.743 0.310064 102.12 0.957026 102.12 0.957026L101.552 1.69045C101.552 1.69045 101.175 1.042 100.317 0.733421L100.885 0Z"
                fill="#FA5333"
              />
              <path
                d="M91.2234 0.403976C91.9777 0.994291 92.1402 1.76349 92.1402 1.76349L91.3337 2.30759C91.3337 2.30759 91.1712 1.53839 90.4169 0.94808L91.2234 0.403976Z"
                fill="#DBDEE0"
              />
              <path
                d="M81.6994 22.9448C81.2268 22.0146 81.4191 21.1813 81.4191 21.1813L82.4656 21.0143C82.4656 21.0143 82.2733 21.8491 82.7458 22.7778L81.6994 22.9448Z"
                fill="#FA5333"
              />
              <path
                d="M86.5069 25.8263C85.9419 24.7321 86.164 23.7453 86.164 23.7453L87.3983 23.541C87.3983 23.541 87.1762 24.5264 87.7412 25.6221L86.5069 25.8263Z"
                fill="#C6CDD1"
              />
              <path
                d="M91.7482 16.5422C92.3698 15.8714 93.1136 15.7775 93.1136 15.7775L93.5668 16.5869C93.5668 16.5869 92.823 16.6809 92.2013 17.3517L91.7482 16.5422Z"
                fill="#DBDEE0"
              />
              <path
                d="M97.4292 25.4342C97.7571 24.3728 98.5219 23.8779 98.5219 23.8779L99.4446 24.5264C99.4446 24.5264 98.6799 25.0213 98.3519 26.0827L97.4292 25.4342Z"
                fill="#FF9283"
              />
            </g>
            <g clip-path="url(#clip1_4421_59)">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M4.51429 25.3482L0.28215 28.6174C1.88096 30.9251 4.70238 32.6559 8.65238 32.6559C14.4833 32.6559 17.8691 29.0982 17.8691 24.3867C17.8691 21.9828 16.4583 20.5405 14.9536 19.1943L13.4488 17.752C12.5083 16.8866 12.0381 16.2136 12.0381 15.3482C12.0381 14.1943 12.7905 13.1366 14.5774 13.1366C15.8 13.1366 17.0226 13.6174 18.1512 14.8674L22.3833 11.5982C21.3488 9.96357 18.9036 8.2328 15.1417 8.2328C10.0631 8.2328 6.30119 11.6943 6.30119 16.2136C6.30119 18.4251 7.52381 19.9636 9.12262 21.4059L10.6274 22.8482C11.6619 23.8097 12.1321 24.3866 12.1321 25.252C12.1321 26.4059 11.2857 27.6559 9.02857 27.6559C7.24167 27.6559 5.64286 26.7905 4.51429 25.3482ZM60.7548 25.3482L56.5226 28.6174C58.0274 30.9251 60.9429 32.6559 64.8929 32.6559C70.7238 32.6559 74.1095 29.0982 74.1095 24.3867C74.1095 21.9828 72.6988 20.5405 71.1941 19.1943L69.6893 17.752C68.7488 16.8866 68.2786 16.2136 68.2786 15.3482C68.2786 14.1943 69.031 13.1366 70.8179 13.1366C72.0405 13.1366 73.2631 13.6174 74.3917 14.8674L78.6238 11.5982C77.5893 9.96357 75.1441 8.2328 71.3821 8.2328C66.3036 8.2328 62.5417 11.6943 62.5417 16.2136C62.5417 18.4251 63.7643 19.9636 65.3631 21.4059L66.8679 22.8482C67.9024 23.8097 68.3726 24.3866 68.3726 25.252C68.3726 26.4059 67.5262 27.6559 65.2691 27.6559C63.4821 27.6559 61.8833 26.7905 60.7548 25.3482ZM60.0024 13.6174H54.5476L49.4691 32.079H43.8262L48.9048 13.6174H43.0738L44.4845 8.61741H61.5071L60.0024 13.6174ZM39.3119 32.1751V8.71357H31.506L17.9631 32.1751H23.9821L26.6155 27.2713H34.3274V32.1751H39.3119ZM34.3274 13.2328V22.2713H29.3429L34.3274 13.2328Z"
                fill="#14233C"
              />
            </g>
            <defs>
              <clipPath id="clip0_4421_59">
                <rect
                  width="30.4102"
                  height="32.9443"
                  fill="white"
                  transform="translate(80)"
                />
              </clipPath>
              <clipPath id="clip1_4421_59">
                <rect
                  width="79"
                  height="25"
                  fill="white"
                  transform="translate(0 7.94434)"
                />
              </clipPath>
            </defs>
          </svg>
        </div>
      );
    }
  }
};

export default OccasionsLogo;
