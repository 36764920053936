import React from 'react';

import Text from '@sats-group/ui-lib/react/text';

import ArrowLinkGroup from 'components/arrow-link-group/arrow-link-group';
import Breadcrumbs from 'components/breadcrumbs/breadcrumbs';
import Categories from 'components/categories/categories';
import ContentContainer from 'components/content-container/content-container';
import Layout from 'components/layout/layout';

import type { MemberCareCategoryPage as Props } from './member-care-category-page.types';

const MemberCareCategoryPage: React.FunctionComponent<Props> = ({
  breadcrumbs,
  description,
  layout,
  subcategories,
  otherCategories,
  title,
}) => (
  <Layout {...layout}>
    <Breadcrumbs {...breadcrumbs} />
    <ContentContainer>
      <div className="member-care-category-page__main">
        <Text
          elementName="h1"
          italic
          size={Text.sizes.headline2}
          theme={Text.themes.headline}
        >
          {title}
        </Text>
        {description ? <Text elementName="p">{description}</Text> : null}
        <ArrowLinkGroup {...subcategories} />
      </div>
    </ContentContainer>
    <div className="member-care-category-page__other-categories">
      <ContentContainer>
        <Categories {...otherCategories} />
      </ContentContainer>
    </div>
  </Layout>
);

export default MemberCareCategoryPage;
