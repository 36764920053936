import React from 'react';

import Button from '@sats-group/ui-lib/react/button';
import HiddenInput from '@sats-group/ui-lib/react/hidden-input';
import Text from '@sats-group/ui-lib/react/text';
import TextInput from '@sats-group/ui-lib/react/text-input';

import ContentContainer from 'components/content-container/content-container';
import MyPageLayout from 'components/my-page-layout/my-page-layout';

import type { CorporateCodeInput as Props } from './corporate-code-input.types';

const CorporateCodeInput: React.FunctionComponent<Props> = ({
  form,
  info,
  myPageLayout,
}) => (
  <MyPageLayout {...myPageLayout}>
    <ContentContainer>
      <div className="corporate-code-input">
        <Text size={Text.sizes.basic}>{info}</Text>
        <form
          action={form.action}
          className="corporate-code-input__form"
          method="get"
        >
          {form.hiddenInputs.map(input => (
            <HiddenInput key={input.name} {...input} />
          ))}
          <div className="corporate-code-input__input">
            <TextInput {...form.input} />
          </div>
          <div>
            <Button text={form.submit} type="submit" />
          </div>
        </form>
      </div>
    </ContentContainer>
  </MyPageLayout>
);

export default CorporateCodeInput;
