import ArrowDown from '@sats-group/icons/24/arrow-down';
import ArrowUp from '@sats-group/icons/24/arrow-up';
import type { FormEvent } from 'react';
import React, { useMemo, useState } from 'react';
import Collapse from 'react-tiny-collapse';
import type { JsonObject } from 'type-fest';

import Button from '@sats-group/ui-lib/react/button';
import Text from '@sats-group/ui-lib/react/text';

import { replaceQueryParameters } from 'shared/replace-query-parameters';

import CleanLink from 'client/components/clean-link/clean-link';
import { mod } from 'client/helpers/add-bem-modifiers';
import { overrideStatus, post } from 'client/helpers/api-helper';
import { publish } from 'client/helpers/messages';
import DynamicImage from 'components/dynamic-image/dynamic-image';
import ElementInterpolator from 'components/element-interpolator/element-interpolator';
import Spinner from 'components/spinner/spinner';

import type { ScheduleEvent as Props } from './group-exercise-schedule.types';
import ScheduleMetadata from './schedule-metadata';

const getMessage = (body: JsonObject) => {
  if (!('message' in body)) {
    return;
  }

  if (typeof body.message !== 'string') {
    return;
  }

  return body.message;
};

const ScheduleEvent: React.FunctionComponent<Props> = ({
  bookEndpoint,
  bookLabel,
  cancelLabel,
  errorMessages,
  exitWaitingListLabel,
  groupExerciseLink,
  hasWaitingList,
  id,
  image,
  isBooked,
  isVisible,
  joinWaitingListLabel,
  joinWaitingListTemplate,
  metadata,
  participationId,
  ptDescription,
  ptLink,
  text,
  unbookEndpoint,
  waitingListCount,
  waitingListPosition,
  waitingListPositionTemplate,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isCurrentlyBooked, setIsCurrentlyBooked] = useState(isBooked);
  const [currentParticipationId, setCurrentParticipationId] =
    useState(participationId);
  const [currentWaitingListPostion, setCurrentWaitingListPostion] =
    useState(waitingListPosition);
  const [currentlyHasWaitingList, setCurrentlyHasWaitingList] =
    useState(hasWaitingList);

  const handleBook = (event: FormEvent) => {
    event.preventDefault();
    setIsSubmitting(true);
    post<{
      hasWaitingList: boolean;
      participationId: string;
      waitingListPosition: number;
    }>(bookEndpoint, { id })
      .then(data => {
        setIsCurrentlyBooked(true);
        setCurrentParticipationId(data.participationId);
        setCurrentWaitingListPostion(data.waitingListPosition);
        setCurrentlyHasWaitingList(data.hasWaitingList);
      })
      .catch(
        overrideStatus({
          400: body => {
            publish({
              text: getMessage(body) ?? errorMessages.book.badRequest,
              theme: 'error',
            });
            return true;
          },
          401: body => {
            publish({
              text: getMessage(body) ?? errorMessages.book.unauthorized,
              theme: 'error',
            });
            return true;
          },
          403: body => {
            publish({
              text: getMessage(body) ?? errorMessages.book.forbidden,
              theme: 'error',
            });
            return true;
          },
          409: body => {
            publish({
              text: getMessage(body) ?? errorMessages.book.conflict,
              theme: 'error',
            });
            return true;
          },
          502: body => {
            publish({
              text: getMessage(body) ?? errorMessages.book.unknownDownstream,
              theme: 'error',
            });
            return true;
          },
        })
      )
      .catch(() =>
        publish({ text: errorMessages.book.unknown, theme: 'error' })
      )
      .finally(() => setIsSubmitting(false));
  };

  const handleUnbook = (event: FormEvent) => {
    event.preventDefault();
    setIsSubmitting(true);
    post(replaceQueryParameters(unbookEndpoint), {
      participationId: String(currentParticipationId),
    })
      .then(() => {
        setIsCurrentlyBooked(false);
        setCurrentParticipationId(undefined);
        setCurrentlyHasWaitingList(hasWaitingList);
      })
      .catch(
        overrideStatus({
          400: () => {
            publish({ text: errorMessages.unbook.badRequest, theme: 'error' });
            return true;
          },
          401: () => {
            publish({
              text: errorMessages.unbook.unauthorized,
              theme: 'error',
            });
            return true;
          },
          502: () => {
            publish({
              text: errorMessages.unbook.unknownDownstream,
              theme: 'error',
            });
            return true;
          },
        })
      )
      .catch(() =>
        publish({ text: errorMessages.unbook.unknown, theme: 'error' })
      )
      .finally(() => setIsSubmitting(false));
  };

  const waitingListPositionElement = (
    <ElementInterpolator
      template={waitingListPositionTemplate}
      elements={{
        number: (
          <Text elementName="span" theme={Text.themes.emphasis}>
            {currentWaitingListPostion}
          </Text>
        ),
      }}
    />
  );

  const joinWaitingListElement = (
    <ElementInterpolator
      template={joinWaitingListTemplate}
      elements={{
        number: (
          <Text elementName="span" theme={Text.themes.emphasis}>
            {waitingListCount}
          </Text>
        ),
      }}
    />
  );

  const hasGeneralInfo = useMemo(
    () => Boolean(groupExerciseLink || ptLink || text),
    [groupExerciseLink, ptLink, text]
  );

  return (
    <li
      className={mod('group-exercise-schedule__event', {
        clickable: hasGeneralInfo,
      })}
    >
      {isSubmitting ? <Spinner theme={Spinner.themes.overlay} /> : null}
      <div className="group-exercise-schedule__event-content-wrapper">
        <div className="group-exercise-schedule__event-content">
          {hasGeneralInfo ? (
            <button
              className="group-exercise-schedule__event-metadata-button"
              onClick={() => setIsOpen(prevState => !prevState)}
            >
              <ScheduleMetadata
                {...metadata}
                waitingList={
                  currentlyHasWaitingList &&
                  (isCurrentlyBooked
                    ? waitingListPositionElement
                    : joinWaitingListElement)
                }
              />
              <div className="group-exercise-schedule__arrow">
                {isOpen ? <ArrowUp /> : <ArrowDown />}
              </div>
            </button>
          ) : (
            <div className="group-exercise-schedule__event-metadata">
              <ScheduleMetadata
                {...metadata}
                waitingList={
                  currentlyHasWaitingList &&
                  (isCurrentlyBooked
                    ? waitingListPositionElement
                    : joinWaitingListElement)
                }
              />
            </div>
          )}
          <div className="group-exercise-schedule__actions">
            {currentlyHasWaitingList ? (
              isCurrentlyBooked ? (
                <form
                  className="group-exercise-schedule__waiting-list"
                  onSubmit={handleUnbook}
                >
                  <Button
                    className="group-exercise-schedule__action"
                    variant={Button.variants.secondary}
                    disabled={isSubmitting}
                    text={exitWaitingListLabel}
                    type="submit"
                    size={Button.sizes.small}
                  />
                  <span className="group-exercise-schedule__waiting-list-description">
                    {waitingListPositionElement}
                  </span>
                </form>
              ) : (
                <form
                  className="group-exercise-schedule__waiting-list"
                  onSubmit={handleBook}
                >
                  <Button
                    variant={Button.variants.primary}
                    disabled={isSubmitting}
                    text={joinWaitingListLabel}
                    type="submit"
                    size={Button.sizes.small}
                    wide
                  />
                  <span className="group-exercise-schedule__waiting-list-description">
                    {joinWaitingListElement}
                  </span>
                </form>
              )
            ) : isCurrentlyBooked ? (
              <form onSubmit={handleUnbook}>
                <Button
                  disabled={isSubmitting}
                  size={Button.sizes.small}
                  text={cancelLabel}
                  type="submit"
                  variant={Button.variants.secondary}
                  wide
                />
              </form>
            ) : (
              <form onSubmit={handleBook}>
                <Button
                  data-test-bookable={!isCurrentlyBooked && isVisible}
                  disabled={isSubmitting}
                  size={Button.sizes.small}
                  text={bookLabel}
                  type="submit"
                  wide
                />
              </form>
            )}
          </div>
        </div>
        {hasGeneralInfo ? (
          <Collapse duration={300} animateChildren={true} isOpen={true}>
            {isOpen ? (
              <div className="group-exercise-schedule__event-general">
                {image ? (
                  <div className="group-exercise-schedule__event-image">
                    <DynamicImage
                      theme={DynamicImage.themes.rounded}
                      {...image}
                    />
                  </div>
                ) : null}
                <div className="group-exercise-schedule__details">
                  <div>
                    {text ? (
                      <Text elementName="p" size={Text.sizes.basic}>
                        {text}
                      </Text>
                    ) : null}
                    {groupExerciseLink ? (
                      <CleanLink
                        className="group-exercise-schedule__event-link"
                        {...groupExerciseLink}
                      />
                    ) : undefined}
                  </div>
                  {ptLink ? (
                    <div>
                      <CleanLink
                        className="group-exercise-schedule__event-link"
                        {...ptLink}
                      />
                      <Text
                        size={Text.sizes.small}
                        className="group-exercise-schedule__pt-description"
                      >
                        {ptDescription}
                      </Text>
                    </div>
                  ) : undefined}
                </div>
              </div>
            ) : null}
          </Collapse>
        ) : null}
      </div>
    </li>
  );
};

export default ScheduleEvent;
