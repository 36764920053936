import React from 'react';

import Text from '@sats-group/ui-lib/react/text';

import CleanLink from 'client/components/clean-link/clean-link';
import DynamicImage from 'components/dynamic-image/dynamic-image';

import type { ArticleLinkCard as Props } from './article-link-card.types';

const ArticleLinkCard: React.FunctionComponent<Props> = ({
  href,
  image,
  title,
}) => (
  <div className="article-link-card">
    <CleanLink
      className="article-link-card__link"
      href={href}
      aria-label={title}
    >
      {image ? (
        <div className="article-link-card__image">
          <DynamicImage
            aspectRatio={DynamicImage.aspectRatios.sixteenNine}
            {...image}
          />
        </div>
      ) : null}
      <div className="article-link-card__data-wrapper">
        <Text
          size={Text.sizes.large}
          theme={Text.themes.emphasis}
          className="article-link-card__title"
        >
          {title}
        </Text>
      </div>
    </CleanLink>
  </div>
);

export default ArticleLinkCard;
