import React from 'react';
import Banner from '@sats-group/ui-lib/react/banner';
import Text from '@sats-group/ui-lib/react/text';

import ContentContainer from 'components/content-container/content-container';
import Logo from 'components/logo/logo';
import Spinner from 'components/spinner/spinner';

import type { FlowStatusLayout as Props } from './flow-status-layout.types';

const FlowStatusLayout: React.FunctionComponent<Props> = ({
  logo,
  messages,
  spin,
  text,
}) => (
  <div className="flow-status-layout">
    <ContentContainer>
      <div className="flow-status-layout__content">
        <header className="flow-status-layout__logo">
          <Logo {...logo} size="small" />
        </header>
        <main className="flow-status-layout__card">
          {messages.map(entry => (
            <div key={entry.text}>
              <Banner {...entry} />
            </div>
          ))}
          {spin ? (
            <div>
              <Spinner theme={Spinner.themes.centered} />
            </div>
          ) : null}
          {text ? (
            <div className="flow-status-layout__text">
              <Text>{text}</Text>
            </div>
          ) : null}
        </main>
      </div>
    </ContentContainer>
  </div>
);

export default FlowStatusLayout;
