import React, { useRef, useState } from 'react';

import HiddenInput from '@sats-group/ui-lib/react/hidden-input';
import Text from '@sats-group/ui-lib/react/text';
import TextInput from '@sats-group/ui-lib/react/text-input';

import { replaceQueryParameters } from 'shared/replace-query-parameters';

import { get } from 'client/helpers/api-helper';
import PrefixInput from 'components/prefix-input/prefix-input';
import RadioList from 'components/radio-list/radio-list';

import type { RegistrationFormInputs as Props } from './registration-form.types';

const RegistrationFormInputs: React.FunctionComponent<Props> = ({
  address,
  birthDateTitle,
  birthDay,
  birthMonth,
  birthYear,
  defaultValues,
  email,
  firstName,
  lastName,
  passwordText,
  passwordTitle,
  phone,
  postalAreaEndpoint,
  postalAreaName,
  postalCode,
  sex,
  socialSecurityNumber,
  title,
}) => {
  const [postalArea, setPostalArea] = useState('');
  const postalCodeValue = useRef('');

  const handlePostalCodeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;

    setPostalArea('');

    if (value.length <= 3) {
      return;
    }

    postalCodeValue.current = value;

    get<{ postalArea: string }>(
      replaceQueryParameters(postalAreaEndpoint, { code: value })
    )
      .then(data => {
        if (postalCodeValue.current === value) {
          setPostalArea(data.postalArea);
        }
      })
      .catch(() => {});
  };

  return (
    <>
      {title ? (
        <Text
          elementName="h4"
          size={Text.sizes.large}
          theme={Text.themes.emphasis}
        >
          {title}
        </Text>
      ) : null}
      <TextInput
        {...firstName}
        autoComplete="given-name"
        defaultValue={defaultValues[firstName.name]}
        required
      />
      <TextInput
        {...lastName}
        autoComplete="family-name"
        defaultValue={defaultValues[lastName.name]}
        required
      />
      <TextInput
        {...email}
        autoComplete="email"
        defaultValue={defaultValues[email.name]}
        required
        type="email"
      />
      {birthDateTitle && birthDay && birthMonth && birthYear ? (
        <fieldset className="registration-form__group">
          <summary>{birthDateTitle}</summary>
          <div className="registration-form__group-inputs">
            <TextInput
              {...birthDay}
              defaultValue={defaultValues[birthDay.name]}
              required
              className="registration-form__date-input"
            />
            <TextInput
              {...birthMonth}
              defaultValue={defaultValues[birthMonth.name]}
              required
              className="registration-form__date-input"
            />
            <TextInput
              {...birthYear}
              defaultValue={defaultValues[birthYear.name]}
              required
              className="registration-form__date-input"
            />
          </div>
        </fieldset>
      ) : null}
      {sex ? (
        <RadioList
          // NOTE: The `RadioList` component doesn't have a `name` prop so this had to be hard coded
          defaultCheckedValue={defaultValues.gender}
          required
          theme={RadioList.themes.horizontal}
          {...sex}
        />
      ) : null}
      {socialSecurityNumber ? (
        <TextInput
          {...socialSecurityNumber}
          defaultValue={defaultValues[socialSecurityNumber.name]}
          required
        />
      ) : null}

      {address ? (
        <div className="registration-form__address">
          <TextInput
            {...address}
            autoComplete="street-address"
            defaultValue={defaultValues[address.name]}
            required
          />
        </div>
      ) : null}

      {postalCode ? (
        <div className="registration-form__postal-code">
          <div>
            <TextInput
              {...postalCode}
              autoComplete="postal-code"
              defaultValue={defaultValues[postalCode?.name]}
              required
              onChange={handlePostalCodeChange}
            />
          </div>

          <div className="registration-form__postal-area">
            <HiddenInput value={postalArea} name={postalAreaName} />
            {postalArea ? (
              <Text
                elementName="h2"
                size={Text.sizes.small}
                theme={Text.themes.normal}
              >
                {postalArea}
              </Text>
            ) : null}
          </div>
        </div>
      ) : null}

      {passwordText ? (
        <div className="registration-form__password">
          <Text
            className="registration-form__password-title"
            elementName="h2"
            size={Text.sizes.basic}
            theme={Text.themes.normal}
          >
            {passwordTitle}
          </Text>
          <Text elementName="p" size={Text.sizes.small}>
            {passwordText}
          </Text>
        </div>
      ) : null}

      {phone ? (
        <PrefixInput
          {...phone}
          autoComplete="tel-national"
          defaultValue={defaultValues[phone.name]}
          required
          type="tel"
        />
      ) : null}
    </>
  );
};

export default RegistrationFormInputs;
