import React, { useEffect } from 'react';

import Text from '@sats-group/ui-lib/react/text';

import { replaceQueryParameters } from 'shared/replace-query-parameters';

import CheckoutLayout from 'components/checkout-layout/checkout-layout';
import CheckoutSummaryContent from 'components/checkout-summary-content/checkout-summary-content';
import CheckoutSummaryWrapper from 'components/checkout-summary-wrapper/checkout-summary-wrapper';
import ContentContainer from 'components/content-container/content-container';
import InfoBox from 'components/info-box/info-box';
import Payment from 'components/payment/payment';
import Testimonials from 'components/testimonials/testimonials';
import useUrlState from 'hooks/use-url-state';

import type { PaymentPage as Props } from './payment-page.types';
import PaymentSummary from './payment-summary';

const PaymentPage: React.FunctionComponent<Props> = ({
  consentText,
  editorialCard,
  layout,
  payment,
  paymentProviderText,
  paymentSummary,
  paymentTitle,
  stalePageRedirectUrl,
  successRedirectUrl,
  summary,
  testimonials,
}) => {
  const [query] = useUrlState();
  const { stale } = query;

  useEffect(() => {
    if (stale) {
      window.location.replace(stalePageRedirectUrl);
    }
  }, [stale]);

  const handlePaymentComplete = (transactionId: string) =>
    window.location.assign(
      replaceQueryParameters(
        successRedirectUrl,
        Object.assign({}, query, { transactionId })
      )
    );

  return (
    <CheckoutLayout {...layout}>
      <div className="payment-page">
        <ContentContainer>
          <div className="payment-page__content">
            {editorialCard ? (
              <div className="payment-page__card">
                <InfoBox {...editorialCard} />
              </div>
            ) : null}

            <div className="payment-page__payment">
              <PaymentSummary {...paymentSummary} />

              <div className="payment-page__payment-form">
                <Text
                  className="payment-page__payment-title"
                  elementName="h2"
                  theme={Text.themes.normal}
                  size={Text.sizes.headline3}
                >
                  {paymentTitle}
                </Text>
                <Payment
                  {...payment}
                  isMemberIdRequiredForIntegrations={true}
                  onPaymentComplete={handlePaymentComplete}
                />

                <Text className="payment-page__consent" size={Text.sizes.small}>
                  {consentText}
                </Text>

                <Text
                  className="payment-page__provider"
                  size={Text.sizes.small}
                >
                  {paymentProviderText}
                </Text>
              </div>
            </div>

            <CheckoutSummaryWrapper>
              <CheckoutSummaryContent {...summary} />
            </CheckoutSummaryWrapper>

            {testimonials ? (
              <div className="payment-page__testimonials">
                <Testimonials {...testimonials} />
              </div>
            ) : null}
          </div>
        </ContentContainer>
      </div>
    </CheckoutLayout>
  );
};

export default PaymentPage;
