import React from 'react';

import TrackOrSticky from 'components/track-or-sticky/track-or-sticky';

import {
  summaryContent,
  summaryFooter,
} from './change-membership-summary-content';
import type { ChangeMembershipSummary as Props } from './change-membership-summary.types';

const ChangeMembershipSummary: React.FunctionComponent<Props> = summary => {
  const content = summaryContent(summary);
  const footer = summaryFooter(summary);

  return (
    <div className="change-membership-summary">
      <TrackOrSticky
        title={summary.summaryTitle}
        content={content}
        footer={footer}
      />
    </div>
  );
};

export default ChangeMembershipSummary;
