/* eslint-disable react/no-multi-comp */
import React, { useState } from 'react';
import Banner from '@sats-group/ui-lib/react/banner';
import Link from '@sats-group/ui-lib/react/link';
import Text from '@sats-group/ui-lib/react/text';

import ProductOption from 'components/product-option/product-option';
import PtShopLayout from 'components/pt-shop-layout/pt-shop-layout';
import SelfServiceSummary from 'components/self-service-summary/self-service-summary';
import Spinner from 'components/spinner/spinner';

import type { PtShop as Props } from './pt-shop.types';

const Primary = ({ actionGroups, banners }: Props['layout']['primary']) => {
  const [isLoading, setIsLoading] = useState(false);
  const handleNavigation = () => setIsLoading(true);

  return (
    <div className="pt-shop__primary">
      {isLoading ? (
        <div className="pt-shop__loader">
          <Spinner theme={Spinner.themes.overlay} />
        </div>
      ) : null}
      {banners.length ? (
        <div className="pt-shop__banners">
          {banners.map(banner => (
            <div key={banner.text}>
              <Banner {...banner} />
            </div>
          ))}
        </div>
      ) : null}
      {actionGroups.length ? (
        <div className="pt-shop__groups">
          {actionGroups.map(group => (
            <div className="pt-shop__group" key={group.key}>
              <Text size={Text.sizes.large} theme={Text.themes.emphasis}>
                {group.title}
              </Text>
              <p className="pt-shop__paragraph">
                {group.description ? (
                  <Text elementName="span">{group.description}</Text>
                ) : null}
                {group.description && group.link ? ' ' : null}
                {group.link ? <Link {...group.link} /> : null}
              </p>
              {group.actions.map(action => (
                <div key={action.href}>
                  <ProductOption {...action} onClick={handleNavigation} />
                </div>
              ))}
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
};

const Secondary = ({ collapsible, ...rest }: Props['layout']['secondary']) => (
  <React.Fragment>
    <div className="pt-shop__summary pt-shop__summary--static">
      <SelfServiceSummary {...rest} />
    </div>
    <div className="pt-shop__summary pt-shop__summary--sticky">
      <SelfServiceSummary {...rest} collapsible={collapsible} />
    </div>
  </React.Fragment>
);

const PtShop: React.FC<Props> = ({ layout }) => (
  <PtShopLayout
    {...layout}
    primaryRenderer={Primary}
    secondaryRenderer={Secondary}
  />
);
export default PtShop;
