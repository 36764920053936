import ArrowDown from '@sats-group/icons/18/arrow-down';
import ArrowUp from '@sats-group/icons/18/arrow-up';
import cn from 'classnames';
import React from 'react';
import Collapse from 'react-tiny-collapse';
import Button from '@sats-group/ui-lib/react/button';
import LinkButton from '@sats-group/ui-lib/react/link-button';
import Text from '@sats-group/ui-lib/react/text';
import useToggle from '@sats-group/ui-lib/react/hooks/use-toggle';

import Price from '../price/price';
import type { SelfServiceSummary as Props } from './self-service-summary.types';

/**
 * See https://www.figma.com/design/dQeQgh6sANMhWBUNtgXQNN/satsweb-components?node-id=7157-13618
 */
const SelfServiceSummary = ({
  actions,
  collapsible,
  discount,
  info,
  price,
  summary,
  title,
}: Props) => {
  const [isOpen, toggleIsOpen] = useToggle(false);

  return (
    <div className="self-service-summary">
      <div className="self-service-summary__head">
        {collapsible ? (
          <div>
            <Button
              aria-controls={collapsible.id}
              aria-expanded={isOpen}
              ariaLabel={isOpen ? collapsible.close : collapsible.open}
              leadingIcon={isOpen ? <ArrowDown /> : <ArrowUp />}
              onClick={toggleIsOpen}
              size={Button.sizes.small}
              variant={Button.variants.secondaryWhite}
            />
          </div>
        ) : null}
        <div className="self-service-summary__title">
          <Text size={Text.sizes.headline3} theme={Text.themes.emphasis}>
            {title}
          </Text>
        </div>
        {actions.secondary ? (
          <div className="self-service-summary__action">
            <LinkButton
              {...actions.secondary}
              size={LinkButton.sizes.small}
              variant={LinkButton.variants.secondaryWhite}
            />
          </div>
        ) : null}
      </div>
      <Collapse
        componentProps={{ id: collapsible ? collapsible.id : undefined }}
        isOpen={collapsible ? isOpen : true}
      >
        <div className="self-service-summary__content">
          <div className="self-service-summary__summary">
            <ul className="self-service-summary__details">
              {summary.details.map(entry => (
                <li key={entry.name}>
                  <Text size={Text.sizes.small} theme={Text.themes.emphasis}>
                    {entry.name}
                  </Text>
                  <Text size={Text.sizes.small}>{entry.value}</Text>
                </li>
              ))}
            </ul>
            {summary.disclaimer ? (
              <div className="self-service-summary__muted">
                <Text size={Text.sizes.small}>{summary.disclaimer}</Text>
              </div>
            ) : null}
          </div>
          {discount ? (
            <div className="self-service-summary__discount">
              {discount.information ? (
                <div>
                  <Text size={Text.sizes.small} theme={Text.themes.emphasis}>
                    {discount.information.title}
                  </Text>
                  <Text size={Text.sizes.small}>
                    {discount.information.description}
                  </Text>
                </div>
              ) : null}
              <ul className="self-service-summary__details">
                {discount.details.map(entry => (
                  <li key={entry.name}>
                    <Text size={Text.sizes.small} theme={Text.themes.emphasis}>
                      {entry.name}
                    </Text>
                    <Text size={Text.sizes.small}>{entry.value}</Text>
                  </li>
                ))}
              </ul>
            </div>
          ) : null}
        </div>
      </Collapse>
      {price ? (
        <div
          className={cn('self-service-summary__price', {
            'self-service-summary__price--compact': collapsible,
          })}
        >
          <Text size={Text.sizes.small}>{price.title}</Text>
          <div>
            <Price {...price.props} />
          </div>
          {collapsible && actions.primary ? (
            <div>
              <LinkButton
                {...actions.primary}
                size={LinkButton.sizes.large}
                variant={LinkButton.variants.complete}
              />
            </div>
          ) : null}
        </div>
      ) : null}
      {info ? (
        <div
          className={cn('self-service-summary__info', {
            'self-service-summary__info--attached': collapsible,
          })}
        >
          <Text size={Text.sizes.small}>{info}</Text>
        </div>
      ) : null}
      {!collapsible && actions.primary ? (
        <div className="self-service-summary__primary">
          <LinkButton
            {...actions.primary}
            size={LinkButton.sizes.large}
            variant={LinkButton.variants.complete}
          />
        </div>
      ) : null}
    </div>
  );
};

export default SelfServiceSummary;
