import cn from 'classnames';
import React from 'react';

import Elixia from '@sats-group/ui-lib/react/logos/e-avatar';
import Sats from '@sats-group/ui-lib/react/logos/s-avatar';

import type { LogoLetter as Props } from './logo-letter.types';
import { brands, colors } from './logo-letter.types';

const LogoLetter: React.FunctionComponent<Props> & {
  colors: typeof colors;
  brands: typeof brands;
} = ({ brand = brands.sats, color = colors.light }) => (
  <div
    aria-hidden
    className={cn('logo', {
      'logo--dark': color === colors.dark,
      'logo--light': color === colors.light,
    })}
  >
    {brand === brands.sats ? <Sats /> : <Elixia />}
  </div>
);

LogoLetter.colors = colors;
LogoLetter.brands = brands;

export default LogoLetter;
