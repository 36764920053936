import React from 'react';

import LinkButton from '@sats-group/ui-lib/react/link-button';
import Text from '@sats-group/ui-lib/react/text';

import ContentContainer from 'components/content-container/content-container';
import MyPageLayout from 'components/my-page-layout/my-page-layout';

import type { CorporateCodeOverview as Props } from './corporate-code-overview.types';

const CorporateCodeOverview: React.FunctionComponent<Props> = ({
  canProceed,
  discounts,
  myPageLayout,
}) => (
  <MyPageLayout {...myPageLayout}>
    <ContentContainer>
      <div className="corporate-code-overview">
        {discounts.map(discount => (
          <div
            className="corporate-code-overview__discount"
            key={discount.name}
          >
            <Text size={Text.sizes.large} theme={Text.themes.normal}>
              {discount.title}
            </Text>
            <dl>
              <Text
                elementName="dt"
                size={Text.sizes.small}
                theme={Text.themes.emphasis}
              >
                {discount.nameLabel}
              </Text>
              <Text
                elementName="dd"
                size={Text.sizes.basic}
                theme={Text.themes.normal}
              >
                {discount.name}
              </Text>
              <Text
                elementName="dt"
                size={Text.sizes.small}
                theme={Text.themes.emphasis}
              >
                {discount.companyLabel}
              </Text>
              <Text
                elementName="dd"
                size={Text.sizes.basic}
                theme={Text.themes.normal}
              >
                {discount.company}
              </Text>
            </dl>
          </div>
        ))}
        {canProceed ? (
          <React.Fragment>
            <div>
              <LinkButton {...canProceed.next} />
            </div>
            <div className="corporate-code-overview__disclaimer">
              <Text size={Text.sizes.small}>{canProceed.disclaimer}</Text>
            </div>
          </React.Fragment>
        ) : null}
      </div>
    </ContentContainer>
  </MyPageLayout>
);

export default CorporateCodeOverview;
