import type AdyenCheckoutType from '@adyen/adyen-web';
import type _DropinElement from '@adyen/adyen-web/dist/types/components/Dropin';
import type _Core from '@adyen/adyen-web/dist/types/core';
import type { PaymentAction } from '@adyen/adyen-web/dist/types/types';
import { useEffect, useState } from 'react';

import { appendScript } from 'client/helpers/append-script';
import { appendStylesheet } from 'client/helpers/append-stylesheet';

export type ApiResponse = {
  action?: PaymentAction;
  resultCode: string;
  pspReference?: string;
};

export type AdyenState = {
  data: {
    billingAddress: unknown;
    browserInfo: unknown;
    paymentMethod: Record<string, unknown>;
  };
  [key: string]: unknown;
};

export type Core = _Core;
export type DropinElement = _DropinElement;

declare global {
  interface Window {
    AdyenCheckout?: typeof AdyenCheckoutType;
  }
}

/**
 * https://docs.adyen.com/online-payments/release-notes/?title%5B0%5D=Web+Components%2FDrop-in
 */
export const useAdyen = (
  onError: () => void
): [isLoading: boolean, hasLoaded: boolean] => {
  const [state, setState] = useState({ isLoading: false, hasLoaded: false });

  useEffect(() => {
    if (window.AdyenCheckout) {
      setState({
        isLoading: false,
        hasLoaded: true,
      });
      return;
    }

    setState({
      isLoading: true,
      hasLoaded: false,
    });

    appendStylesheet(
      'https://checkoutshopper-live.adyen.com/checkoutshopper/sdk/5.71.2/adyen.css',
      {
        crossorigin: 'anonymous',
        integrity:
          'sha384-5MvB4RnzvviA3VBT4KYABZ4HXNZG5LRqREEgd41xt/pf/QvKmsj2O9GuNuywRXx9',
      }
    );

    appendScript(
      'https://checkoutshopper-live.adyen.com/checkoutshopper/sdk/5.71.2/adyen.js',
      () => {
        setState({
          isLoading: false,
          hasLoaded: Boolean(window.AdyenCheckout),
        });
      },
      {
        crossorigin: 'anonymous',
        integrity:
          'sha384-jc/mYZi/5W1D4/eM5hc6xo0EWCbvV1AuWlZvXesp1c+ouFgGLk0eMlBzBT5mKGcN',
        onError: () => {
          onError();
          setState({
            isLoading: false,
            hasLoaded: Boolean(window.AdyenCheckout),
          });
        },
      }
    );
  }, []);

  return [state.isLoading, state.hasLoaded];
};
