import cn from 'classnames';
import React from 'react';

import Text from '@sats-group/ui-lib/react/text';

import RichText from 'components/rich-text/rich-text';

import type { TextModule as Props } from './text-module.types';

const TextModule: React.FunctionComponent<Props> = ({
  body,
  theme,
  tall,
  title,
  titleSize = Text.sizes.headline2,
}) => (
  <div
    className={cn('text-module', {
      'text-module--tall': tall,
      [`text-module--${theme}`]: theme,
    })}
  >
    {title ? (
      <Text
        className="text-module__title"
        elementName="h2"
        size={titleSize}
        theme={Text.themes.emphasis}
      >
        {title}
      </Text>
    ) : null}
    <RichText {...body} />
  </div>
);

export default TextModule;
