import React from 'react';

import Text from '@sats-group/ui-lib/react/text';

import type { NewPriceCard as Props } from './new-price-card.types';
import Banner from '@sats-group/ui-lib/react/banner';

const NewPriceCard: React.FunctionComponent<Props> = ({
  banner,
  price,
  summary,
  title,
}) => (
  <div className="new-price-card">
    <div className="new-price-card__title">
      <Text
        elementName="h2"
        size={Text.sizes.large}
        theme={Text.themes.emphasis}
        tight
      >
        {title}
      </Text>
    </div>
    {banner ? <Banner {...banner} icon nested /> : null}
    {summary.length ? (
      <table className="new-price-card__summary">
        {summary.map(entry => (
          <tr key={entry.key}>
            <Text
              elementName="th"
              size={Text.sizes.small}
              theme={Text.themes.emphasis}
              tight
            >
              {entry.key}
            </Text>
            <Text elementName="td" size={Text.sizes.small} tight>
              {entry.value}
            </Text>
          </tr>
        ))}
      </table>
    ) : null}
    <div className="new-price-card__price-wrapper">
      <table className="new-price-card__price">
        <tr>
          <Text
            elementName="th"
            size={Text.sizes.small}
            theme={Text.themes.emphasis}
            tight
          >
            {price.current.key}
          </Text>
          <td>
            <Text
              size={Text.sizes.headline3}
              theme={Text.themes.emphasis}
              tight
            >
              {price.current.value}
            </Text>
            <Text size={Text.sizes.small} tight>
              {price.current.period}
            </Text>
          </td>
        </tr>
        <tr>
          <Text
            elementName="th"
            size={Text.sizes.small}
            theme={Text.themes.emphasis}
            tight
          >
            {price.next.key}
          </Text>
          <td>
            <Text
              size={Text.sizes.headline2}
              theme={Text.themes.emphasis}
              tight
            >
              {price.next.value}
            </Text>
            <Text size={Text.sizes.small} tight>
              {price.next.period}
            </Text>
          </td>
        </tr>
      </table>
    </div>
  </div>
);

export default NewPriceCard;
