import React, { type FormEvent, useState } from 'react';

import Button from '@sats-group/ui-lib/react/button';
import Checkbox from '@sats-group/ui-lib/react/checkbox';
import HiddenInput from '@sats-group/ui-lib/react/hidden-input';
import Message from '@sats-group/ui-lib/react/message';
import Modal from '@sats-group/ui-lib/react/modal';
import Select from '@sats-group/ui-lib/react/select';
import Text from '@sats-group/ui-lib/react/text';
import TextInput from '@sats-group/ui-lib/react/text-input';

import ContentContainer from 'components/content-container/content-container';
import CountryCodeSelect from 'components/country-code-select/country-code-select';
import Layout from 'components/layout/layout';
import RichText from 'components/rich-text/rich-text';
import Spinner from 'components/spinner/spinner';

import type { LeadsFormPage as Props } from './leads-form-page.types';

const LeadsFormPage: React.FunctionComponent<Props> = ({
  clubSelect,
  emailInput,
  endpoint,
  firstNameInput,
  hiddenInputs,
  intro,
  lastNameInput,
  layout,
  marketingConsent,
  messages,
  over18,
  phoneInput,
  submit,
  terms,
  title,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isMarketingConsentModalOpen, setMarketingConsentModalIsOpen] =
    useState(false);
  const [isTermsModalOpen, setTermsModalIsOpen] = useState(false);

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    if (isLoading) {
      event.preventDefault();
    }
    setIsLoading(true);
  };

  return (
    <Layout {...layout}>
      <div className="leads-form-page">
        <ContentContainer className="leads-form-page__top">
          <Text
            className="leads-form-page__text"
            elementName="h1"
            theme={Text.themes.headline}
            italic
            size={Text.sizes.headline2}
          >
            {title}
          </Text>
          {messages.map(message => (
            <div key={message.text}>
              <Message {...message} />
            </div>
          ))}
          {intro ? (
            <Text
              className="leads-form-page__text"
              elementName="h2"
              size={Text.sizes.headline3}
            >
              {intro}
            </Text>
          ) : null}
        </ContentContainer>
        <ContentContainer theme={ContentContainer.themes.veryNarrow}>
          {isLoading ? <Spinner theme={Spinner.themes.overlay} /> : null}
          {terms ? (
            <form
              className="leads-form-page__form"
              method="POST"
              action={endpoint}
              onSubmit={handleSubmit}
            >
              {hiddenInputs.map(input => (
                <HiddenInput key={input.name} {...input} />
              ))}
              <TextInput {...firstNameInput} />
              <TextInput {...lastNameInput} />
              <CountryCodeSelect {...phoneInput}></CountryCodeSelect>
              <TextInput {...emailInput} />
              {over18 ? <Checkbox {...over18} /> : null}
              {clubSelect ? <Select {...clubSelect} /> : null}
              {terms ? (
                <div className="leads-form-page__terms">
                  <Checkbox {...terms.checkbox} />
                  <div>
                    <Button
                      {...terms.button}
                      variant={Button.variants.secondary}
                      size={Button.sizes.small}
                      onClick={() => setTermsModalIsOpen(true)}
                    />
                  </div>
                  {isTermsModalOpen ? (
                    <Modal
                      {...terms.modal}
                      onClose={() => setTermsModalIsOpen(false)}
                    >
                      <RichText {...terms.text} />
                    </Modal>
                  ) : null}
                </div>
              ) : null}
              {marketingConsent && marketingConsent.terms ? (
                <div className="leads-form-page__terms">
                  <Checkbox {...marketingConsent.checkbox} />
                  <div>
                    <Button
                      {...marketingConsent.button}
                      variant={Button.variants.secondary}
                      size={Button.sizes.small}
                      onClick={() => setMarketingConsentModalIsOpen(true)}
                    />
                  </div>
                  {isMarketingConsentModalOpen ? (
                    <Modal
                      {...marketingConsent.modal}
                      onClose={() => setMarketingConsentModalIsOpen(false)}
                    >
                      <RichText {...marketingConsent.terms} />
                    </Modal>
                  ) : null}
                </div>
              ) : null}
              <Button type="submit" text={submit} />
            </form>
          ) : null}
        </ContentContainer>
      </div>
    </Layout>
  );
};

export default LeadsFormPage;
