/* eslint-disable react/no-multi-comp */
import React from 'react';

import Expander from '@sats-group/ui-lib/react/expander';

import WebAdminLayout from 'components/web-admin-layout/web-admin-layout';

import SitemapNode from './sitemap-node';
import type { WebAdminSitemap as Props } from './web-admin-sitemap.types';

const Item: React.FC<{
  cells: [string, string][];
  headers: [string, string];
}> = ({ cells, headers }) => (
  <table>
    <thead>
      <tr>
        {headers.map(name => (
          <th align="left" key={name}>
            {name}
          </th>
        ))}
      </tr>
    </thead>
    <tbody>
      {cells.map(([name, value]) => (
        <tr key={name}>
          <td>{name}</td>
          <td>{value}</td>
        </tr>
      ))}
    </tbody>
  </table>
);

const WebAdminSitemap: React.FunctionComponent<Props> = ({
  firstLevelSlugs,
  layout,
  restSlugs,
  sitemap,
}) => (
  <WebAdminLayout {...layout}>
    <div className="web-admin-sitemap">
      <div>
        <Expander
          items={[
            {
              listItemProps: {
                actions: [],
                id: 'first-level-slugs',
                title: 'App Controlled First Level Slugs',
              },
              props: {
                cells: firstLevelSlugs,
                headers: ['Name', 'Value'],
              },
            },
            {
              listItemProps: {
                actions: [],
                id: 'rest-slugs',
                title: 'App Controlled Rest Slugs',
              },
              props: {
                cells: restSlugs,
                headers: ['Name', 'Value'],
              },
            },
          ]}
          itemRenderer={Item}
        />
      </div>
      <SitemapNode data={sitemap} />
    </div>
  </WebAdminLayout>
);

export default WebAdminSitemap;
