import Creditcard from '@sats-group/icons/32/creditcard';
import Invoice from '@sats-group/icons/32/invoice';
import React, { useState } from 'react';

import Button from '@sats-group/ui-lib/react/button';
import HiddenInput from '@sats-group/ui-lib/react/hidden-input';
import Text from '@sats-group/ui-lib/react/text';

import Spinner from 'components/spinner/spinner';

import type { PaymentMethodCard as Props } from './payment-method-card.types';

const PaymentMethodCard: React.FC<Props> = ({
  deleteForm,
  description,
  form,
  id,
  metadata,
  method,
  state,
  status,
  statusIndicator,
  title,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    if (isLoading) {
      event.preventDefault();
    }
    setIsLoading(true);
  };

  return (
    <div
      className="payment-method-card"
      data-state={state}
      data-test-is-loading={isLoading}
      data-test-card-id={id}
    >
      <span className="payment-method-card__icon">
        {method === 'card' ? <Creditcard /> : <Invoice />}
      </span>
      <section className="payment-method-card__details">
        <Text
          className="payment-method-card__title"
          theme={Text.themes.emphasis}
        >
          {title}
        </Text>
        <div className="payment-method-card__status">
          <div
            className={`payment-method-card__status-symbol payment-method-card__status-symbol--${statusIndicator}`}
          ></div>
          <Text>{status}</Text>
        </div>
        {metadata.map(meta => (
          <Text key={meta}>{meta}</Text>
        ))}
        {description ? (
          <Text
            className="payment-method-card__description"
            size={Text.sizes.small}
          >
            {description}
          </Text>
        ) : null}
      </section>
      {form || deleteForm ? (
        <section className="payment-method-card__buttons">
          {isLoading ? <Spinner theme={Spinner.themes.overlay} /> : null}
          {form ? (
            <form
              className="payment-method-card__form"
              action={form.endpoint}
              method="POST"
              onSubmit={handleSubmit}
            >
              {form.hiddenInputs.map(input => (
                <HiddenInput
                  key={input.name}
                  name={input.name}
                  value={input.value}
                />
              ))}
              <HiddenInput name="cardId" value={id} />

              <Button type="submit" text={form.submitButtonText} />
            </form>
          ) : null}
          {deleteForm ? (
            <form
              className="payment-method-card__delete-form"
              action={deleteForm.endpoint}
              method="POST"
              onSubmit={handleSubmit}
            >
              {deleteForm.hiddenInputs.map(input => (
                <HiddenInput
                  key={input.name}
                  name={input.name}
                  value={input.value}
                />
              ))}
              <HiddenInput name="cardId" value={id} />
              <Button
                className="payment-method-card__delete-button"
                type="submit"
                variant={Button.variants.secondary}
                text={deleteForm.submitButtonText}
                data-test-delete-card
              />
            </form>
          ) : null}
        </section>
      ) : null}
    </div>
  );
};

export default PaymentMethodCard;
