import Banner from '@sats-group/ui-lib/react/banner';
import VisuallyHidden from '@sats-group/ui-lib/react/visually-hidden';
import React from 'react';

import ArrowLink from 'components/arrow-link/arrow-link';
import ContentContainer from 'components/content-container/content-container';
import Logo from 'components/logo/logo';

import type { SalesPosters as Props } from './sales-posters.types';

const SalesPosters: React.FC<Props> = ({ links, logo, messages, title }) => (
  <main className="sales-posters">
    <VisuallyHidden>
      <h1>{title}</h1>
    </VisuallyHidden>
    <ContentContainer>
      <div className="sales-posters__content">
        <header className="sales-posters__logo">
          <Logo {...logo} size={Logo.sizes.large} />
        </header>
        {messages.length ? (
          <div className="sales-posters__messages">
            {messages.map(entry => (
              <Banner {...entry} key={entry.text} />
            ))}
          </div>
        ) : null}
        {links.length ? (
          <ul className="sales-posters__links">
            {links.map(entry => (
              <li className="sales-posters__link" key={entry.href}>
                <ArrowLink {...entry} />
              </li>
            ))}
          </ul>
        ) : null}
      </div>
    </ContentContainer>
  </main>
);

export default SalesPosters;
