import React from 'react';

import Text from '@sats-group/ui-lib/react/text';

import ContentContainer from '../content-container/content-container';
import ElementInterpolator from '../element-interpolator/element-interpolator';
import Logo from '../logo/logo';

import type { MyPageFooter as Props } from './my-page-footer.types';

const MyPageFooter: React.FunctionComponent<Props> = ({ logo, notice }) => (
  <div className="my-page-footer">
    <ContentContainer>
      <div className="my-page-footer__content">
        <div className="my-page-footer__logo">
          <Logo {...logo} />
        </div>
        {notice ? (
          <div className="my-page-footer__notice">
            <Text size={Text.sizes.interface} theme={Text.themes.emphasis}>
              <ElementInterpolator
                template={notice.template}
                elements={{
                  cookieLink: (
                    <a href={notice.cookie.href} key={notice.cookie.href}>
                      {notice.cookie.text}
                    </a>
                  ),
                  privacyLink: (
                    <a href={notice.privacy.href} key={notice.privacy.href}>
                      {notice.privacy.text}
                    </a>
                  ),
                }}
              />
            </Text>
          </div>
        ) : null}
      </div>
    </ContentContainer>
  </div>
);

export default MyPageFooter;
