import React from 'react';

import Text from '@sats-group/ui-lib/react/text';

import WebAdminLayout from 'client/components/web-admin-layout/web-admin-layout';

import type { WebAdmin as Props } from './web-admin.types';

const WebAdmin: React.FunctionComponent<Props> = ({ gitLog, layout }) => (
  <WebAdminLayout {...layout}>
    <div className="web-admin">
      {gitLog.length ? (
        <React.Fragment>
          <Text
            elementName="h1"
            size={Text.sizes.headline2}
            theme={Text.themes.emphasis}
          >
            Most Recent Application Changes
          </Text>
          <div className="web-admin__git-log">
            {gitLog.map(
              ({
                date,
                emoji,
                hash,
                message,
                pr,
                prUrl,
                trackerId,
                trackerUrl,
              }) => (
                <div className="web-admin__git-log-entry" key={hash}>
                  <div className="web-admin__git-log-emoji">{emoji}</div>
                  <div className="web-admin__git-log-text">
                    <div>
                      {trackerId && trackerUrl ? (
                        <React.Fragment>
                          <a href={trackerUrl}>{trackerId}</a>{' '}
                        </React.Fragment>
                      ) : null}
                      {message}
                    </div>
                    <div>
                      {date}, <a href={prUrl}>{pr}</a>, {hash}
                    </div>
                  </div>
                </div>
              )
            )}
          </div>
        </React.Fragment>
      ) : null}
    </div>
  </WebAdminLayout>
);

export default WebAdmin;
