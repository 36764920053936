import React from 'react';

import Text from '@sats-group/ui-lib/react/text';

import ContentContainer from 'components/content-container/content-container';
import MissedClassesCard from 'components/missed-classes-card/missed-classes-card';
import MyPageLayout from 'components/my-page-layout/my-page-layout';

import type { BookingRestrictions as Props } from './booking-restrictions.types';

const BookingRestrictions: React.FunctionComponent<Props> = ({
  classes,
  myPageLayout,
  rules,
}) => (
  <MyPageLayout {...myPageLayout}>
    <ContentContainer>
      <div className="booking-restrictions">
        <section>
          <MissedClassesCard {...classes} />
        </section>
        {rules.list.length ? (
          <section>
            <Text
              elementName="h2"
              size={Text.sizes.headline3}
              theme={Text.themes.headline}
            >
              {rules.title}
            </Text>
            <ul className="booking-restrictions__rules">
              {rules.list.map(rule => (
                <Text
                  className="booking-restrictions__rule"
                  elementName="li"
                  key={rule}
                >
                  {rule}
                </Text>
              ))}
            </ul>
          </section>
        ) : null}
      </div>
    </ContentContainer>
  </MyPageLayout>
);

export default BookingRestrictions;
