import React from 'react';

import Text from '@sats-group/ui-lib/react/text';

import DynamicImage from '../dynamic-image/dynamic-image';

import type { GroupClasses as Props } from './group-classes.types';

const GroupClasses: React.FunctionComponent<Props> = ({ items, title }) =>
  items.length ? (
    <div className="group-classes">
      <Text
        elementName="h2"
        theme={Text.themes.emphasis}
        size={Text.sizes.headline2}
      >
        {title}
      </Text>
      <div className="group-classes__track">
        <div className="group-classes__items">
          {items.map(item => (
            <a className="group-classes__item" href={item.href} key={item.href}>
              <DynamicImage
                {...item.image}
                aspectRatio={DynamicImage.aspectRatios.sixteenNine}
                theme={DynamicImage.themes.rounded}
              />
              <Text theme={Text.themes.emphasis} size={Text.sizes.large}>
                {item.text}
              </Text>
            </a>
          ))}
        </div>
      </div>
    </div>
  ) : null;

export default GroupClasses;
