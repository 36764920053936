import SvgCreditcard from '@sats-group/icons/24/creditcard';
import Strength from '@sats-group/icons/24/strength';
import React from 'react';
import Text from '@sats-group/ui-lib/react/text';

import FlowTerminusLayout from 'components/flow-terminus-layout/flow-terminus-layout';
import type { CorporateCodeConfirmation as Props } from './corporate-code-confirmation.types';

const CorporateCodeConfirmation: React.FunctionComponent<Props> = ({
  data,
  layout,
  messages,
}) => (
  <FlowTerminusLayout {...layout}>
    <div className="corporate-code-confirmation">
      <div className="corporate-code-confirmation__section">
        {messages.map(({ text, title, icon }) => (
          <>
            {title ? (
              <Text
                elementName="h2"
                size={Text.sizes.large}
                theme={Text.themes.headline}
              >
                {title}
              </Text>
            ) : null}
            <div className="corporate-code-confirmation__message">
              {icon === 'Strength' ? <Strength /> : null}
              {icon === 'SvgCreditCard' ? <SvgCreditcard /> : null}
              <Text>{text}</Text>
            </div>
          </>
        ))}
      </div>
      {data.entries.length ? (
        <div className="corporate-code-confirmation__section">
          <Text
            elementName="h2"
            size={Text.sizes.large}
            theme={Text.themes.headline}
          >
            {data.title}
          </Text>
          <table className="corporate-code-confirmation__data">
            <tbody>
              {data.entries.map(([key, value]) => (
                <tr key={key}>
                  <Text elementName="th" theme={Text.themes.emphasis}>
                    {key}
                  </Text>
                  <Text elementName="td">{value}</Text>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : null}
    </div>
  </FlowTerminusLayout>
);

export default CorporateCodeConfirmation;
