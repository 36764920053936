import React, { useState } from 'react';

import Button from '@sats-group/ui-lib/react/button';
import useEscape from '@sats-group/ui-lib/react/hooks/use-escape';
import Modal from '@sats-group/ui-lib/react/modal';

import MembershipCard from 'components/membership-card/membership-card';

import type { ProductOptionList as Props } from './product-option-list.types';

const ProductOptionList: React.FunctionComponent<Props> = ({
  modal,
  moreOptionsLabel,
  onClick,
  optionKey,
  options,
  selected,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEscape(() => setIsModalOpen(false));

  if (!options || !options.length) {
    return null;
  }

  return (
    <div className="personal-trainer-select-session-page__section-actions">
      <Button
        text={moreOptionsLabel}
        size={Button.sizes.small}
        variant={Button.variants.secondary}
        onClick={() => setIsModalOpen(true)}
        data-test-product-options
      />
      {isModalOpen ? (
        <Modal {...modal} onClose={() => setIsModalOpen(false)}>
          {onClick
            ? options.map(option => (
                <button
                  className="personal-trainer-select-session-page__option"
                  key={option.id}
                  onClick={() => {
                    onClick(optionKey, option.id);
                    setIsModalOpen(false);
                  }}
                  data-test-product-option
                >
                  <MembershipCard
                    {...option}
                    isSelected={option.id === selected}
                  />
                </button>
              ))
            : undefined}
        </Modal>
      ) : null}
    </div>
  );
};

export default ProductOptionList;
