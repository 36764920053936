import React, { useEffect, useState } from 'react';

import LinkButton from '@sats-group/ui-lib/react/link-button';
import Modal from '@sats-group/ui-lib/react/modal';
import Text from '@sats-group/ui-lib/react/text';

import { fromQueryString } from 'shared/from-query-string';
import { replaceQueryParameters } from 'shared/replace-query-parameters';

import type { CheckoutSessionModal as Props } from './checkout-session-modal.types';

const CheckoutSessionModal: React.FunctionComponent<Props> = ({
  buttonText,
  modal,
  text,
}) => {
  const [href, setHref] = useState('');

  useEffect(() => {
    setHref(
      replaceQueryParameters(
        window.location.pathname,
        Object.assign(fromQueryString(window.location.search), {
          'registration-error': undefined,
          sessionId: undefined,
        })
      )
    );
  }, []);

  return (
    <Modal {...modal}>
      <div className="checkout-session-modal">
        <Text>{text}</Text>
        <LinkButton
          className="checkout-session-modal__button"
          variant={LinkButton.variants.secondary}
          href={href}
          text={buttonText}
        />
      </div>
    </Modal>
  );
};

export default CheckoutSessionModal;
