import Check from '@sats-group/icons/16/check';
import React from 'react';

import Text from '@sats-group/ui-lib/react/text';

import { mod } from 'client/helpers/add-bem-modifiers';
import useUrlState from 'hooks/use-url-state';

import CheckoutProgressStepContent from './checkout-progress-step-content';
import type { CheckoutProgressStep as Props } from './checkout-progress-step.types';

const CheckoutProgressStep: React.FunctionComponent<Props> = ({
  isComplete,
  isCurrent,
  link,
  number,
  title,
}) => {
  const [query] = useUrlState();

  return (
    <li key={title}>
      <CheckoutProgressStepContent
        className={mod('checkout-progress__step', {
          complete: isComplete,
          current: isCurrent,
        })}
        isComplete={Boolean(isComplete)}
        link={link}
        query={query}
      >
        {/* NOTE: Hidden for screen readers since the numbers are communicated in the containing <ol> */}
        <div className="checkout-progress__step-indicator">
          {isComplete ? (
            <Check />
          ) : (
            <Text
              className="checkout-progress__step-number"
              aria-hidden="true"
              theme={Text.themes.emphasis}
              size={Text.sizes.small}
            >
              {number}
            </Text>
          )}
        </div>
        <div className="checkout-progress__step-content">
          <Text
            className="checkout-progress__step-title"
            size={Text.sizes.small}
          >
            {title}
          </Text>
        </div>
      </CheckoutProgressStepContent>
    </li>
  );
};

export default CheckoutProgressStep;
