import LocationIcon from '@sats-group/icons/24/location';
import ImagesIcon from '@sats-group/icons/32/images';
import React from 'react';

import Text from '@sats-group/ui-lib/react/text';

import BackgroundImage from 'components/background-image/background-image';
import List from 'components/list/list';

import type { ClubCard as Props } from './club-card.types';

const ClubCard: React.FunctionComponent<Props> = ({
  address,
  bullets,
  href,
  id,
  image,
  title,
}) => (
  <a className="club-card" data-test-club={id} href={href}>
    <div className="club-card__image">
      {image ? <BackgroundImage {...image} /> : null}
      <ImagesIcon />
    </div>
    <div className="club-card__content">
      <Text
        className="club-card__title"
        elementName="h2"
        size={Text.sizes.headline3}
      >
        {title}
      </Text>

      {address ? (
        <div className="club-card__address">
          <div className="club-card__address-icon">
            <LocationIcon />
          </div>
          {address}
        </div>
      ) : null}

      {bullets.length ? (
        <div className="club-card__bullets">
          <Text size={Text.sizes.small}>
            <List theme={List.themes.checkmarks}>{bullets}</List>
          </Text>
        </div>
      ) : null}
    </div>
  </a>
);

export default ClubCard;
