import React, { useEffect, useState } from 'react';
import Collapse from 'react-tiny-collapse';

import Button from '@sats-group/ui-lib/react/button';
import Message from '@sats-group/ui-lib/react/message';
import Text from '@sats-group/ui-lib/react/text';

import type { Body } from 'shared/person-trainer-select-session.types';
import { replaceQueryParameters } from 'shared/replace-query-parameters';

import DynamicImage from 'client/components/dynamic-image/dynamic-image';
import { get } from 'client/helpers/api-helper';
import { publish } from 'client/helpers/messages';
import useUrlState from 'client/hooks/use-url-state';
import Accordion from 'components/accordion/accordion';
import ContentContainer from 'components/content-container/content-container';
import ElementInterpolator from 'components/element-interpolator/element-interpolator';
import MembershipCard from 'components/membership-card/membership-card';
import type { MembershipCard as MembershipCardType } from 'components/membership-card/membership-card.types';
import PersonalTrainerSessionShopLayout from 'components/personal-trainer-session-shop-layout/personal-trainer-session-shop-layout';
import Spinner from 'components/spinner/spinner';

import type {
  ExtendedMembershipCard,
  PersonalTrainerSelectSessionPage as Props,
} from './personal-trainer-select-session-page.types';
import ProductOptionList from './product-option-list';
import Summary from './summary';
import type { DynamicSummary } from './summary.types';

const PersonalTrainerSelectSessionPage: React.FunctionComponent<Props> = ({
  endpoint,
  initiallySelectedOptions = {},
  layout,
  levels,
  levelsTitle,
  lessNumberOfSessionsLabel,
  logInPrompt,
  messages,
  moreNumberOfSessionsLabel,
  numberOfSessions,
  numberOfSessionsKey,
  numberOfSessionsTitle,
  optionsDictionary,
  personalTrainer,
  personalTrainerDescription,
  ptFilterLink,
  selectLevelDescription,
  selectedSessionType,
  sessionTypes,
  sessionTypeTitle,
  dynamicSummary,
  staticSummary,
}) => {
  const [, setQuery] = useUrlState();

  const [numberOfSessionsIsExpanded, setNumberOfSessionsIsExpanded] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currentlySelectedSessionType, setCurrentlySelectedSessionType] =
    useState<MembershipCardType | undefined>(selectedSessionType);
  const [summary, setSummary] = useState<DynamicSummary | undefined>(
    dynamicSummary
  );
  const [dynamicLogInPrompt, setDynamicLogInPrompt] = useState(logInPrompt);

  const [selectedOptions, setSelectedOptions] = useState<
    Record<string, string | undefined>
  >(initiallySelectedOptions);
  const [currentNumberOfSessions, setCurrentNumberOfSessions] =
    useState<ExtendedMembershipCard[]>(numberOfSessions);

  const updateOptions = (key: string, value: string) => {
    setIsLoading(true);
    // @ts-expect-error
    get<Body>(
      replaceQueryParameters(endpoint, { ...selectedOptions, [key]: value })
    )
      .then(response => {
        setCurrentNumberOfSessions(response.numberOfSessions);
        setSelectedOptions(response.products);
        setSummary(response.summary);
        setDynamicLogInPrompt(prev => {
          if (prev) {
            prev.link.href = response.logInUrl;
          }

          return prev;
        });
      })
      .catch(error => {
        if (error && error.message) {
          publish({ text: error.message, theme: 'error' });
        }
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    const selectedNumberOfSessions = numberOfSessions.find(
      amount => amount.id === selectedOptions.numberOfSessions
    );
    const selectedPtLevel = levels.list?.find(
      level => level.id === selectedOptions.ptLevel
    );
    const selectedSessionType = sessionTypes?.options?.find(
      session => session.id === selectedOptions.sessionType
    );

    setQuery({
      sessionType: selectedSessionType
        ? optionsDictionary.sessionType[selectedSessionType.id]
        : undefined,
      ptLevel: selectedPtLevel
        ? optionsDictionary.levels[selectedPtLevel.id]
        : undefined,
      numberOfSessions: selectedNumberOfSessions
        ? optionsDictionary.numberOfSessions[selectedNumberOfSessions.id]
        : undefined,
    });

    setCurrentlySelectedSessionType(selectedSessionType);
  }, [selectedOptions]);

  const highlightedNumberOfSessions = currentNumberOfSessions.filter(
    session => session.highlighted
  );

  const restNumberOfSessions = currentNumberOfSessions.filter(
    session => !session.highlighted
  );

  return (
    <PersonalTrainerSessionShopLayout {...layout}>
      <ContentContainer>
        <div
          className="personal-trainer-select-session-page"
          data-test-is-loading={isLoading}
        >
          {isLoading ? <Spinner theme={Spinner.themes.overlaySticky} /> : null}

          {messages.length || dynamicLogInPrompt ? (
            <div className="personal-trainer-select-session-page__messages">
              {messages.map(message => (
                <Message key={message.text} {...message} />
              ))}
              {dynamicLogInPrompt ? <Message {...dynamicLogInPrompt} /> : null}
            </div>
          ) : null}

          <div className="personal-trainer-select-session-page__content">
            <div className="personal-trainer-select-session-page__options">
              <Text
                elementName="h2"
                size={Text.sizes.headline2}
                className="personal-trainer-select-session-page__section-title"
              >
                {sessionTypeTitle}
              </Text>
              <div className="personal-trainer-select-session-page__option-wrapper">
                {currentlySelectedSessionType ? (
                  <div className="personal-trainer-select-session-page__option">
                    <MembershipCard
                      {...currentlySelectedSessionType}
                      hideCheckmark={true}
                    />
                  </div>
                ) : null}
                {sessionTypes ? (
                  <ProductOptionList
                    {...sessionTypes}
                    onClick={updateOptions}
                    selected={selectedOptions[sessionTypes.optionKey]}
                  />
                ) : null}
              </div>
              {levels ? (
                <div className="personal-trainer-select-session-page__section">
                  <Text
                    elementName="h2"
                    size={Text.sizes.headline2}
                    className="personal-trainer-select-session-page__section-title"
                  >
                    {levelsTitle}
                  </Text>
                  {personalTrainer ? (
                    <React.Fragment>
                      <div className="personal-trainer-select-session-page__personal-trainer">
                        <div className="personal-trainer-select-session-page__personal-trainer-content-top">
                          <Text elementName="span" size={Text.sizes.basic}>
                            <ElementInterpolator
                              template={personalTrainerDescription}
                              elements={{
                                name: (
                                  <Text
                                    elementName="span"
                                    size={Text.sizes.basic}
                                    theme={Text.themes.emphasis}
                                    key={personalTrainer.name}
                                  >
                                    {personalTrainer.name}
                                  </Text>
                                ),
                                level: (
                                  <Text
                                    elementName="span"
                                    size={Text.sizes.basic}
                                    theme={Text.themes.emphasis}
                                    key={personalTrainer.level}
                                  >
                                    {personalTrainer.level}
                                  </Text>
                                ),
                              }}
                            />
                          </Text>
                          {personalTrainer.image ? (
                            <div className="personal-trainer-select-session-page__personal-trainer-image">
                              <DynamicImage
                                theme={DynamicImage.themes.round}
                                aspectRatio={DynamicImage.aspectRatios.square}
                                {...personalTrainer.image}
                              />
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </React.Fragment>
                  ) : (
                    <div className="personal-trainer-select-session-page__section-description">
                      <Text
                        elementName="span"
                        size={Text.sizes.small}
                        theme={Text.themes.emphasis}
                      >
                        {selectLevelDescription}
                      </Text>{' '}
                      <Text
                        elementName="a"
                        href={ptFilterLink.href}
                        size={Text.sizes.small}
                        theme={Text.themes.emphasis}
                      >
                        {ptFilterLink.text}
                      </Text>
                    </div>
                  )}
                  <div className="personal-trainer-select-session-page__levels">
                    <Accordion
                      {...levels}
                      onClick={updateOptions}
                      selectedId={selectedOptions[levels.id]}
                    />
                  </div>
                </div>
              ) : null}
              {currentNumberOfSessions.length ? (
                <div className="personal-trainer-select-session-page__section">
                  <Text
                    elementName="h2"
                    size={Text.sizes.headline2}
                    className="personal-trainer-select-session-page__section-title"
                  >
                    {numberOfSessionsTitle}
                  </Text>
                  {highlightedNumberOfSessions.map(session => (
                    <button
                      className="personal-trainer-select-session-page__option"
                      data-test-expand-session-type
                      key={session.id}
                      onClick={() => {
                        updateOptions(numberOfSessionsKey, session.id);
                      }}
                    >
                      <MembershipCard
                        {...session}
                        isSelected={
                          selectedOptions[numberOfSessionsKey] === session.id
                        }
                      />
                    </button>
                  ))}
                  {restNumberOfSessions.length ? (
                    <React.Fragment>
                      <Collapse isOpen={numberOfSessionsIsExpanded}>
                        <div>
                          {restNumberOfSessions.map(session => (
                            <button
                              className="personal-trainer-select-session-page__option"
                              data-test-number-of-sessions={session.id}
                              key={session.id}
                              onClick={() => {
                                updateOptions(numberOfSessionsKey, session.id);
                              }}
                            >
                              <MembershipCard
                                {...session}
                                isSelected={
                                  selectedOptions[numberOfSessionsKey] ===
                                  session.id
                                }
                              />
                            </button>
                          ))}
                        </div>
                      </Collapse>
                      <div className="personal-trainer-select-session-page__section-actions">
                        <Button
                          data-test-expand-number-of-sessions
                          text={
                            numberOfSessionsIsExpanded
                              ? lessNumberOfSessionsLabel
                              : moreNumberOfSessionsLabel
                          }
                          size={Button.sizes.small}
                          variant={Button.variants.secondary}
                          onClick={() =>
                            setNumberOfSessionsIsExpanded(
                              currentState => !currentState
                            )
                          }
                        />
                      </div>
                    </React.Fragment>
                  ) : null}
                </div>
              ) : null}
            </div>
            <div className="personal-trainer-select-session-page__summary-wrapper">
              <Summary {...summary} {...staticSummary} />
            </div>
          </div>
        </div>
      </ContentContainer>
    </PersonalTrainerSessionShopLayout>
  );
};

export default PersonalTrainerSelectSessionPage;
