import React, { useCallback, useState } from 'react';

import Button from '@sats-group/ui-lib/react/button';
import LinkButton from '@sats-group/ui-lib/react/link-button';

import ContentContainer from 'components/content-container/content-container';
import DynamicImage from 'components/dynamic-image/dynamic-image';
import EmbedYoutube from 'components/embed-youtube/embed-youtube';
import FullscreenModal from 'components/fullscreen-modal/fullscreen-modal';
import Slideshow from 'components/slideshow/slideshow';

import type { ModalSlideshow as Props } from './modal-slideshow.types';

const ModalSlideshow: React.FunctionComponent<Props> = ({
  closeButtonText,
  link,
  media = [],
  playButtonText,
  slideshow,
  slideshowButtonText,
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [slideIndex, setSlideIndex] = useState(0);

  const hideModal = useCallback(() => setModalVisible(false), []);

  const showModal = useCallback(
    (index: number) => () => {
      setSlideIndex(index);
      setModalVisible(true);
    },
    []
  );

  return (
    <div className="modal-slideshow">
      {modalVisible ? (
        <FullscreenModal closeButtonText={closeButtonText} hide={hideModal}>
          <div className="modal-slideshow__slideshow">
            <Slideshow
              initialSlide={slideIndex}
              labels={media.map(({ image }) => image.alt)}
              thumbnails={media.map(({ image, key }) => (
                <DynamicImage key={key} {...image} />
              ))}
              {...slideshow}
            >
              {media.map(({ image, key, video }) =>
                video ? (
                  <EmbedYoutube key={key} {...video} />
                ) : (
                  <DynamicImage key={key} {...image} />
                )
              )}
            </Slideshow>
          </div>
        </FullscreenModal>
      ) : null}

      <ContentContainer raw theme={ContentContainer.themes.wide}>
        <div className="modal-slideshow__header">
          <div className="modal-slideshow__images-container">
            <div className="modal-slideshow__header-buttons">
              {media.length > 1 ? (
                <Button
                  variant={Button.variants.primary}
                  onClick={showModal(0)}
                  text={slideshowButtonText}
                />
              ) : null}
              {link ? (
                <LinkButton {...link} variant={LinkButton.variants.cta} />
              ) : null}
            </div>
            {media.length > 1 ? (
              <React.Fragment>
                <div className="modal-slideshow__images">
                  {media.slice(0, 3).map(({ image, key, video }, index) => (
                    <div
                      className="modal-slideshow__image"
                      key={key}
                      onClick={showModal(index)}
                    >
                      {video ? (
                        <Button
                          className="modal-slideshow__video-button"
                          onClick={showModal(index)}
                          text={playButtonText}
                        />
                      ) : null}
                      <DynamicImage {...image} />
                    </div>
                  ))}
                </div>
              </React.Fragment>
            ) : (
              media.map(({ image, key }) => (
                <DynamicImage {...image} key={key} />
              ))
            )}
          </div>
        </div>
      </ContentContainer>
    </div>
  );
};

export default ModalSlideshow;
