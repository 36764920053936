import React from 'react';

import Text from '@sats-group/ui-lib/react/text';

import ActivityMetadata from 'components/activity-metadata/activity-metadata';
import Breadcrumbs from 'components/breadcrumbs/breadcrumbs';
import Carousel from 'components/carousel/carousel';
import ContentContainer from 'components/content-container/content-container';
import FavoriteButton from 'components/favorite-button/favorite-button';
import Layout from 'components/layout/layout';
import LinkBox from 'components/link-box/link-box';
import PageHeader from 'components/page-header/page-header';
import RichText from 'components/rich-text/rich-text';
import SidebarLayout from 'components/sidebar-layout/sidebar-layout';
import FavoriteContextProvider from 'contexts/favorite-context-provider';

import type { ExercisePage as Props } from './exercise-page.types';

const ExercisePage: React.FunctionComponent<Props> = ({
  activityMetadata,
  breadcrumbs,
  description,
  favorite,
  favorites,
  header,
  layout,
  related,
}) => (
  <Layout {...layout}>
    <Breadcrumbs {...breadcrumbs} />
    <FavoriteContextProvider {...favorites}>
      <SidebarLayout>
        <SidebarLayout.Top>
          <PageHeader {...header} />
        </SidebarLayout.Top>

        <SidebarLayout.Sidebar>
          {favorite ? (
            <div className="exercise-page__favorite">
              <FavoriteButton {...favorite} />
            </div>
          ) : null}
          <ActivityMetadata {...activityMetadata} />
        </SidebarLayout.Sidebar>

        <SidebarLayout.Content>
          <div className="exercise-page__description">
            {description.map(({ title, text }, i) => (
              <div key={title || i}>
                {title ? (
                  <Text
                    className="exercise-page__description-title"
                    elementName="h2"
                    size={Text.sizes.headline2}
                    theme={Text.themes.emphasis}
                  >
                    {title}
                  </Text>
                ) : null}
                <RichText className="exercise-page__text" {...text} />
              </div>
            ))}
          </div>
        </SidebarLayout.Content>
      </SidebarLayout>

      {related ? (
        <ContentContainer>
          <div className="exercise-page__related">
            <Carousel {...related} entryRenderer={LinkBox} />
          </div>
        </ContentContainer>
      ) : null}
    </FavoriteContextProvider>
  </Layout>
);

export default ExercisePage;
