import Check from '@sats-group/icons/16/check';
import Close from '@sats-group/icons/16/close';
import React, { useRef, useState } from 'react';

import Button from '@sats-group/ui-lib/react/button';
import HiddenInput from '@sats-group/ui-lib/react/hidden-input';
import LinkButton from '@sats-group/ui-lib/react/link-button';
import Select from '@sats-group/ui-lib/react/select';
import Text from '@sats-group/ui-lib/react/text';

import CleanLink from 'client/components/clean-link/clean-link';
import ContentContainer from 'components/content-container/content-container';
import DateInput from 'components/date-input/date-input';
import InputButton from 'components/input-button/input-button';
import MyPageLayout from 'components/my-page-layout/my-page-layout';
import RadioCard from 'components/radio-card/radio-card';
import RichText from 'components/rich-text/rich-text';
import Spinner from 'components/spinner/spinner';

import type { FreezeSelectLengthPage as Props } from './freeze-select-length-page.types';

const FreezeSelectLengthPage: React.FunctionComponent<Props> = ({
  alternatives,
  bindingPeriodText,
  ctaText,
  dateInput,
  dateSelect,
  endpoint,
  freezeMembershipButton,
  hiddenInputs,
  myPageLayout,
  preselectedOption,
  previousPageButton,
  radioCardChildren,
  removeFile,
  requiresDocumentation,
}) => {
  const [uploadedFile, setUploadedFile] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);

  const fileInput = useRef<HTMLInputElement>(null);

  const handleUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target.files[0]?.name;
      if (file) {
        setUploadedFile(file);
      }
    }
  };

  const handleDelete = () => {
    if (fileInput.current) {
      fileInput.current.value = '';
    }
    setUploadedFile('');
  };

  return (
    <MyPageLayout {...myPageLayout}>
      <ContentContainer>
        <form
          action={endpoint}
          className="freeze-select-length-page"
          encType={requiresDocumentation ? 'multipart/form-data' : undefined}
          method="POST"
          onSubmit={() => setIsLoading(true)}
        >
          {isLoading ? <Spinner theme={Spinner.themes.overlaySticky} /> : null}

          {hiddenInputs.map(input => (
            <HiddenInput key={input.name} {...input} />
          ))}

          {dateInput || dateSelect ? (
            <div className="freeze-select-length-page__date">
              {dateInput ? (
                <DateInput {...dateInput} required />
              ) : dateSelect ? (
                <Select {...dateSelect} required />
              ) : null}
            </div>
          ) : null}

          <Text
            elementName="p"
            size={Text.sizes.large}
            theme={Text.themes.emphasis}
          >
            {ctaText}
          </Text>

          <Text elementName="p" size={Text.sizes.basic}>
            {bindingPeriodText}
          </Text>

          <RadioCard {...preselectedOption} required>
            {radioCardChildren ? (
              <div className="freeze-select-length-page__radio-card-container">
                {radioCardChildren.monthSelect ? (
                  <Select {...radioCardChildren.monthSelect} required />
                ) : null}

                <Text className="freeze-select-length-page__radio-card-text">
                  {radioCardChildren.addAttachment}
                </Text>

                <div className="freeze-select-length-page__upload-container">
                  <InputButton
                    type="file"
                    required
                    onChange={handleUpload}
                    ref={fileInput}
                    {...radioCardChildren.uploadFile}
                    data-test-upload-button
                  >
                    {radioCardChildren.uploadText}
                  </InputButton>

                  {uploadedFile ? (
                    <div className="freeze-select-length-page__file-container">
                      <Text data-test-uploaded-file>{uploadedFile}</Text>
                      {removeFile ? (
                        <Button
                          {...removeFile}
                          leadingIcon={<Close />}
                          onClick={handleDelete}
                          size={Button.sizes.small}
                          variant={Button.variants.secondary}
                        />
                      ) : null}
                    </div>
                  ) : null}
                </div>

                <RichText
                  className="freeze-select-length-page__radio-card-text"
                  {...radioCardChildren.documentationDetails}
                />

                <div className="freeze-select-length-page__radio-card-links-container">
                  <Text>{radioCardChildren.noDocumentation}</Text>
                  <CleanLink
                    theme={CleanLink.themes.orange}
                    {...radioCardChildren.toFreezeProductLink}
                  />
                </div>

                <div className="freeze-select-length-page__radio-card-links-container">
                  <Text>{radioCardChildren.needHelp}</Text>
                  <CleanLink
                    theme={CleanLink.themes.orange}
                    {...radioCardChildren.contactMemberCare}
                  />
                </div>
              </div>
            ) : null}
          </RadioCard>

          {alternatives ? (
            <div className="freeze-select-length-page__alternatives">
              {alternatives.map(alternative => (
                <label
                  key={alternative.title}
                  htmlFor={alternative.name}
                  className="freeze-select-length-page__alternative"
                >
                  <input
                    className="freeze-select-length-page__radio-input"
                    type="radio"
                    id={alternative.name}
                    name={alternative.name}
                    value={alternative.value}
                  />
                  <div className="freeze-select-length-page__option-check">
                    <Check />
                  </div>

                  <Text size={Text.sizes.button} theme={Text.themes.emphasis}>
                    {alternative.title}
                  </Text>
                </label>
              ))}
            </div>
          ) : null}

          <div className="freeze-select-length-page__actions">
            <LinkButton
              variant={LinkButton.variants.secondary}
              {...previousPageButton}
            />
            <Button
              type="submit"
              data-test-submit
              variant="complete"
              {...freezeMembershipButton}
            />
          </div>
        </form>
      </ContentContainer>
    </MyPageLayout>
  );
};
export default FreezeSelectLengthPage;
