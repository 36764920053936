import React from 'react';

import LinkButton from '@sats-group/ui-lib/react/link-button';
import Text from '@sats-group/ui-lib/react/text';

import AccessLevelCard from 'components/access-level-card/access-level-card';
import ContentContainer from 'components/content-container/content-container';
import List from 'components/list/list';
import MyPageLayout from 'components/my-page-layout/my-page-layout';

import type { CancellationAlternativesPage as Props } from './cancellation-alternatives-page.types';

const CancellationAlternativesPage: React.FunctionComponent<Props> = ({
  alternatives,
  alternativeOptionsText,
  myPageLayout,
  nextPage,
  prevPage,
}) => (
  <MyPageLayout {...myPageLayout}>
    <div className="cancellation-alternatives-page">
      <ContentContainer>
        <Text
          className="cancellation-alternatives-page__text"
          size={Text.sizes.basic}
        >
          {alternativeOptionsText}
        </Text>
        <div className="cancellation-alternatives-page__list">
          <List theme={List.themes.gridSmall}>
            {alternatives.map(alternative => (
              <AccessLevelCard key={alternative.title} {...alternative} />
            ))}
          </List>
        </div>
        <div className="cancellation-alternatives-page__actions">
          <LinkButton variant={LinkButton.variants.secondary} {...prevPage} />
          <LinkButton {...nextPage} data-test-next-page />
        </div>
      </ContentContainer>
    </div>
  </MyPageLayout>
);

export default CancellationAlternativesPage;
