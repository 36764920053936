import ChevronDown from '@sats-group/icons/18/arrow-down';
import cn from 'classnames';
import React, { useRef } from 'react';
import Collapse from 'react-tiny-collapse';

import useEscape from '@sats-group/ui-lib/react/hooks/use-escape';
import useClickOutside from '@sats-group/ui-lib/react/hooks/use-click-outside';
import useToggle from '@sats-group/ui-lib/react/hooks/use-toggle';
import Text from '@sats-group/ui-lib/react/text';

import ContentContainer from 'components/content-container/content-container';

import type { MenuDropdown as Props } from './menu.types';

const MenuDropdown: React.FunctionComponent<Props> = ({ id, items, title }) => {
  const [dropdownOpen, dropdownToggle, , close] = useToggle(false);
  const dropdown = useRef(null);

  useClickOutside(dropdown, close);
  useEscape(close);

  return (
    <div className="menu__dropdown-wrapper" ref={dropdown}>
      <button
        aria-controls={id}
        aria-expanded={dropdownOpen}
        className={cn('menu__dropdown-trigger', {
          'menu__dropdown-trigger--active': dropdownOpen,
        })}
        onClick={dropdownToggle}
      >
        <Text elementName="span" size={Text.sizes.small}>
          {title}
        </Text>

        <div
          className={cn('menu__icon', {
            'menu__icon--flipped': dropdownOpen,
          })}
        >
          <ChevronDown />
        </div>
      </button>
      <div className="menu__dropdown">
        <Collapse componentProps={{ id }} isOpen={dropdownOpen}>
          <ContentContainer theme={ContentContainer.themes.wide}>
            <ul
              className={cn('menu__dropdown-groups', {
                'menu__dropdown-groups--wide': items.length > 3,
              })}
            >
              {items.map(({ key, links, title }) => (
                <li className="menu__dropdown-group" key={key}>
                  {title ? (
                    <Text className="menu__dropdown-group-title">{title}</Text>
                  ) : null}
                  <div className="menu__dropdown-group-links">
                    {links.map(link => (
                      <div key={link.text}>
                        <a className="menu__dropdown-group-link" {...link}>
                          <Text elementName="span" size={Text.sizes.small}>
                            {link.text}
                          </Text>
                        </a>
                      </div>
                    ))}
                  </div>
                </li>
              ))}
            </ul>
          </ContentContainer>
        </Collapse>
      </div>
    </div>
  );
};

export default MenuDropdown;
