import React from 'react';

import Text from '@sats-group/ui-lib/react/text';

import type { InputButton as Props } from './input-button.types';

const InputButton = React.forwardRef<
  HTMLInputElement,
  React.PropsWithChildren<Props>
>(({ children, id, name, type = 'checkbox', required, ...props }, ref) => (
  <div className="input-button">
    <input
      {...props}
      id={id}
      name={name}
      type={type}
      required={required}
      ref={ref}
    />
    <div className="input-button__label">
      <Text
        elementName="label"
        htmlFor={id}
        size={Text.sizes.small}
        theme={Text.themes.normal}
      >
        {children}
      </Text>
      {required ? <span className="input-button__asterisk">*</span> : null}
    </div>
  </div>
));

InputButton.displayName = 'InputButton';

export default InputButton;
