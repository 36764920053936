import SvgPause from '@sats-group/icons/24/pause';
import SvgPlay from '@sats-group/icons/24/play';
import cn from 'classnames';
import React, { useMemo, useRef, useState } from 'react';

import Button from '@sats-group/ui-lib/react/button';
import LinkButton from '@sats-group/ui-lib/react/link-button';
import Text from '@sats-group/ui-lib/react/text';

import { MediaType } from 'shared/media-type-helper';

import BackgroundImage from '../background-image/background-image';
import BackgroundVideo, {
  type BackgroundVideoRef,
} from '../background-video/background-video';
import ContentContainer from '../content-container/content-container';
import Tagline from '../tagline/tagline';

import { HeroVariant, type Hero as Props } from './hero.types';

const Hero: React.FunctionComponent<Props> = ({
  media,
  variant = HeroVariant.Default,
  description,
  disclaimer,
  links,
  pause,
  play,
  tagline,
  title,
}) => {
  const videoRef = useRef<BackgroundVideoRef>(null);
  const [isPlaying, setIsPlaying] = useState<boolean>();

  const handlePause = () => setIsPlaying(false);
  const handleTimeUpdate = () => setIsPlaying(true);

  const pauseVideo = () => {
    if (videoRef.current) {
      videoRef.current.pause();
    }
  };

  const playVideo = () => {
    if (videoRef.current) {
      try {
        videoRef.current.play();
      } catch {
        // Noop
      }
    }
  };

  const hasVideo = media && media.type === MediaType.Video;

  const videoTrigger = useMemo(() => {
    if (!hasVideo) {
      return null;
    }

    if (isPlaying) {
      return (
        <Button
          ariaLabel={pause}
          leadingIcon={<SvgPause />}
          onClick={pauseVideo}
          variant={Button.variants.secondaryWhite}
        />
      );
    }

    return (
      <Button
        ariaLabel={play}
        leadingIcon={<SvgPlay />}
        onClick={playVideo}
        variant={Button.variants.secondaryWhite}
      />
    );
  }, [hasVideo, isPlaying]);

  return (
    <div className={cn('hero', `hero--${variant}`)}>
      <ContentContainer theme={ContentContainer.themes.wide}>
        <div className={cn('hero__inner', `hero__inner--${variant}`)}>
          <div
            className={cn(
              'hero__media-wrapper',
              `hero__media-wrapper--${variant}`
            )}
          >
            <div className="hero__media">
              {media.type === MediaType.Image ? (
                <BackgroundImage
                  {...media.props}
                  focusArea={BackgroundImage.focusAreas.Face}
                />
              ) : media.type === MediaType.Video ? (
                <React.Fragment>
                  <BackgroundVideo
                    {...media.props}
                    onPause={handlePause}
                    onTimeUpdate={handleTimeUpdate}
                    ref={videoRef}
                  />
                  <div className="hero__video-trigger">{videoTrigger}</div>
                </React.Fragment>
              ) : null}
            </div>
          </div>
          <div
            className={cn(
              'hero__content-wrapper',
              `hero__content-wrapper--${variant}`
            )}
          >
            <ContentContainer theme={ContentContainer.themes.wide}>
              <div className={cn('hero__content', `hero__content--${variant}`)}>
                <div className="hero__content-part">
                  {tagline ? (
                    <Tagline
                      {...tagline}
                      onDark={variant !== HeroVariant.Clean}
                    />
                  ) : null}
                  <div>
                    <Text
                      elementName="h1"
                      size={Text.sizes.headline1}
                      theme={
                        variant === HeroVariant.SplitInfo
                          ? Text.themes.emphasis
                          : Text.themes.headline
                      }
                      italic={variant === HeroVariant.SplitInfo ? false : true}
                    >
                      {title}
                    </Text>
                  </div>
                  {description ? (
                    <Text
                      elementName="p"
                      size={Text.sizes.large}
                      theme={Text.themes.normal}
                    >
                      {description}
                    </Text>
                  ) : null}
                </div>
                <div className="hero__content-part">
                  {links.length || videoTrigger ? (
                    <div className="hero__actions">
                      {links.map((link, index) => (
                        <div className="hero__action" key={link.href}>
                          <LinkButton
                            {...link}
                            size={LinkButton.sizes.large}
                            variant={
                              index
                                ? variant === HeroVariant.Clean
                                  ? LinkButton.variants.ctaSecondary
                                  : LinkButton.variants.ctaSecondaryWhite
                                : LinkButton.variants.cta
                            }
                          />
                        </div>
                      ))}
                      {videoTrigger ? (
                        <div className="hero__action hero__action--trigger">
                          {videoTrigger}
                        </div>
                      ) : null}
                    </div>
                  ) : null}
                  {disclaimer ? (
                    <Text elementName="p" size={Text.sizes.interface}>
                      {disclaimer}
                    </Text>
                  ) : null}
                </div>
              </div>
            </ContentContainer>
          </div>
        </div>
      </ContentContainer>
    </div>
  );
};

export default Hero;
