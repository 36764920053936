import ChevronUp from '@sats-group/icons/24/arrow-up';
import React, { useState } from 'react';
import Collapse from 'react-tiny-collapse';

import useToggle from '@sats-group/ui-lib/react/hooks/use-toggle';
import LinkButton from '@sats-group/ui-lib/react/link-button';
import Text from '@sats-group/ui-lib/react/text';

import CleanLink from 'client/components/clean-link/clean-link';
import { mod } from 'client/helpers/add-bem-modifiers';
import CheckoutSummaryModal from 'components/checkout-summary-modal/checkout-summary-modal';
import List from 'components/list/list';
import PreCorporatePrice from 'components/pre-corporate-price/pre-corporate-price';
import Price from '../price/price';
import Spinner from 'components/spinner/spinner';

import type { CheckoutSummaryContent as Props } from './checkout-summary-content.types';

const subTitleProps = {
  className: 'checkout-summary__sub-title',
  elementName: 'h3',
  size: Text.sizes.small,
  theme: Text.themes.normal,
};

const CheckoutSummaryContent: React.FunctionComponent<Props> = ({
  bannerButtonText,
  bannerTitle,
  bannerText,
  bindingPrice,
  bindingPriceLabel,
  button,
  choiceGroups = [],
  isLoading,
  isMaximumPrice,
  maximumPriceModal,
  additionalInformation = [],
  preCorporatePrice,
  price,
  priceTitle,
  reducedPriceDisclaimer,
  title,
}) => {
  const [maximumPriceModalVisible, setMaximumPriceModalVisible] =
    useState(false);
  const [isOpen, toggle] = useToggle(false);

  const collapseProps = {
    className: 'checkout-summary-content__collapse',
    isOpen,
  };

  return (
    <div className="checkout-summary-content">
      {isLoading ? <Spinner theme={Spinner.themes.overlaySticky} /> : null}

      {maximumPriceModal && maximumPriceModalVisible ? (
        <CheckoutSummaryModal
          hide={() => setMaximumPriceModalVisible(false)}
          {...maximumPriceModal}
        />
      ) : null}

      <div className="checkout-summary-content__heading">
        <Text
          className="checkout-summary-content__title"
          elementName="h2"
          size={Text.sizes.headline3}
          theme={Text.themes.normal}
        >
          {title}
        </Text>
        <button className="checkout-summary-content__trigger" onClick={toggle}>
          <Text
            elementName="h2"
            size={Text.sizes.headline3}
            theme={Text.themes.normal}
          >
            {title}
          </Text>
          <div
            className={mod('checkout-summary-content__trigger-icon', {
              flipped: isOpen,
            })}
          >
            <ChevronUp />
          </div>
        </button>
      </div>

      <Collapse {...collapseProps}>
        <div className="checkout-summary-content__padded-content">
          {choiceGroups.length > 0 ? (
            <div>
              {choiceGroups.map(({ link, texts, title }) => (
                <div className="checkout-summary-content__choice" key={title}>
                  <Text {...subTitleProps}>{title}</Text>
                  <Text size={Text.sizes.small}>
                    {link ? (
                      <CleanLink theme={CleanLink.themes.orange} {...link} />
                    ) : null}
                  </Text>
                  <Text
                    className="checkout-summary-content__choice-list"
                    size={Text.sizes.small}
                  >
                    <List theme={List.themes.greenCheckmarks}>{texts}</List>
                  </Text>
                </div>
              ))}
            </div>
          ) : null}
          {additionalInformation.length
            ? additionalInformation.map(({ link, texts, title }) => (
                <div className="checkout-summary-content__choice" key={title}>
                  <Text {...subTitleProps}>{title}</Text>
                  <Text size={Text.sizes.small}>
                    {link ? (
                      <CleanLink theme={CleanLink.themes.orange} {...link} />
                    ) : null}
                  </Text>
                  <Text
                    className="checkout-summary-content__choice-list"
                    size={Text.sizes.small}
                  >
                    <List>{texts}</List>
                  </Text>
                </div>
              ))
            : null}
        </div>
        {preCorporatePrice ? (
          <PreCorporatePrice {...preCorporatePrice} />
        ) : null}
      </Collapse>

      {bannerTitle ? (
        <Collapse {...collapseProps}>
          <div className="checkout-summary-content__banner">
            <Text
              elementName="h3"
              size={Text.sizes.basic}
              theme={Text.themes.headline}
              italic
            >
              {bannerTitle}
            </Text>
            {bannerText ? (
              <Text size={Text.sizes.small}>{bannerText}</Text>
            ) : null}

            {maximumPriceModal ? (
              <button
                className="checkout-summary-content__banner-button"
                onClick={() => setMaximumPriceModalVisible(true)}
              >
                <span
                  aria-hidden="true"
                  className="checkout-summary-content__banner-icon"
                >
                  ?
                </span>
                {bannerButtonText}
              </button>
            ) : null}
          </div>
        </Collapse>
      ) : null}

      {price ? (
        <div
          className={mod('checkout-summary-content__price-wrapper', {
            green: isMaximumPrice,
          })}
        >
          <div className="checkout-summary-content__price">
            <Text {...subTitleProps}>{priceTitle}</Text>
            <div className="checkout-summary-content__price-inner">
              <Price {...price} />
            </div>
            {reducedPriceDisclaimer ? (
              <div className="checkout-summary-content__reduced-price-disclaimer">
                {reducedPriceDisclaimer.description ? (
                  <Text size={Text.sizes.small}>
                    {reducedPriceDisclaimer.description}
                  </Text>
                ) : null}
                <Text
                  className="access-level-card__reduced-price"
                  size={Text.sizes.interface}
                >
                  {reducedPriceDisclaimer.normalPrice}
                </Text>
              </div>
            ) : null}
          </div>
          {button ? (
            <div className="checkout-summary-content__link-container">
              <LinkButton
                variant={LinkButton.variants.cta}
                size={LinkButton.sizes.large}
                {...button}
              />
            </div>
          ) : null}
        </div>
      ) : null}

      {bindingPrice && bindingPrice.price ? (
        <Collapse {...collapseProps}>
          <Text
            className="checkout-summary-content__binding-price"
            elementName="p"
            size={Text.sizes.small}
          >
            {bindingPriceLabel}
            <Text
              elementName="span"
              size={Text.sizes.small}
              theme={Text.themes.emphasis}
            >
              {' '}
              {bindingPrice.price}
              {bindingPrice.currency ? ` ${bindingPrice.currency}` : null}
            </Text>
          </Text>
        </Collapse>
      ) : null}
    </div>
  );
};

export default CheckoutSummaryContent;
