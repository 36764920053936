import React, { useState } from 'react';
import Collapse from 'react-tiny-collapse';

import Button from '@sats-group/ui-lib/react/button';
import Text from '@sats-group/ui-lib/react/text';

import { mod } from 'client/helpers/add-bem-modifiers';
import DynamicImage from 'components/dynamic-image/dynamic-image';
import EmbedVideo from 'components/embed-video/embed-video';
import EmbedYoutube from 'components/embed-youtube/embed-youtube';

import type { EmbedMedia as Props, Media } from './embed-media.types';
import { themes } from './embed-media.types';

const EmbedMedia: React.FunctionComponent<Props> & {
  themes: typeof themes;
} = ({ error, media = [], poster, theme }) => {
  const [currentVideo, setCurrentVideo] = useState<Media['video']>(undefined);
  const [currentYouTube, setCurrentYouTube] =
    useState<Media['youTube']>(undefined);
  const hasCurrentMedia = Boolean(currentVideo);

  const setCurrentMedia = ({
    video,
    youTube,
  }: Pick<Media, 'video' | 'youTube'>) => {
    if (video) {
      setCurrentVideo(video);
      setCurrentYouTube(undefined);
    } else if (youTube) {
      setCurrentVideo(undefined);
      setCurrentYouTube(youTube);
    }
  };

  const buttons = (
    <React.Fragment>
      {media.map(
        ({ buttonText, isPrimaryButton, video, youTube }) =>
          (video || youTube) && (
            <Button
              key={buttonText}
              className="embed-media__button"
              variant={isPrimaryButton ? undefined : Button.variants.secondary}
              onClick={() =>
                setCurrentMedia({
                  video,
                  youTube,
                })
              }
              text={buttonText}
            />
          )
      )}
    </React.Fragment>
  );

  return (
    <div className={mod('embed-media', theme)}>
      {error ? (
        <Text className="embed-media__error" theme={Text.themes.emphasis}>
          {error}
        </Text>
      ) : null}
      {/* NOTE: This instance of Collapse animates the height of its child whenever thei height of the child changes */}
      <Collapse
        animateChildren={true}
        duration={500}
        easing="cubic-bezier(0.4, 0, 0.25, 1)"
        isOpen={true}
      >
        <div className="embed-media__content">
          {poster ? (
            <div className="embed-media__poster">
              <DynamicImage {...poster} />
            </div>
          ) : null}
          {currentVideo ? (
            <EmbedVideo autoplay={true} {...currentVideo} />
          ) : currentYouTube ? (
            <EmbedYoutube {...currentYouTube} />
          ) : (
            <div className="embed-media__menu">
              <div className="embed-media__menu-buttons">{buttons}</div>
            </div>
          )}
        </div>
      </Collapse>

      {media.length > 1 ? (
        <Collapse
          duration={500}
          easing="cubic-bezier(0.4, 0, 0.25, 1)"
          isOpen={hasCurrentMedia}
          unmountChildren={true}
        >
          <div className="embed-media__buttons">{buttons}</div>
        </Collapse>
      ) : null}
    </div>
  );
};

EmbedMedia.themes = themes;

export default EmbedMedia;
