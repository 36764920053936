import React from 'react';

import Link from '@sats-group/ui-lib/react/link';
import Text from '@sats-group/ui-lib/react/text';

import ContentContainer from 'components/content-container/content-container';
import Layout from 'components/layout/layout';

import type { MemberCareConfirmationPage as Props } from './member-care-confirmation-page.types';

const MemberCareConfirmationPage: React.FunctionComponent<Props> = ({
  layout,
  link,
  message,
  title,
}) => (
  <Layout {...layout}>
    <ContentContainer theme={ContentContainer.themes.narrow}>
      <div className="member-care-confirmation-page">
        <Text
          elementName="h1"
          size={Text.sizes.headline2}
          theme={Text.themes.normal}
        >
          {title}
        </Text>
        <Text>{message}</Text>
        <div>
          <Link {...link} />
        </div>
      </div>
    </ContentContainer>
  </Layout>
);

export default MemberCareConfirmationPage;
