import React from 'react';
import CTABanner from '../cta-banner/cta-banner';
import Text from '@sats-group/ui-lib/react/text';
import { type PriceDifferenceCard as PriceDifferenceCardType } from './price-difference-card.types';

const PriceDifferenceCard: React.FC<PriceDifferenceCardType> = ({
  headline,
  description,
  button,
  currentPrice,
  listPrice,
  yourPriceText,
  newPriceText,
}) => (
  <div className="price-difference-card">
    <CTABanner
      theme={CTABanner.themes.dark}
      title={headline}
      text={description}
      link={{
        href: button?.href,
        text: button?.text,
        variant: 'cta',
      }}
    >
      <div className="price-difference-card__content">
        <div className="price-difference-card__container">
          <Text>{yourPriceText}:</Text>
          <Text
            size={Text.sizes.large}
            theme={Text.themes.emphasis}
            className="price-difference-card__price"
          >
            {currentPrice}
          </Text>
        </div>
        <div className="price-difference-card__container">
          <Text>{newPriceText}:</Text>
          <Text
            size={Text.sizes.large}
            theme={Text.themes.emphasis}
            className="price-difference-card__price"
          >
            {listPrice}
          </Text>
        </div>
      </div>
    </CTABanner>
  </div>
);

export default PriceDifferenceCard;
