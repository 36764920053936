import React from 'react';
import Text from '@sats-group/ui-lib/react/text';

import type { Price as Props } from './price.types';

/**
 * See https://www.figma.com/design/VYtHMl4Z4ubu1fuhQeDZEG/Framework?node-id=4518-846
 */
const Price: React.FC<Props> = ({
  current,
  information,
  original,
  small,
  split,
}) => (
  <div className="price">
    {original ? (
      <div className="price__original">
        <Text size={small ? Text.sizes.interface : Text.sizes.small}>
          {original}
        </Text>
      </div>
    ) : null}
    <div className="price__current">
      <Text
        size={small ? Text.sizes.basic : Text.sizes.headline3}
        theme={small ? Text.themes.headline : Text.themes.emphasis}
      >
        {current.value}
      </Text>
      {current.post ? (
        <Text
          size={small ? Text.sizes.small : Text.sizes.basic}
          theme={Text.themes.emphasis}
        >
          {current.post}
        </Text>
      ) : null}
    </div>
    {split ? (
      <Text
        size={small ? Text.sizes.interface : Text.sizes.small}
        theme={Text.themes.emphasis}
      >
        {split}
      </Text>
    ) : null}
    {information.map(entry => (
      <Text key={entry} size={small ? Text.sizes.interface : Text.sizes.small}>
        {entry}
      </Text>
    ))}
  </div>
);

export default Price;
