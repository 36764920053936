import React from 'react';

import Message from '@sats-group/ui-lib/react/message';

import ContentBlocks from 'components/content-blocks/content-blocks';
import ContentContainer from 'components/content-container/content-container';
import PreviewLayout from 'components/preview-layout/preview-layout';

import type { ListPreview as Props } from './list-preview.types';

const ListPreview: React.FunctionComponent<Props> = ({ blocks }) => (
  <PreviewLayout>
    <div className="list-preview">
      <ContentContainer>
        {blocks ? (
          <ContentBlocks {...blocks} />
        ) : (
          <Message text="No blocks props" />
        )}
      </ContentContainer>
    </div>
  </PreviewLayout>
);

export default ListPreview;
