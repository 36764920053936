import type { Block, Inline } from '@contentful/rich-text-types';

import type { Quote } from '../quote/quote.types';

export const toQuoteProps = (node: Block | Inline): Quote | undefined => {
  const fields = node?.data?.target?.fields;

  const background = fields.lightBackground;
  const image = fields.image;
  const quote = fields.quote;
  const signature = fields.signature;

  if (quote && signature) {
    return {
      background,
      image,
      quote,
      signature,
    };
  }

  return undefined;
};
