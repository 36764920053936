import React from 'react';

import Text from '@sats-group/ui-lib/react/text';

import WebAdminLayout from 'client/components/web-admin-layout/web-admin-layout';

import type { WebAdminContentTypes as Props } from './web-admin-content-types.types';

const WebAdminContentTypes: React.FunctionComponent<Props> = ({
  contentTypes,
  layout,
}) => (
  <WebAdminLayout {...layout}>
    <div className="web-admin-content-types">
      <Text
        elementName="h1"
        size={Text.sizes.headline2}
        theme={Text.themes.emphasis}
      >
        Content Types
      </Text>
      <table>
        <thead>
          <tr>
            <th style={{ textAlign: 'left' }}>ID</th>
            <th style={{ textAlign: 'left' }}>Type</th>
            <th style={{ textAlign: 'left' }}>Fields</th>
          </tr>
        </thead>
        <tbody>
          {contentTypes.map(([id, type, fields]) => (
            <tr key={id}>
              <td style={{ verticalAlign: 'top' }}>{id}</td>
              <td style={{ verticalAlign: 'top' }}>{type}</td>
              <td style={{ verticalAlign: 'top' }}>{fields.join(', ')}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </WebAdminLayout>
);

export default WebAdminContentTypes;
