import React from 'react';
import Text from '@sats-group/ui-lib/react/text';

import Breadcrumbs from 'components/breadcrumbs/breadcrumbs';
import ContentBlocks from 'components/content-blocks/content-blocks';
import ContentContainer from 'components/content-container/content-container';
import Hero from 'components/hero/hero';
import Layout from 'components/layout/layout';

import type { CorporateSub as Props } from './corporate-sub.types';
import { HeroVariant } from 'client/components/hero/hero.types';

const CorporateSub: React.FunctionComponent<Props> = ({
  blocks,
  breadcrumbs,
  hero,
  layout,
  title,
}) => (
  <Layout {...layout}>
    <Breadcrumbs {...breadcrumbs} />
    {hero ? <Hero {...hero} variant={HeroVariant.Clean} /> : null}
    <ContentContainer theme={ContentContainer.themes.wide}>
      <div className="corporate-sub">
        {hero ? null : (
          <Text
            elementName="h1"
            italic
            size={Text.sizes.headline1}
            theme={Text.themes.headline}
          >
            {title}
          </Text>
        )}
        <ContentBlocks {...blocks} />
      </div>
    </ContentContainer>
  </Layout>
);

export default CorporateSub;
