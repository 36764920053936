/* eslint-disable react/no-multi-comp */
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import type { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import React from 'react';

import Text from '@sats-group/ui-lib/react/text';

import AssetHyperlink from './asset-hyperlink';
import EmbeddedAssetRenderer from './embedded-asset-renderer';
import EmbeddedEntryRenderer from './embedded-entry-renderer';
import RichTextQuoteBlock from './rich-text-quote-block';
import TableRenderer from './table-renderer';

const NodeWrapper: React.FC<
  React.PropsWithChildren<{
    nodeType: string;
  }>
> = ({ nodeType, children }) => (
  <div className={`rich-text__node-${nodeType}`} data-node-type={nodeType}>
    {children}
  </div>
);

const defaultRenderNodes: NonNullable<
  Parameters<typeof documentToReactComponents>[1]
>['renderNode'] = {
  [INLINES.ASSET_HYPERLINK]: (node, children) => (
    <NodeWrapper nodeType={node.nodeType}>
      {AssetHyperlink(node, children)}
    </NodeWrapper>
  ),
  [BLOCKS.QUOTE]: node => (
    <NodeWrapper nodeType={node.nodeType}>
      <RichTextQuoteBlock text={documentToPlainTextString(node)} />
    </NodeWrapper>
  ),
  [BLOCKS.EMBEDDED_ASSET]: (node, children) => (
    <NodeWrapper nodeType={node.nodeType}>
      {EmbeddedAssetRenderer(node, children)}
    </NodeWrapper>
  ),
  [BLOCKS.EMBEDDED_ENTRY]: (node, children) => (
    <NodeWrapper nodeType={node.nodeType}>
      {EmbeddedEntryRenderer(node, children)}
    </NodeWrapper>
  ),
  [BLOCKS.PARAGRAPH]: (node, children) => (
    <NodeWrapper nodeType={node.nodeType}>
      <Text elementName="p" size={Text.sizes.basic}>
        {children}
      </Text>
    </NodeWrapper>
  ),
  [BLOCKS.HEADING_1]: (node, children) => (
    <NodeWrapper nodeType={node.nodeType}>
      <Text
        elementName="h1"
        size={Text.sizes.headline2}
        theme={Text.themes.emphasis}
      >
        {children}
      </Text>
    </NodeWrapper>
  ),
  [BLOCKS.HEADING_2]: (node, children) => (
    <NodeWrapper nodeType={node.nodeType}>
      <Text
        elementName="h2"
        size={Text.sizes.headline3}
        theme={Text.themes.emphasis}
      >
        {children}
      </Text>
    </NodeWrapper>
  ),
  [BLOCKS.HEADING_3]: (node, children) => (
    <NodeWrapper nodeType={node.nodeType}>
      <Text
        elementName="h3"
        size={Text.sizes.large}
        theme={Text.themes.emphasis}
      >
        {children}
      </Text>
    </NodeWrapper>
  ),
  [BLOCKS.HEADING_4]: (node, children) => (
    <NodeWrapper nodeType={node.nodeType}>
      <Text elementName="h4" theme={Text.themes.emphasis}>
        {children}
      </Text>
    </NodeWrapper>
  ),
  [BLOCKS.HEADING_5]: (node, children) => (
    <NodeWrapper nodeType={node.nodeType}>
      <Text elementName="h5" theme={Text.themes.emphasis}>
        {children}
      </Text>
    </NodeWrapper>
  ),
  [BLOCKS.HEADING_6]: (node, children) => (
    <NodeWrapper nodeType={node.nodeType}>
      <Text elementName="h6" theme={Text.themes.emphasis}>
        {children}
      </Text>
    </NodeWrapper>
  ),
  [BLOCKS.TABLE]: (node, children) => (
    <NodeWrapper nodeType={node.nodeType}>
      {TableRenderer(node, children)}
    </NodeWrapper>
  ),
  [BLOCKS.UL_LIST]: (node, children) => (
    <NodeWrapper nodeType={node.nodeType}>
      <ul>{children}</ul>
    </NodeWrapper>
  ),
  [BLOCKS.OL_LIST]: (node, children) => (
    <NodeWrapper nodeType={node.nodeType}>
      <ol>{children}</ol>
    </NodeWrapper>
  ),
};

export const renderOptions = {
  renderNode: defaultRenderNodes,
};
