import React from 'react';

import LinkButton from '@sats-group/ui-lib/react/link-button';
import Text from '@sats-group/ui-lib/react/text';

import ContentContainer from 'components/content-container/content-container';
import ImageLinkCard from 'components/image-link-card/image-link-card';
import List from 'components/list/list';
import MyPageLayout from 'components/my-page-layout/my-page-layout';

import type { FreezeMotivationsPage as Props } from './freeze-motivations-page.types';

const FreezeMotivationsPage: React.FunctionComponent<Props> = ({
  intro,
  motivations,
  myPageLayout,
  nextPage,
  prevPage,
}) => (
  <MyPageLayout {...myPageLayout}>
    <div className="freeze-motivations-page">
      <ContentContainer>
        <Text
          className="freeze-motivations-page__intro"
          size={Text.sizes.basic}
        >
          {intro}
        </Text>
        <List
          className="freeze-motivations-page__motivation-list"
          theme={List.themes.marginLarge}
        >
          {motivations.map(motivation => (
            <ImageLinkCard
              {...motivation}
              key={motivation.href}
              shouldOpenInNewTab={true}
              theme={ImageLinkCard.themes.narrowImage}
            />
          ))}
        </List>
        <LinkButton
          className="freeze-motivations-page__link-button"
          variant={LinkButton.variants.secondary}
          {...prevPage}
        />
        <LinkButton
          className="freeze-motivations-page__link-button"
          {...nextPage}
          data-test-next-page
        />
      </ContentContainer>
    </div>
  </MyPageLayout>
);

export default FreezeMotivationsPage;
