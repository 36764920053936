import SvgArrowDown from '@sats-group/icons/24/arrow-down';
import cn from 'classnames';
import React from 'react';
import Collapse from 'react-tiny-collapse';
import useToggle from '@sats-group/ui-lib/react/hooks/use-toggle';
import Text from '@sats-group/ui-lib/react/text';

import ActionBox from 'components/action-box/action-box';
import ArrowLink from 'components/arrow-link/arrow-link';
import ContentContainer from 'components/content-container/content-container';
import MyPageLayout from 'components/my-page-layout/my-page-layout';

import type { ManageMembership as Props } from './manage-membership.types';

const ManageMembership: React.FunctionComponent<Props> = ({
  groups,
  layout,
  nav,
}) => {
  const [isNavOpen, toggleNav] = useToggle(false);

  return (
    <MyPageLayout {...layout}>
      <ContentContainer className="manage-membership">
        {nav.enabled ? (
          <div className="manage-membership__group">
            <Text
              elementName="h2"
              size={Text.sizes.large}
              theme={Text.themes.emphasis}
            >
              {nav.title}
            </Text>
            <div className="manage-membership__nav">
              <button
                aria-controls={nav.id}
                aria-expanded={isNavOpen}
                className="manage-membership__nav-trigger"
                onClick={toggleNav}
              >
                <Text>{nav.trigger}</Text>
                <div
                  className={cn('manage-membership__nav-flourish', {
                    'manage-membership__nav-flourish--flipped': isNavOpen,
                  })}
                >
                  <SvgArrowDown />
                </div>
              </button>
              <Collapse isOpen={isNavOpen}>
                <nav className="manage-membership__nav-items" id={nav.id}>
                  {nav.options.map(entry => (
                    <div key={entry.href}>
                      {React.createElement(
                        entry.current ? 'span' : 'a',
                        {
                          'aria-current': entry.current,
                          className: 'manage-membership__nav-item',
                          href: entry.current ? undefined : entry.href,
                        },
                        <Text
                          elementName="span"
                          theme={
                            entry.current ? Text.themes.emphasis : undefined
                          }
                        >
                          {entry.text}
                        </Text>
                      )}
                    </div>
                  ))}
                </nav>
              </Collapse>
            </div>
          </div>
        ) : null}
        {groups.map(group => (
          <div className="manage-membership__group" key={group.title}>
            <Text
              elementName="h2"
              size={Text.sizes.large}
              theme={Text.themes.emphasis}
            >
              {group.title}
            </Text>
            {group.action ? (
              <div>
                <ActionBox {...group.action} secondary />
              </div>
            ) : null}
            <div className="manage-membership__actions">
              {group.actions.map(action => (
                <div key={action.href}>
                  <ArrowLink {...action} />
                </div>
              ))}
            </div>
          </div>
        ))}
      </ContentContainer>
    </MyPageLayout>
  );
};

export default ManageMembership;
