import { useEffect } from 'react';

import { appendScript } from 'client/helpers/append-script';
import type { EmbeddedService } from 'client/types/salesforce-embedded-service';

export type Init = Parameters<EmbeddedService['init']>;
export type Settings = Partial<EmbeddedService['settings']>;

const initESW = (init: Init, settings: Settings, postInit: () => void) => {
  if (!window.embedded_svc) {
    return;
  }

  Object.assign(window.embedded_svc.settings, settings);

  const chatDataContainer = document.querySelector('[data-chat]');
  if (chatDataContainer) {
    try {
      const memberData = JSON.parse(chatDataContainer.innerHTML);
      window.embedded_svc.settings.prepopulatedPrechatFields = {
        FirstName: memberData.firstName,
        LastName: memberData.lastName,
        Email: memberData.email,
        MobilePhone: memberData.mobile,
        SATS_Exerp_Member_Id__c: memberData.id,
      };
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('Error initializing Embedded Service: ', err);
    }
  }

  window.embedded_svc.init(...init);

  postInit();
};

export const useSalesforceChat = (
  src: string,
  init: Init,
  settings: Settings,
  postInit: () => void
) =>
  useEffect(() => {
    if (window.embedded_svc) {
      initESW(init, settings, postInit);
    } else {
      appendScript(src, () =>
        initESW(
          // NOTE: Sets `gslbBaseURL` to `null`, as it's explicitly null
          // in the examples when loading esw for the first time.
          init.map((entry, index) => (index === 2 ? null : entry)) as Init,
          settings,
          postInit
        )
      );
    }
  }, []);
