import React from 'react';

import Text from '@sats-group/ui-lib/react/text';

import ContentContainer from 'components/content-container/content-container';
import MyPageLayout from 'components/my-page-layout/my-page-layout';
import MyProfileCard from 'components/my-profile-card/my-profile-card';
import UserDetailsList from 'components/user-details-list/user-details-list';

import type { MyProfilePage as Props } from './my-profile-page.types';

const MyProfilePage: React.FunctionComponent<Props> = ({
  myPageLayout,
  otherInformationTitle,
  otherInformation,
  personalInformation,
  personalInformationTitle,
  profileCard,
}) => (
  <MyPageLayout {...myPageLayout}>
    <ContentContainer>
      <div className="my-profile-page">
        {profileCard ? (
          <div className="my-profile-page__profile-card">
            <MyProfileCard {...profileCard} />
          </div>
        ) : null}
        {personalInformation ? (
          <div className="my-profile-page__user-details">
            <Text
              className="my-profile-page__heading"
              elementName="h2"
              theme={Text.themes.normal}
              size={Text.sizes.headline3}
            >
              {personalInformationTitle}
            </Text>
            <UserDetailsList {...personalInformation} />
          </div>
        ) : null}

        {otherInformation ? (
          <div className="my-profile-page__user-details">
            <Text
              className="my-profile-page__heading"
              elementName="h2"
              theme={Text.themes.normal}
              size={Text.sizes.headline3}
            >
              {otherInformationTitle}
            </Text>
            <UserDetailsList {...otherInformation} />
          </div>
        ) : null}
      </div>
    </ContentContainer>
  </MyPageLayout>
);

export default MyProfilePage;
