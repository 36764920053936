import LocationIcon from '@sats-group/icons/24/location';
import MailIcon from '@sats-group/icons/24/mail';
import PhoneIcon from '@sats-group/icons/24/phone';
import React from 'react';

import Text from '@sats-group/ui-lib/react/text';

import type { Contact as Props } from './contact.types';

const icons = {
  location: LocationIcon,
  mail: MailIcon,
  phone: PhoneIcon,
};

const Contact: React.FunctionComponent<Props> = ({ items = [] }) => (
  <div className="contact">
    {items.map(({ text, href, type }) => {
      const textEl = <Text size={Text.sizes.basic}>{text}</Text>;
      return (
        <div className="contact__item" key={text}>
          {type
            ? React.createElement(icons[type], { className: 'contact__icon' })
            : null}
          {href ? <a href={href}>{textEl}</a> : textEl}
        </div>
      );
    })}
  </div>
);

export default Contact;
