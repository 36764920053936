import React, { useState } from 'react';

import Button from '@sats-group/ui-lib/react/button';
import HiddenInput from '@sats-group/ui-lib/react/hidden-input';
import LinkButton from '@sats-group/ui-lib/react/link-button';
import Text from '@sats-group/ui-lib/react/text';
import TextInput from '@sats-group/ui-lib/react/text-input';

import ContentContainer from 'components/content-container/content-container';
import MyPageLayout from 'components/my-page-layout/my-page-layout';
import Spinner from 'components/spinner/spinner';
import TextInterpolator from 'components/text-interpolator/text-interpolator';

import type { CancelMembershipProvideReasonPage as Props } from './cancel-membership-provide-reason-page.types';

const CancelMembershipProvideReasonPage: React.FunctionComponent<Props> = ({
  characterLimit,
  hiddenInputs,
  myPageLayout,
  nextPage,
  otherReasonText,
  previousPage,
  provideReasonText,
  reason,
  reasonInput,
  redirectUrl,
}) => {
  const [charactersRemaining, setCharactersRemaining] = useState(
    characterLimit.inputLimit
  );

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    if (isSubmitting) {
      event.preventDefault();
    }

    setIsSubmitting(true);
  };

  return (
    <MyPageLayout {...myPageLayout}>
      <ContentContainer>
        <div className="cancel-membership-provide-reason-page">
          {isSubmitting ? <Spinner theme={Spinner.themes.overlay} /> : null}
          <Text elementName="h2" size={Text.sizes.headline2}>
            {otherReasonText}
          </Text>
          <Text
            className="cancel-membership-provide-reason-page__text"
            elementName="p"
          >
            {provideReasonText}
          </Text>
          <form action={redirectUrl} method="GET" onSubmit={handleSubmit}>
            {hiddenInputs.map(input => (
              <HiddenInput {...input} key={input.name} />
            ))}
            <TextInput
              data-test-write-reason
              defaultValue={reason}
              onChange={e =>
                setCharactersRemaining(
                  characterLimit.inputLimit - e.target.value.length
                )
              }
              {...reasonInput}
            ></TextInput>
            <Text
              className="cancel-membership-provide-reason-page__character-limit-text"
              elementName="p"
            >
              <TextInterpolator
                template={characterLimit.limitText}
                tokens={{ characterLimit: String(charactersRemaining) }}
              />
            </Text>
            <div className="cancel-membership-page__actions">
              <LinkButton
                className="cancel-membership-page__previous"
                {...previousPage}
                variant={LinkButton.variants.secondary}
              />
              <Button data-test-submit type="submit" text={nextPage} />
            </div>
          </form>
        </div>
      </ContentContainer>
    </MyPageLayout>
  );
};

export default CancelMembershipProvideReasonPage;
