import React from 'react';

import Expander from '@sats-group/ui-lib/react/expander';

import { BlockType } from 'shared/content-block-helpers';

import ArticleCard from 'components/article-card/article-card';
import Carousel from 'components/carousel/carousel';
import ComponentList from 'components/component-list/component-list';
import ContentContainer from 'components/content-container/content-container';
import ContentElement from 'components/content-element/content-element';
import CTABanner from 'components/cta-banner/cta-banner';
import EmbedYoutube from 'components/embed-youtube/embed-youtube';
import ProfileCard from 'components/profile-card/profile-card';
import Quote from 'components/quote/quote';
import RichText from 'components/rich-text/rich-text';
import SplitInfoModule from 'components/split-info-module/split-info-module';
import TextModule from 'components/text-module/text-module';
import UniqueSellingPoint from 'components/unique-selling-point/unique-selling-point';
import UspList from 'components/usp-list/usp-list';
import VideoCard from 'components/video-card/video-card';

import ContentBlock from './content-block';
import type { ContentBlocks as ContentBlocksProps } from './content-blocks.types';

const ContentBlocks: React.FC<ContentBlocksProps> = ({ blocks }) => (
  <div className="content-blocks">
    <div className="content-blocks__blocks">
      {blocks.map(block => {
        switch (block.type) {
          case BlockType.ArticleCardCarousel: {
            return (
              <ContentBlock id={block.key} key={block.key} type={block.type}>
                <Carousel {...block.props} entryRenderer={ArticleCard} />
              </ContentBlock>
            );
          }
          case BlockType.ArticleCardComponentList: {
            return (
              <ContentBlock id={block.key} key={block.key} type={block.type}>
                <ComponentList {...block.props} entryRenderer={ArticleCard} />
              </ContentBlock>
            );
          }
          case BlockType.ContentElementCarousel: {
            return (
              <ContentBlock id={block.key} key={block.key} type={block.type}>
                <Carousel {...block.props} entryRenderer={ContentElement} />
              </ContentBlock>
            );
          }
          case BlockType.ContentElementComponentList: {
            return (
              <ContentBlock id={block.key} key={block.key} type={block.type}>
                <ComponentList
                  {...block.props}
                  entryRenderer={ContentElement}
                />
              </ContentBlock>
            );
          }
          case BlockType.Expander: {
            return (
              <ContentBlock id={block.key} key={block.key} type={block.type}>
                <ContentContainer>
                  <Expander {...block.props} itemRenderer={RichText} />
                </ContentContainer>
              </ContentBlock>
            );
          }
          case BlockType.ProductBox: {
            return (
              <ContentBlock id={block.key} key={block.key} type={block.type}>
                <ContentContainer>
                  <CTABanner theme={CTABanner.themes.light} {...block.props} />
                </ContentContainer>
              </ContentBlock>
            );
          }
          case BlockType.ProfileCardCarousel: {
            return (
              <ContentBlock id={block.key} key={block.key} type={block.type}>
                <Carousel {...block.props} entryRenderer={ProfileCard} />
              </ContentBlock>
            );
          }
          case BlockType.ProfileCardComponentList: {
            return (
              <ContentBlock id={block.key} key={block.key} type={block.type}>
                <ComponentList {...block.props} entryRenderer={ProfileCard} />
              </ContentBlock>
            );
          }
          case BlockType.Quote: {
            return (
              <ContentBlock id={block.key} key={block.key} type={block.type}>
                <Quote {...block.props} />
              </ContentBlock>
            );
          }
          case BlockType.QuoteCarousel: {
            return (
              <ContentBlock id={block.key} key={block.key} type={block.type}>
                <Carousel {...block.props} entryRenderer={Quote} />
              </ContentBlock>
            );
          }
          case BlockType.QuoteComponentList: {
            return (
              <ContentBlock id={block.key} key={block.key} type={block.type}>
                <ComponentList {...block.props} entryRenderer={Quote} />
              </ContentBlock>
            );
          }
          case BlockType.SplitInfoModule: {
            return (
              <ContentBlock id={block.key} key={block.key} type={block.type}>
                <SplitInfoModule {...block.props} />
              </ContentBlock>
            );
          }
          case BlockType.TextModule: {
            return (
              <ContentBlock
                center
                id={block.key}
                key={block.key}
                type={block.type}
              >
                <TextModule {...block.props} />
              </ContentBlock>
            );
          }
          case BlockType.TextModuleCarousel: {
            return (
              <ContentBlock id={block.key} key={block.key} type={block.type}>
                <Carousel {...block.props} entryRenderer={TextModule} />
              </ContentBlock>
            );
          }
          case BlockType.TextModuleComponentList: {
            return (
              <ContentBlock id={block.key} key={block.key} type={block.type}>
                <ComponentList {...block.props} entryRenderer={TextModule} />
              </ContentBlock>
            );
          }
          case BlockType.UspCarousel: {
            return (
              <ContentBlock id={block.key} key={block.key} type={block.type}>
                <Carousel {...block.props} entryRenderer={UniqueSellingPoint} />
              </ContentBlock>
            );
          }
          case BlockType.UspComponentList: {
            return (
              <ContentBlock id={block.key} key={block.key} type={block.type}>
                <ComponentList
                  {...block.props}
                  entryRenderer={UniqueSellingPoint}
                />
              </ContentBlock>
            );
          }
          case BlockType.UspList: {
            return (
              <ContentBlock id={block.key} key={block.key} type={block.type}>
                <UspList {...block.props} />
              </ContentBlock>
            );
          }
          case BlockType.VideoCardCarousel: {
            return (
              <ContentBlock id={block.key} key={block.key} type={block.type}>
                <Carousel {...block.props} entryRenderer={VideoCard} />
              </ContentBlock>
            );
          }
          case BlockType.YoutubeEmbed: {
            return (
              <ContentBlock
                center
                id={block.key}
                key={block.key}
                type={block.type}
              >
                <div className="content-blocks__youtube-embed">
                  <EmbedYoutube {...block.props} />
                </div>
              </ContentBlock>
            );
          }
        }
      })}
    </div>
  </div>
);

export default ContentBlocks;
