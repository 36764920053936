import cn from 'classnames';
import React from 'react';
import Banner from '@sats-group/ui-lib/react/banner';
import Text from '@sats-group/ui-lib/react/text';

import ProgressBar from '@sats-group/ui-lib/react/progress-bar';

import Logo from '../logo/logo';

import type { PtShopLayout as Props } from './pt-shop-layout.types';

const PtShopLayout = <T, U>({
  flipped,
  header,
  messages,
  primary,
  primaryRenderer,
  secondary,
  secondaryRenderer,
  title,
}: Props<T, U> & {
  primaryRenderer: (props: T) => React.ReactNode;
  secondaryRenderer: (props: U) => React.ReactNode;
}) => (
  <div className="pt-shop-layout">
    <header className="pt-shop-layout__header">
      <Logo {...header.logo} />
      <div className="pt-shop-layout__header-progress">
        <ProgressBar
          {...header.progress}
          variant={ProgressBar.variants.cta}
          theme={ProgressBar.themes.dark}
          labelAlignment={ProgressBar.labelAlignments.left}
          labelPosition={ProgressBar.labelPositions.topLeft}
        />
      </div>
    </header>
    <div
      className={cn('pt-shop-layout__content', {
        'pt-shop-layout__content--flipped': flipped,
      })}
    >
      <main className="pt-shop-layout__primary">
        <div className="pt-shop-layout__title">
          <Text
            elementName="h1"
            size={Text.sizes.headline1}
            theme={Text.themes.emphasis}
          >
            {title}
          </Text>
        </div>
        {messages.length ? (
          <div className="pt-shop-layout__messages">
            {messages.map(entry => (
              <div key={entry.text}>
                <Banner {...entry} />
              </div>
            ))}
          </div>
        ) : null}
        <div>{primaryRenderer(primary)}</div>
      </main>
      <aside className="pt-shop-layout__secondary">
        {secondaryRenderer(secondary)}
      </aside>
    </div>
  </div>
);

export default PtShopLayout;
