import React, { useState } from 'react';

import Button from '@sats-group/ui-lib/react/button';
import HiddenInput from '@sats-group/ui-lib/react/hidden-input';

import ChangeMembershipSummary from 'components/change-membership-summary/change-membership-summary';
import ContentContainer from 'components/content-container/content-container';
import MyPageLayout from 'components/my-page-layout/my-page-layout';
import RadioCard from 'components/radio-card/radio-card';
import Spinner from 'components/spinner/spinner';

import type { ChangeMembershipOptionPage as Props } from './change-membership-option-page.types';

const ChangeMembershipOptionPage: React.FunctionComponent<Props> = ({
  endpoint,
  hiddenInputs,
  myPageLayout,
  options,
  preSelectedOption,
  submit,
  summary,
}) => {
  const [selectedOption, setSelectedOption] = useState(preSelectedOption);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    if (isSubmitting) {
      event.preventDefault();
    }

    setIsSubmitting(true);
  };

  return (
    <MyPageLayout {...myPageLayout}>
      {isSubmitting ? <Spinner theme={Spinner.themes.overlay} /> : null}
      <ContentContainer>
        <div className="change-membership-option-page">
          {options.length ? (
            <div>
              <form
                action={endpoint}
                className="change-membership-option-page__content"
                method="POST"
                onSubmit={handleSubmit}
              >
                {hiddenInputs.map(input => (
                  <HiddenInput key={input.name} {...input} />
                ))}
                <div className="change-membership-option-page__options">
                  {options.map(option => (
                    <RadioCard
                      {...option}
                      key={option.value}
                      isChecked={selectedOption === option.value}
                      onClick={() => setSelectedOption(option.value)}
                    />
                  ))}
                </div>
                <div className="change-membership-option-page__summary">
                  <ChangeMembershipSummary {...summary} />
                </div>
                <div className="change-membership-option-page__save-button">
                  <Button
                    data-test-submit-option
                    text={submit}
                    type="submit"
                    wide
                  />
                </div>
              </form>
            </div>
          ) : null}
        </div>
      </ContentContainer>
    </MyPageLayout>
  );
};

export default ChangeMembershipOptionPage;
