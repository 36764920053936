import cn from 'classnames';
import React from 'react';
import { useIsMounted } from '@sats-group/ui-lib/react/hooks/use-is-mounted';

import type { Blur as Props } from './blur.types';

const Blur: React.FunctionComponent<Props> = () => {
  const isMounted = useIsMounted();

  return <div className={cn('blur', { 'blur--mounted': isMounted })}></div>;
};

export default Blur;
