import { useRef } from 'react';
import useEvent from '@sats-group/ui-lib/react/hooks/use-event';

import scrollingElement from 'client/helpers/scrolling-element';

/** Calls the provided callback with the current scroll position and a position delta each time a scroll event occurs */
export default (callback: (current: number, delta: number) => void) => {
  const prevPos = useRef<number>(null);

  useEvent('scroll', () => {
    if (!scrollingElement) {
      return;
    }

    const current = scrollingElement.scrollTop;
    if (typeof prevPos.current !== 'number') {
      prevPos.current = current;
    }

    const delta = current - prevPos.current;
    callback(current, delta);
    prevPos.current = current;
  });
};
