/* eslint-disable react/no-multi-comp */
import React, { type FormEvent, useEffect, useState } from 'react';

import Button from '@sats-group/ui-lib/react/button';
import Message from '@sats-group/ui-lib/react/message';
import type { Message as MessageType } from '@sats-group/ui-lib/react/message/message.types';
import Modal from '@sats-group/ui-lib/react/modal';
import Text from '@sats-group/ui-lib/react/text';

import { gitHash } from 'shared/git-hash.generated';
import { isString } from 'shared/is';
import { isoNow } from 'shared/iso-now.generated';

import type { ObjectValues } from 'client/types';
import WebAdminLayout from 'components/web-admin-layout/web-admin-layout';

import type { WebAdminData as Props } from './web-admin-data.types';

const wait = (seconds: number) =>
  new Promise(resolve => setTimeout(resolve, seconds * 1000));

const Code: React.FunctionComponent<{ children: string }> = ({ children }) => (
  <code
    style={{
      background: '#cfd8e4',
      borderRadius: '4px',
      padding: '2px 5px',
    }}
  >
    {children}
  </code>
);

type JobStatus = {
  completed: 'completed';
  error: 'error';
  inProgress: 'in-progress';
};

type StatusLog = {
  [key: string]: {
    message: MessageType;
    status: ObjectValues<keyof JobStatus>;
  };
};

const WebAdminData: React.FunctionComponent<Props> = ({
  builtByVersion,
  dataBuildTime,
  layout,
  warnings,
}) => {
  const [appBuildLocalTime, setAppBuildLocalTime] = useState('calculating...');
  const [dataBuildLocalTime, setDataBuildLocalTime] =
    useState('calculating...');
  const [isWarningsModalOpen, setIsWarningsModalOpen] = useState(false);
  const [statusLog, setStatusLog] = useState<StatusLog>({});

  const updateStatusLog = (
    jobName: string,
    status: keyof JobStatus,
    message: MessageType
  ) =>
    setStatusLog(log => {
      const newObject = log;
      newObject[jobName] = { message, status };
      return { ...newObject };
    });

  const isRunning = (jobName: string) => {
    if (!statusLog[jobName]) {
      return false;
    }

    if (statusLog[jobName]?.status === 'inProgress') {
      return true;
    }

    return false;
  };

  const jobRunning = () => {
    const running = Object.entries(statusLog).find(
      ([, value]) => value.status === 'inProgress'
    );

    return running ? true : false;
  };

  useEffect(() => {
    setAppBuildLocalTime(new Date(isoNow).toLocaleString());
    setDataBuildLocalTime(new Date(dataBuildTime).toLocaleString());
  }, []);

  const checkDataJobStatus = async (
    url: string,
    jobName: string,
    currentTry = 0,
    maxTries = 30
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Promise<any> => {
    if (currentTry >= maxTries) {
      updateStatusLog('too-many-tries', 'completed', {
        text: `🚨 Maximum number of status checks (${maxTries}) reached`,
        theme: 'error',
      });

      return;
    }

    await wait(currentTry * 0.5);

    const result = await fetch(url);

    if (result.ok) {
      const body = await result.json();

      if (body.runtimeStatus === 'Pending') {
        updateStatusLog(jobName, 'inProgress', {
          text: `⏳Starting job`,
        });

        return checkDataJobStatus(url, jobName, currentTry + 1, maxTries);
      }

      if (result.status === 202) {
        updateStatusLog(jobName, 'inProgress', {
          text: `${body.customStatus} (${currentTry}/${maxTries})`,
        });

        return checkDataJobStatus(url, jobName, currentTry + 1, maxTries);
      }
      updateStatusLog(jobName, 'completed', {
        text: `${body.customStatus} (${currentTry}/${maxTries})`,
        theme: 'success',
      });
    } else {
      updateStatusLog('status-check-failed', 'completed', {
        text: ` ${jobName.toUpperCase()} status check failed (${currentTry}/${maxTries}) with status ${
          result.status
        } ${result.statusText}`,
        theme: 'error',
      });
    }
  };

  const handleDataJobSubmission = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    //@ts-ignore
    const { action } = event.target;
    //@ts-ignore
    const { name, value } = event.nativeEvent.submitter;

    if (!isString(action) || !isString(name) || !isString(value)) {
      updateStatusLog('missing-action', 'error', {
        text: `🚨 Missing action, name, or value. This is strange and should never happen. Check web-admin-data.tsx`,
        theme: 'error',
      });

      return;
    }

    const body = new FormData();
    body.set(name, value);

    fetch(action, {
      method: 'post',
      body,
    })
      .then(result => {
        const location = result.headers.get('location');
        if (!location) {
          throw new Error('Missing location header');
        }

        checkDataJobStatus(location, value);
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.error('Async job failed to start', error);
        updateStatusLog('async-job-failed-to-start', 'error', {
          text: `🚨 Async job failed to start`,
          theme: 'error',
        });
      });
  };

  return (
    <WebAdminLayout {...layout}>
      <div className="web-admin-data">
        {builtByVersion !== gitHash ? (
          <Message
            text="Data built by a different app version than the one currently running."
            theme={Message.themes.warning}
          />
        ) : null}
        <div className="web-admin-data__meta">
          <div>
            <Text>
              Data built by app version <Code>{builtByVersion}</Code> at
            </Text>
            <ul>
              <li>
                <Code>{dataBuildTime}</Code> ISO
              </li>
              <li>
                <Code>{dataBuildLocalTime}</Code> local
              </li>
            </ul>
          </div>
          <div>
            <Text>
              Current app version <Code>{gitHash}</Code> built at
            </Text>
            <ul>
              <li>
                <Code>{isoNow}</Code> ISO
              </li>
              <li>
                <Code>{appBuildLocalTime}</Code> local
              </li>
            </ul>
          </div>
        </div>

        <Message text="Data functions are handled by a companion app, easing the load on the website. These controls instruct the companion app. All instructions are queued and handled asynchronously." />

        {warnings.length ? (
          <div>
            <Text
              className="web-admin-data__title"
              elementName="h2"
              size={Text.sizes.headline2}
              theme={Text.themes.emphasis}
            >
              Some Contentful Data Seems Broken
            </Text>
            <div className="web-admin-data__actions">
              <Button
                onClick={() => setIsWarningsModalOpen(true)}
                text="Open messages"
                variant={Button.variants.cta}
              />
            </div>
          </div>
        ) : null}

        {isWarningsModalOpen ? (
          <Modal
            closeLabel="Close"
            id="Contentful warnings"
            ariaLabel="Contentful warnings"
            onClose={() => setIsWarningsModalOpen(false)}
            title="Contentful Warnings"
          >
            <div className="web-admin-data__contentful-warnings">
              {warnings.map((warning, index) => (
                <Message {...warning} key={index} />
              ))}
            </div>
          </Modal>
        ) : null}

        <form
          action="/api/admin-start-data-job"
          encType="application/x-www-form-urlencoded"
          onSubmit={handleDataJobSubmission}
          method="post"
        >
          <Text
            className="web-admin-data__title"
            elementName="h2"
            size={Text.sizes.headline2}
            theme={Text.themes.emphasis}
          >
            Cache External Data
          </Text>
          <div className="web-admin-data__actions">
            <Button
              name="job"
              text="Every source"
              type="submit"
              value="cache-all"
              disabled={jobRunning()}
              theme={
                isRunning('cache-all')
                  ? Button.themes.spinner
                  : Button.themes.normal
              }
            />
            <Button
              name="job"
              text="Everything Contentful"
              type="submit"
              value="cache-all-contentful"
              variant={Button.variants.secondary}
              disabled={jobRunning()}
              theme={
                isRunning('cache-all-contentful')
                  ? Button.themes.spinner
                  : Button.themes.normal
              }
            />
            <Button
              name="job"
              text="Contentful"
              type="submit"
              value="cache-contentful"
              variant={Button.variants.secondary}
              disabled={jobRunning()}
              theme={
                isRunning('cache-contentful')
                  ? Button.themes.spinner
                  : Button.themes.normal
              }
            />
            <Button
              name="job"
              text="Contentful Assets"
              type="submit"
              value="cache-assets"
              variant={Button.variants.secondary}
              disabled={jobRunning()}
              theme={
                isRunning('cache-assets')
                  ? Button.themes.spinner
                  : Button.themes.normal
              }
            />
            <Button
              name="job"
              text="Features"
              type="submit"
              value="cache-features"
              variant={Button.variants.secondary}
              disabled={jobRunning()}
              theme={
                isRunning('cache-features')
                  ? Button.themes.spinner
                  : Button.themes.normal
              }
            />
            <Button
              name="job"
              text="Redirects"
              type="submit"
              value="cache-redirects"
              variant={Button.variants.secondary}
              disabled={jobRunning()}
              theme={
                isRunning('cache-redirects')
                  ? Button.themes.spinner
                  : Button.themes.normal
              }
            />
            <Button
              name="job"
              text="SATS (Service Platform)"
              type="submit"
              value="cache-platform"
              variant={Button.variants.secondary}
              disabled={jobRunning()}
              theme={
                isRunning('cache-platform')
                  ? Button.themes.spinner
                  : Button.themes.normal
              }
            />
            <Button
              name="job"
              text="Screen9"
              type="submit"
              value="cache-screen-nine"
              variant={Button.variants.secondary}
              disabled={jobRunning()}
              theme={
                isRunning('cache-screen-nine')
                  ? Button.themes.spinner
                  : Button.themes.normal
              }
            />
          </div>
          <Text
            className="web-admin-data__title"
            elementName="h2"
            size={Text.sizes.headline2}
            theme={Text.themes.emphasis}
          >
            Build Internal Data
          </Text>
          <div className="web-admin-data__actions">
            <Button
              name="job"
              text="Danish"
              type="submit"
              value="build-da"
              variant={Button.variants.secondary}
              disabled={jobRunning()}
              theme={
                isRunning('build-da')
                  ? Button.themes.spinner
                  : Button.themes.normal
              }
            />
            <Button
              name="job"
              text="Finnish"
              type="submit"
              value="build-fi"
              variant={Button.variants.secondary}
              disabled={jobRunning()}
              theme={
                isRunning('build-fi')
                  ? Button.themes.spinner
                  : Button.themes.normal
              }
            />
            <Button
              name="job"
              text="Norwegian"
              type="submit"
              value="build-no"
              variant={Button.variants.secondary}
              disabled={jobRunning()}
              theme={
                isRunning('build-no')
                  ? Button.themes.spinner
                  : Button.themes.normal
              }
            />
            <Button
              name="job"
              text="Swedish"
              type="submit"
              value="build-sv"
              variant={Button.variants.secondary}
              disabled={jobRunning()}
              theme={
                isRunning('build-sv')
                  ? Button.themes.spinner
                  : Button.themes.normal
              }
            />
          </div>
          <Text
            className="web-admin-data__title"
            elementName="h2"
            size={Text.sizes.headline2}
            theme={Text.themes.emphasis}
          >
            Refresh Data
          </Text>
          <div className="web-admin-data__actions">
            <Button
              name="job"
              text="Run all data functions"
              type="submit"
              value="refresh"
              disabled={jobRunning()}
              theme={
                isRunning('refresh')
                  ? Button.themes.spinner
                  : Button.themes.normal
              }
            />
          </div>
        </form>

        <div>
          <Text
            className="web-admin-data__title"
            elementName="h2"
            size={Text.sizes.headline2}
            theme={Text.themes.emphasis}
          >
            Data Status Log
          </Text>
          <div className="web-admin-data__status-log">
            {Object.keys(statusLog).length !== 0 ? (
              Object.entries(statusLog)
                .reverse()
                .map(([key, value]) => <Message key={key} {...value.message} />)
            ) : (
              <Message text="No log entries (yet)" />
            )}
          </div>
        </div>
      </div>
    </WebAdminLayout>
  );
};

export default WebAdminData;
