import React, { useState } from 'react';

import Button from '@sats-group/ui-lib/react/button';
import HiddenInput from '@sats-group/ui-lib/react/hidden-input';
import LinkButton from '@sats-group/ui-lib/react/link-button';
import Text from '@sats-group/ui-lib/react/text';
import TextInput from '@sats-group/ui-lib/react/text-input';

import ContentContainer from 'components/content-container/content-container';
import MyPageLayout from 'components/my-page-layout/my-page-layout';
import Spinner from 'components/spinner/spinner';

import type { BsAutomatiskBetalingPage as Props } from './bs-automatisk-betaling-page.types';

const BsAutomatiskBetalingPage: React.FC<Props> = ({
  cancel,
  disclaimer,
  form,
  help,
  myPageLayout,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    if (isSubmitting) {
      event.preventDefault();
    }

    setIsSubmitting(true);
  };

  return (
    <MyPageLayout {...myPageLayout}>
      <ContentContainer>
        {isSubmitting ? <Spinner theme={Spinner.themes.overlay} /> : null}
        <form
          action={form.action}
          className="bs-automatisk-betaling-page"
          method="post"
          onSubmit={handleSubmit}
        >
          <Text>{help}</Text>
          {form.hiddenInputs.map(input => (
            <HiddenInput {...input} key={input.name} />
          ))}
          <TextInput {...form.input} />
          <Text>{disclaimer}</Text>
          <div className="bs-automatisk-betaling-page__actions">
            <LinkButton {...cancel} variant={LinkButton.variants.secondary} />
            <Button text={form.submit} type="submit" />
          </div>
        </form>
      </ContentContainer>
    </MyPageLayout>
  );
};

export default BsAutomatiskBetalingPage;
