import React from 'react';

import Link from '@sats-group/ui-lib/react/link';

import type { PaymentReceiptGroup as Props } from './payment-receipt-group.types';
import PaymentReceiptRow from './payment-receipt-row';

const PaymentReceiptGroup: React.FunctionComponent<Props> = ({
  payments,
  yearlyReceipt,
}) => (
  <div className="payment-receipt-group">
    <div>
      {yearlyReceipt ? (
        <div className="payment-receipt-group__yearly-receipt">
          <Link
            {...yearlyReceipt}
            data-testid="yearly-receipt"
            rel="noopener noreferrer"
            target="_blank"
          />
        </div>
      ) : null}
      <ul className="payment-receipt-group__list">
        {payments.map(row => (
          <PaymentReceiptRow {...row} key={row.link.href} />
        ))}
      </ul>
    </div>
  </div>
);

export default PaymentReceiptGroup;
