import React from 'react';

import Text from '@sats-group/ui-lib/react/text';

import type { LineItems as Props } from './line-items.types';

const LineItems: React.FunctionComponent<Props> = ({ items, title }) =>
  items.length ? (
    <div className="line-items">
      {title ? (
        <div className="line-items__title">
          <Text size={Text.sizes.small} theme={Text.themes.emphasis}>
            {title}
          </Text>
        </div>
      ) : null}
      <dl className="line-items__items">
        {items.map(({ emphasizedValue, emphasizedName, name, value }) => (
          <div key={name}>
            <Text
              elementName="dt"
              size={Text.sizes.small}
              theme={emphasizedName ? Text.themes.emphasis : undefined}
            >
              {name}
            </Text>
            <Text
              elementName="dd"
              size={emphasizedValue ? Text.sizes.headline3 : Text.sizes.small}
              theme={emphasizedValue ? Text.themes.emphasis : undefined}
            >
              {value}
            </Text>
          </div>
        ))}
      </dl>
    </div>
  ) : null;

export default LineItems;
