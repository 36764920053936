import Check from '@sats-group/icons/24/check';
import React from 'react';

import Banner from '@sats-group/ui-lib/react/banner';
import Text from '@sats-group/ui-lib/react/text';

import ArrowLinkGroup from 'components/arrow-link-group/arrow-link-group';
import ContentContainer from 'components/content-container/content-container';
import MyPageLayout from 'components/my-page-layout/my-page-layout';

import type { FreezeSelectReasonPage as Props } from './freeze-select-reason-page.types';

const FreezeSelectReasonPage: React.FunctionComponent<Props> = ({
  myPageLayout,
  overviewTitle,
  freezeInformation,
  freezeReasons,
  nudge,
}) => (
  <MyPageLayout {...myPageLayout}>
    <div className="freeze-select-reason-page">
      <ContentContainer>
        <ul className="freeze-select-reason-page__information-container">
          {freezeInformation.map(entry => (
            <li key={entry}>
              <Check />
              <Text size={Text.sizes.basic}>{entry}</Text>
            </li>
          ))}
        </ul>
        <Text
          className="freeze-select-reason-page__overview-title"
          size={Text.sizes.headline3}
          theme={Text.themes.emphasis}
          elementName="h2"
        >
          {overviewTitle}
        </Text>

        {freezeReasons.map(entry => (
          <div className="freeze-select-reason-page__reasons" key={entry.title}>
            <Text
              className="freeze-select-reason-page__reason-group-title"
              size={Text.sizes.large}
              theme={Text.themes.emphasis}
            >
              {entry.title}
            </Text>
            {entry.text && entry.text}
            <div className="freeze-select-reason-page__reason-link-container">
              <ArrowLinkGroup {...entry.reasons} />
            </div>
          </div>
        ))}
        {nudge ? <Banner {...nudge} /> : null}
      </ContentContainer>
    </div>
  </MyPageLayout>
);

export default FreezeSelectReasonPage;
