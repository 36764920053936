import ArrowRight from '@sats-group/icons/24/arrow-right';
import cn from 'classnames';
import React from 'react';

import Text from '@sats-group/ui-lib/react/text';

import type { ArrowLink as Props } from './arrow-link.types';

const ArrowLink: React.FunctionComponent<Props> = ({
  data,
  description,
  groupPosition,
  heading,
  href,
  secondary,
  small,
  testId,
  disabled,
}) =>
  React.createElement(
    disabled ? 'div' : 'a',
    {
      'aria-disabled': disabled,
      className: cn('arrow-link', {
        [`arrow-link--${groupPosition}`]: groupPosition,
        'arrow-link--secondary': secondary,
        'arrow-link--small': small,
        'arrow-link--disabled': disabled,
      }),
      href: disabled ? undefined : href,
      'data-testid': testId,
    },
    <React.Fragment>
      <div className="arrow-link__text">
        <div className="arrow-link__heading">
          <Text theme={small ? undefined : Text.themes.emphasis}>
            {heading}
          </Text>
        </div>
        {small ? null : (
          <React.Fragment>
            {data ? (
              <div className="arrow-link__data">
                <Text size={Text.sizes.small}>{data}</Text>
              </div>
            ) : null}
            {description ? (
              <div className="arrow-link__description">
                <Text size={Text.sizes.small}>{description}</Text>
              </div>
            ) : null}
          </React.Fragment>
        )}
      </div>
      {disabled ? null : (
        <div>
          <ArrowRight />
        </div>
      )}
    </React.Fragment>
  );

export default ArrowLink;
