import React, { useState } from 'react';

import Button from '@sats-group/ui-lib/react/button';
import Checkbox from '@sats-group/ui-lib/react/checkbox';
import HiddenInput from '@sats-group/ui-lib/react/hidden-input';
import LinkButton from '@sats-group/ui-lib/react/link-button';
import Select from '@sats-group/ui-lib/react/select';
import TextInput from '@sats-group/ui-lib/react/text-input';

import ContentContainer from 'components/content-container/content-container';
import MyPageLayout from 'components/my-page-layout/my-page-layout';
import Spinner from 'components/spinner/spinner';

import type { AutogiroBanksPage as Props } from './autogiro-banks-page.types';

const AutogiroBanksPage: React.FunctionComponent<Props> = ({
  action,
  banks,
  exit,
  hiddenInputs,
  myPageLayout,
  otherDevice,
  ssn,
  submit,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    if (isSubmitting) {
      event.preventDefault();
    }

    setIsSubmitting(true);
  };

  return (
    <MyPageLayout {...myPageLayout}>
      {isSubmitting ? <Spinner theme={Spinner.themes.overlay} /> : null}
      <ContentContainer>
        <div className="autogiro-banks-page">
          <form
            action={action}
            className="autogiro-banks-page__form"
            method="post"
            onSubmit={handleSubmit}
          >
            {banks.options && banks.options.length ? (
              <React.Fragment>
                {hiddenInputs.map(input => (
                  <HiddenInput {...input} key={input.name} />
                ))}
                <Select {...banks} required />
                <Checkbox {...otherDevice} />
                <TextInput
                  {...ssn}
                  data-bad-test-number="19700123-9297"
                  data-good-test-number="19800113-9297"
                  required
                />
                <div className="autogiro-banks-page__actions">
                  <LinkButton
                    {...exit}
                    variant={LinkButton.variants.secondary}
                  />
                  <Button text={submit} type="submit" />
                </div>
              </React.Fragment>
            ) : (
              <div className="autogiro-banks-page__actions">
                <LinkButton {...exit} variant={LinkButton.variants.secondary} />
              </div>
            )}
          </form>
        </div>
      </ContentContainer>
    </MyPageLayout>
  );
};

export default AutogiroBanksPage;
