import cn from 'classnames';
import React from 'react';

import Text from '@sats-group/ui-lib/react/text';

import type { NavigationCard as Props } from './navigation-card.types';

const NavigationCard: React.FunctionComponent<Props> = ({
  data,
  description,
  errors,
  href,
  rawIcon,
  tall,
  title,
}) =>
  React.createElement(
    href ? 'a' : 'div',
    {
      className: cn(
        'navigation-card',
        href ? 'navigation-card--enabled' : 'navigation-card--disabled',
        { 'navigation-card--tall': tall }
      ),
      href,
    },
    <React.Fragment>
      <div
        className="navigation-card__icon"
        dangerouslySetInnerHTML={{ __html: rawIcon }}
      />
      <div className="navigation-card__text">
        <div className="navigation-card__title">
          <Text theme={Text.themes.emphasis} tight>
            {title}
          </Text>
        </div>
        <div className="navigation-card__text-group">
          {data.map(d => (
            <div className="navigation-card__data" key={d}>
              <Text size={Text.sizes.small}>{d}</Text>
            </div>
          ))}
          {errors.map(e => (
            <div className="navigation-card__error" key={e}>
              <Text size={Text.sizes.small}>{e}</Text>
            </div>
          ))}
        </div>
        {description ? (
          <div className="navigation-card__description">
            <Text size={Text.sizes.small}>{description}</Text>
          </div>
        ) : null}
      </div>
    </React.Fragment>
  );

export default NavigationCard;
