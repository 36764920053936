import Check from '@sats-group/icons/16/check';
import React from 'react';

import LinkButton from '@sats-group/ui-lib/react/link-button';
import Text from '@sats-group/ui-lib/react/text';
import { sizes } from '@sats-group/ui-lib/react/visually-button/visually-button.types';

import type { SelectedClubCard as Props } from './selected-club-card.types';

const SelectedClubCard: React.FunctionComponent<Props> = ({
  address,
  id,
  linkButton,
  title,
}) => (
  <div className="selected-club-card" data-id={id}>
    <div className="selected-club-card__checkmark">
      <Check />
    </div>

    <div className="selected-club-card__content">
      <div className="selected-club-card__club-info">
        <Text
          className="selected-club-card__title"
          elementName="h2"
          size={Text.sizes.headline3}
        >
          {title}
        </Text>

        <div className="selected-club-card__address">{address}</div>
      </div>

      <div className="selected-club-card__link-button">
        <LinkButton {...linkButton} size={sizes.small} />
      </div>
    </div>
  </div>
);

export default SelectedClubCard;
