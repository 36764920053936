import Prformance from '@sats-group/icons/24/prformance';
import React from 'react';

import Banner from '@sats-group/ui-lib/react/banner';
import Text from '@sats-group/ui-lib/react/text';

import type { MissedClassesCard as Props } from './missed-classes-card.types';

const MissedClassesCard: React.FunctionComponent<Props> = ({
  banner,
  list,
  message,
  title,
}) => (
  <div className="missed-classes-card">
    <div className="missed-classes-card__title">
      <Text
        elementName="h2"
        size={Text.sizes.large}
        theme={Text.themes.emphasis}
      >
        {title}
      </Text>
    </div>
    {banner ? <Banner {...banner} icon nested /> : null}
    {list.length ? (
      <ul className="missed-classes-card__list">
        {list.map(entry => (
          <li
            className="missed-classes-card__entry"
            data-key={entry.key}
            key={entry.key}
          >
            <div>
              <Text theme={Text.themes.emphasis}>{entry.date}</Text>
              <Text>{entry.time}</Text>
              <Text>{entry.duration}</Text>
            </div>
            <div>
              <Text theme={Text.themes.emphasis}>{entry.class}</Text>
              <Text>{entry.instructor}</Text>
              <Text>{entry.club}</Text>
            </div>
          </li>
        ))}
      </ul>
    ) : null}
    {message ? (
      <div className="missed-classes-card__message">
        <div className="missed-classes-card__message-content">
          <Prformance />
          <Text>{message}</Text>
        </div>
      </div>
    ) : null}
  </div>
);

export default MissedClassesCard;
