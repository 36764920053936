import React, { useState } from 'react';

import Button from '@sats-group/ui-lib/react/button';
import HiddenInput from '@sats-group/ui-lib/react/hidden-input';
import LinkButton from '@sats-group/ui-lib/react/link-button';
import Text from '@sats-group/ui-lib/react/text';
import TextInput from '@sats-group/ui-lib/react/text-input';

import Spinner from 'client/components/spinner/spinner';
import ContentContainer from 'components/content-container/content-container';
import MyPageLayout from 'components/my-page-layout/my-page-layout';

import type { MyProfileEditPage as Props } from './my-profile-edit-page.types';

const MyProfileEditPage: React.FunctionComponent<Props> = ({
  cancel,
  contactInput,
  description,
  form,
  myPageLayout,
  postalCode,
  save,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
    if (isLoading) {
      e.preventDefault();
    }

    setIsLoading(true);
  };
  return (
    <MyPageLayout {...myPageLayout}>
      <ContentContainer>
        <div className="my-profile-edit-page">
          <div className="my-profile-edit-page__description">
            <Text>{description}</Text>
          </div>
          <ContentContainer
            theme={ContentContainer.themes.veryNarrow}
            className="my-profile-edit-page__form-container"
          >
            <form
              action={form.action}
              className="my-profile-edit-page__form"
              method="POST"
              onSubmit={handleSubmit}
            >
              {isLoading ? <Spinner theme={Spinner.themes.centered} /> : null}
              {form.hiddenInputs.map(input => (
                <HiddenInput key={input.name} {...input} />
              ))}
              <TextInput {...contactInput} />
              {postalCode ? (
                <div className="my-profile-edit-page__postal-input">
                  <TextInput {...postalCode} />
                </div>
              ) : null}
              <div className="my-profile-edit-page__button-container">
                <LinkButton
                  variant={Button.variants.secondary}
                  {...cancel}
                  wide={true}
                />
                <Button {...save} type="submit" wide={true} />
              </div>
            </form>
          </ContentContainer>
        </div>
      </ContentContainer>
    </MyPageLayout>
  );
};

export default MyProfileEditPage;
