import SvgArrowDown from '@sats-group/icons/24/arrow-down';
import cn from 'classnames';
import React, { useState } from 'react';
import Collapse from 'react-tiny-collapse';

import Message from '@sats-group/ui-lib/react/message';
import Text from '@sats-group/ui-lib/react/text';

import type { ContactUsItem as Props } from './contact-us-item.types';

const ContactUsItem: React.FunctionComponent<Props> = ({
  actions,
  defaultOpen,
  icon,
  id,
  message,
  text,
  title,
}) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  return (
    <div className="contact-us__item">
      <button
        aria-controls={id}
        aria-expanded={isOpen}
        onClick={() => setIsOpen(isOpen => !isOpen)}
        className={cn('contact-us__item-trigger', {
          'contact-us__item-trigger--open': isOpen,
        })}
      >
        <Text
          className="contact-us__item-title"
          elementName="h3"
          theme={Text.themes.emphasis}
          size={Text.sizes.small}
        >
          <span>{icon}</span>
          {title}
        </Text>

        <div
          className={cn('contact-us__item-arrow', {
            'contact-us__item-arrow--flipped': isOpen,
          })}
        >
          <SvgArrowDown />
        </div>
      </button>

      <Collapse componentProps={{ id }} isOpen={isOpen}>
        <div className="contact-us__item-content">
          {text ? <div className="contact-us__item-text">{text}</div> : null}
          {actions ? (
            <div className="contact-us__item-actions">{actions}</div>
          ) : null}
          {message ? (
            <div className="contact-us__item-message">
              <Message {...message} inline />
            </div>
          ) : null}
        </div>
      </Collapse>
    </div>
  );
};

export default ContactUsItem;
