import React from 'react';

import Text from '@sats-group/ui-lib/react/text';

import List from 'components/list/list';

import type { PaymentSummary as Props } from './payment-page.types';

const PaymentSummary: React.FunctionComponent<Props> = ({
  errorMessage,
  lines = [],
  title,
  text,
}) => (
  <div>
    {!lines.length ? (
      <Text
        className="payment-page__payment-summary-error"
        size={Text.sizes.small}
      >
        {errorMessage}
      </Text>
    ) : null}

    <Text
      className="payment-page__payment-summary-title"
      elementName="h2"
      theme={Text.themes.normal}
      size={Text.sizes.headline3}
    >
      {title}
    </Text>

    {lines.length ? (
      <React.Fragment>
        {text ? <Text size={Text.sizes.small}>{text}</Text> : null}

        <List
          className="payment-page__payment-summary-lines"
          theme={List.themes.marginMedium}
        >
          {lines.map(({ dates, isImportant, name, price }) => (
            <div className="payment-page__payment-summary-line" key={name}>
              <Text
                size={Text.sizes.small}
                theme={isImportant ? Text.themes.emphasis : Text.themes.normal}
              >
                {name}
                {dates ? <div>{dates}</div> : null}
              </Text>
              <Text
                className="payment-page__payment-summary-price"
                size={Text.sizes.small}
                theme={Text.themes.emphasis}
              >
                {price}
              </Text>
            </div>
          ))}
        </List>
      </React.Fragment>
    ) : null}
  </div>
);

export default PaymentSummary;
