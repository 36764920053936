import type { InputHTMLAttributes } from 'react';
import React from 'react';

import Radio from '@sats-group/ui-lib/react/radio';

import { mod } from 'client/helpers/add-bem-modifiers';
import type { ThemedComponent } from 'client/types';

import type { RadioList as Props } from './radio-list.types';
import { themes } from './radio-list.types';

type RestProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'color' | 'value'>;

const RadioList: ThemedComponent<RestProps & Props, typeof themes> = ({
  defaultCheckedValue,
  label,
  options,
  showOnlyOneCustomError,
  theme,
  ...rest
}) => (
  <fieldset className={mod('radio-list', theme)}>
    <div>
      <legend>{label}</legend>
    </div>
    <div className="radio-list__radios">
      {options.map((option, index) => (
        <Radio
          defaultChecked={defaultCheckedValue === option.value}
          key={option.value}
          showCustomError={
            showOnlyOneCustomError && index + 1 < options.length ? false : true
          }
          {...option}
          {...rest}
        />
      ))}
    </div>
  </fieldset>
);

RadioList.themes = themes;

export default RadioList;
