import React from 'react';

import useToggle from '@sats-group/ui-lib/react/hooks/use-toggle';
import { useSalesforceMessaging } from '../../hooks/use-salesforce-messaging';
import type { SalesforceMessagingTrigger as Props } from './salesforce-messaging-trigger.types';

const SalesforceMessagingTrigger: React.FunctionComponent<Props> = ({
  config,
}) => {
  const [, , enableTrigger] = useToggle(false);

  useSalesforceMessaging(
    config.esw,
    config.init,
    config.settings,
    config.prechat,
    enableTrigger
  );

  return <div className="salesforce-messaging-trigger"></div>;
};

export default SalesforceMessagingTrigger;
