import React from 'react';

import Text from '@sats-group/ui-lib/react/text';

import Logo from '../logo/logo';

import type { TableOfContents as Props } from './table-of-contents.types';

const TableOfContents: React.FunctionComponent<Props> = ({
  logo,
  sections,
}) => (
  <div className="table-of-contents">
    <div>
      <Logo {...logo} />
    </div>
    {sections.map(section => (
      <div className="table-of-contents__section" key={section.title}>
        <Text
          elementName="h2"
          size={Text.sizes.small}
          theme={Text.themes.emphasis}
        >
          {section.title}
        </Text>
        <ul className="table-of-contents__links">
          {section.links.map(link => (
            <li key={link.href}>
              <Text elementName="span" size={Text.sizes.small}>
                <a className="table-of-contents__link" href={link.href}>
                  {link.text}
                </a>
              </Text>
            </li>
          ))}
        </ul>
      </div>
    ))}
  </div>
);

export default TableOfContents;
