import React from 'react';

import LinkButton from '@sats-group/ui-lib/react/link-button';

import ContentContainer from '../content-container/content-container';
import Logo from '../logo/logo';

import type { MyPageMenu as Props } from './my-page-menu.types';

const MyPageMenu: React.FunctionComponent<Props> = ({ actions, logo }) => (
  <div className="my-page-menu">
    <ContentContainer>
      <div className="my-page-menu__content">
        <div className="my-page-menu__logo">
          <Logo {...logo} />
        </div>
        {actions.length ? (
          <div className="my-page-menu__actions">
            {actions.map(action => (
              <div key={action.href}>
                <LinkButton
                  {...action}
                  size={LinkButton.sizes.small}
                  variant={LinkButton.variants.ctaSecondaryWhite}
                />
              </div>
            ))}
          </div>
        ) : null}
      </div>
    </ContentContainer>
  </div>
);

export default MyPageMenu;
