import React from 'react';

import LinkButton from '@sats-group/ui-lib/react/link-button';
import Message from '@sats-group/ui-lib/react/message';
import Text from '@sats-group/ui-lib/react/text';

import ContentContainer from 'components/content-container/content-container';

import type { WebAdminLogin as Props } from './web-admin-login.types';

const WebAdminLogin: React.FunctionComponent<Props> = ({
  actions,
  error,
  title,
}) => (
  <div className="web-admin-login">
    <ContentContainer
      className="web-admin-login__content"
      theme={ContentContainer.themes.narrow}
    >
      <Text
        elementName="h1"
        italic
        size={Text.sizes.headline1}
        theme={Text.themes.headline}
      >
        {title}
      </Text>
      {error ? (
        <div>
          <Message inline text={error} theme={Message.themes.error} />
        </div>
      ) : null}
      <div className="web-admin-login__actions">
        {actions.map(action => (
          <div key={action.href}>
            <LinkButton {...action} />
          </div>
        ))}
      </div>
    </ContentContainer>
  </div>
);

export default WebAdminLogin;
