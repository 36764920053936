import React from 'react';

import Text from '@sats-group/ui-lib/react/text';

import DynamicImage from 'components/dynamic-image/dynamic-image';

import type { CommentCard as Props } from './comment-card.types';

const CommentCard: React.FunctionComponent<Props> = ({
  image,
  text,
  title,
}) => (
  <div className="comment-card">
    <div className="comment-card__image">
      {image ? (
        <DynamicImage
          aspectRatio={DynamicImage.aspectRatios.square}
          {...image}
        />
      ) : null}
    </div>
    <Text
      className="comment-card__title"
      elementName="h2"
      size={Text.sizes.large}
      theme={Text.themes.emphasis}
    >
      {title}
    </Text>
    <Text className="comment-card__text" size={Text.sizes.small}>
      {text}
    </Text>
  </div>
);

export default CommentCard;
