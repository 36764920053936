import React, { useState } from 'react';

import Select from '@sats-group/ui-lib/react/select';
import TextInput from '@sats-group/ui-lib/react/text-input';

import type { CountryCodeSelect as Props } from './country-code-select.types';

const CountryCodeSelect: React.FunctionComponent<Props> = ({
  initialSelect = '',
  initialSelectPattern = '',
  input,
  prefixSelect,
  prefixSelectLabel,
}) => {
  const [phonePattern, setPhonePattern] = useState(initialSelectPattern);
  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    event.preventDefault();
    const pattern =
      event.target[event.target.selectedIndex]?.getAttribute('pattern');
    setPhonePattern(pattern ? pattern : initialSelectPattern);
  };
  return (
    <div className="country-code-select">
      <Select
        isLabelVisible={false}
        label={prefixSelectLabel}
        {...prefixSelect}
        className="country-code-select__prefix"
        defaultValue={initialSelect}
        onChange={handleChange}
      />

      <div className="country-code-select__input">
        <TextInput {...input} pattern={phonePattern} type="tel" />
      </div>
    </div>
  );
};

export default CountryCodeSelect;
