import cn from 'classnames';
import React from 'react';

import DynamicMessage from 'components/dynamic-message/dynamic-message';
import Footer from 'components/footer/footer';
import Menu from 'components/menu/menu';

import type { Layout as Props } from './layout.types';
import Breadcrumbs from '../breadcrumbs/breadcrumbs';

const Layout: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  footer,
  breadcrumbs,
  menu,
  message,
}) => (
  <React.Fragment>
    <DynamicMessage {...message} />
    <div
      className={cn('layout', {
        'layout--footer': footer,
      })}
    >
      <header className="layout__header">
        <Menu {...menu} />
      </header>
      {breadcrumbs ? <Breadcrumbs {...breadcrumbs} /> : null}
      <main className="layout__main">{children}</main>
      {footer ? <Footer {...footer} /> : null}
    </div>
  </React.Fragment>
);

export default Layout;
