import React from 'react';

import Expander from '@sats-group/ui-lib/react/expander';
import Link from '@sats-group/ui-lib/react/link';
import Message from '@sats-group/ui-lib/react/message';
import Text from '@sats-group/ui-lib/react/text';

import ArrowLinkGroup from 'components/arrow-link-group/arrow-link-group';
import Breadcrumbs from 'components/breadcrumbs/breadcrumbs';
import ContentContainer from 'components/content-container/content-container';
import Layout from 'components/layout/layout';
import RichText from 'components/rich-text/rich-text';

import ContactOption from '../../components/contact-option/contact-option';

import type { ContactFormStep as Props } from './contact-form-step.types';

const ContactFormStep: React.FunctionComponent<Props> = ({
  breadcrumbs,
  form,
  forwardNavigation,
  highlights,
  layout,
  link,
  messages,
  title,
}) => (
  <Layout {...layout}>
    <div className="contact-form-step">
      <Breadcrumbs {...breadcrumbs} />
      <div className="contact-form-step__section">
        <ContentContainer theme={ContentContainer.themes.narrow}>
          <Text
            elementName="h1"
            theme={Text.themes.emphasis}
            size={Text.sizes.headline1}
          >
            {title}
          </Text>
          {messages.length ? (
            <div className="contact-form-step__messages">
              {messages.map(message => (
                <Message {...message} key={message.text} />
              ))}
            </div>
          ) : null}
          {forwardNavigation ? (
            <div className="contact-form-step__forward-navigation">
              <Text
                elementName="h2"
                theme={Text.themes.emphasis}
                size={Text.sizes.headline3}
              >
                {forwardNavigation.title}
              </Text>
              <Text
                elementName="p"
                className="contact-form-step__description"
                size={Text.sizes.small}
              >
                {forwardNavigation.description}
              </Text>
              <ArrowLinkGroup {...forwardNavigation.links} />
            </div>
          ) : null}
        </ContentContainer>
      </div>
      {highlights || link ? (
        <div className="contact-form-step__section--light">
          <ContentContainer theme={ContentContainer.themes.narrow}>
            {highlights ? (
              <div>
                <Expander {...highlights} itemRenderer={RichText} />
                {link ? (
                  <div className="contact-form-step__link">
                    <Link {...link} />
                  </div>
                ) : null}
              </div>
            ) : link ? (
              <div className="contact-form-step__link">
                <Link {...link} />
              </div>
            ) : null}
          </ContentContainer>
        </div>
      ) : null}

      {form ? (
        <div className="contact-form-step__section">
          <ContentContainer theme={ContentContainer.themes.narrow}>
            <ContactOption {...form} />
          </ContentContainer>
        </div>
      ) : null}
    </div>
  </Layout>
);

export default ContactFormStep;
