import React, { useState } from 'react';

import Button from '@sats-group/ui-lib/react/button';
import Checkbox from '@sats-group/ui-lib/react/checkbox';
import useEscape from '@sats-group/ui-lib/react/hooks/use-escape';
import HiddenInput from '@sats-group/ui-lib/react/hidden-input';
import LinkButton from '@sats-group/ui-lib/react/link-button';
import Message from '@sats-group/ui-lib/react/message';
import Modal from '@sats-group/ui-lib/react/modal';
import Radio from '@sats-group/ui-lib/react/radio';
import Text from '@sats-group/ui-lib/react/text';

import ContentContainer from 'components/content-container/content-container';
import ElementInterpolator from 'components/element-interpolator/element-interpolator';
import List from 'components/list/list';
import Payment from 'components/payment/payment';
import PersonalTrainerSessionShopLayout from 'components/personal-trainer-session-shop-layout/personal-trainer-session-shop-layout';
import Price from 'client/components/price/price';
import RichText from 'components/rich-text/rich-text';
import Spinner from 'components/spinner/spinner';

import PaymentSection from './payment-section';
import type { PersonalTrainerSessionPaymentPage as Props } from './personal-trainer-session-payment-page.types';

const PersonalTrainerSessionPaymentPage: React.FunctionComponent<Props> = ({
  awaitingTermsAccept,
  discountTexts,
  editProducts,
  layout,
  messages,
  paymentSections,
  payByCard,
  payByCardSectionId,
  paymentMethods,
  products = [],
  productsDisclaimer,
  productsTitle,
  purchaseTitle,
  splitPaymentForm,
  splitPaymentInformation,
  successRedirectUrl,
  sum,
  sumLabel,
  terms,
}) => {
  const [termsModalIsOpen, setTermsModalIsOpen] = useState(false);
  const [hasAcceptedTerms, setHasAcceptedTerms] = useState(false);
  const [currentlyExpandedSection, setCurrentlyExpandedSection] = useState<
    string | undefined
  >();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    if (isSubmitting) {
      event.preventDefault();
    }

    setIsSubmitting(true);
  };

  const handlePaymentComplete = () =>
    window.location.assign(successRedirectUrl);

  const handleTermsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setHasAcceptedTerms(e.target.checked);

    if (paymentSections.length === 1 && e.target.checked) {
      setCurrentlyExpandedSection(paymentSections[0]?.id);
    } else {
      setCurrentlyExpandedSection(undefined);
    }
  };

  const handleExpandSection = (id?: string) => setCurrentlyExpandedSection(id);

  useEscape(() => setTermsModalIsOpen(false));

  return (
    <PersonalTrainerSessionShopLayout {...layout}>
      {isSubmitting ? <Spinner theme={Spinner.themes.overlay} /> : null}
      <div className="personal-trainer-session-payment-page">
        <ContentContainer theme={ContentContainer.themes.narrow}>
          {messages.length ? (
            <div className="personal-trainer-session-payment-page__messages">
              {messages.map(message => (
                <Message key={message.text} {...message} />
              ))}
            </div>
          ) : null}
          <Text
            className="personal-trainer-session-payment-page__section-heading"
            elementName="h2"
            size={Text.sizes.headline3}
          >
            {purchaseTitle}
          </Text>
          <div className="personal-trainer-session-payment-page__section">
            <div className="personal-trainer-session-payment-page__section-inner">
              <div className="personal-trainer-session-payment-page__products-wrapper">
                <div>
                  <Text
                    className="personal-trainer-session-payment-page__products-title"
                    theme={Text.themes.emphasis}
                  >
                    {productsTitle}
                  </Text>
                  <List theme={List.themes.greenCheckmarks}>
                    {products.map(item => (
                      <Text key={item}>{item}</Text>
                    ))}
                  </List>
                </div>
                <LinkButton
                  variant={Button.variants.secondary}
                  size={Button.sizes.small}
                  {...editProducts}
                />
              </div>
              <Text
                className="personal-trainer-session-payment-page__disclaimer"
                size={Text.sizes.small}
              >
                {productsDisclaimer}
              </Text>
            </div>
            {discountTexts ? (
              <div className="personal-trainer-session-payment-page__section-inner">
                {discountTexts.map(discount => (
                  <Text key={discount} size={Text.sizes.small}>
                    {discount}
                  </Text>
                ))}
              </div>
            ) : undefined}
            {sum ? (
              <div className="personal-trainer-session-payment-page__section-inner personal-trainer-session-payment-page__price">
                <Text elementName="span">{sumLabel}</Text> <Price {...sum} />
              </div>
            ) : undefined}
          </div>
          {terms ? (
            <div className="personal-trainer-session-payment-page__section">
              <div className="personal-trainer-session-payment-page__terms-text">
                <Checkbox
                  form="payment"
                  {...terms.checkbox}
                  required
                  onChange={handleTermsChange}
                  data-test-terms
                />
                <Button
                  {...terms.button}
                  variant={Button.variants.secondary}
                  size={Button.sizes.small}
                  onClick={() => setTermsModalIsOpen(true)}
                />
              </div>
              {termsModalIsOpen ? (
                <Modal
                  {...terms.modal}
                  onClose={() => setTermsModalIsOpen(false)}
                >
                  <RichText
                    className="personal-trainer-session-payment-page__terms"
                    {...terms.text}
                  />
                  <div className="personal-trainer-session-payment-page__terms-close">
                    <Button
                      onClick={() => setTermsModalIsOpen(false)}
                      {...terms.close}
                    />
                  </div>
                </Modal>
              ) : null}
            </div>
          ) : undefined}
          <Text
            elementName="h2"
            className="personal-trainer-session-payment-page__payment-section-title"
            size={Text.sizes.headline2}
          >
            {paymentMethods}
          </Text>
          {hasAcceptedTerms || paymentSections.length === 1 ? null : (
            <Text className="personal-trainer-session-payment-page__awaiting-terms">
              {awaitingTermsAccept}
            </Text>
          )}
          {paymentSections.length > 1 ? (
            <div className="personal-trainer-session-payment-page__payment-sections">
              {paymentSections.map(section => (
                <PaymentSection
                  key={section.id}
                  {...section}
                  onExpandSection={handleExpandSection}
                  isSelected={currentlyExpandedSection === section.id}
                  isDisabled={!hasAcceptedTerms}
                />
              ))}
            </div>
          ) : null}
          {paymentSections.length === 1 && !currentlyExpandedSection ? (
            <div className="personal-trainer-session-payment-page__ghost-payment">
              <div>
                <div className="personal-trainer-session-payment-page__ghost-payment-box"></div>
                <div className="personal-trainer-session-payment-page__ghost-payment-box"></div>
                <div className="personal-trainer-session-payment-page__ghost-payment-box"></div>
              </div>
              <div className="personal-trainer-session-payment-page__ghost-payment-text">
                {awaitingTermsAccept}
              </div>
            </div>
          ) : undefined}
          {currentlyExpandedSection === payByCardSectionId ? (
            <div
              className="personal-trainer-session-payment-page__pay-by-card"
              id={payByCardSectionId}
            >
              <Payment
                {...payByCard}
                onPaymentComplete={handlePaymentComplete}
              />
            </div>
          ) : null}
          {currentlyExpandedSection === splitPaymentForm.id ? (
            <div
              className="personal-trainer-session-payment-page__split-payment"
              id={splitPaymentForm.id}
            >
              <Text
                elementName="div"
                className="personal-trainer-session-payment-page__split-payment-information"
              >
                {splitPaymentInformation}
              </Text>
              <form
                action={splitPaymentForm.endpoint}
                method="POST"
                id="payment"
                onSubmit={handleSubmit}
              >
                <fieldset className="personal-trainer-session-payment-page__radio-list">
                  <Text
                    elementName="div"
                    theme={Text.themes.emphasis}
                    className="personal-trainer-session-payment-page__legend"
                  >
                    <legend>{splitPaymentForm.legend}</legend>
                  </Text>
                  {splitPaymentForm.options.map(({ radio, number, price }) => (
                    <div
                      className="personal-trainer-session-payment-page__radio"
                      key={radio.value}
                    >
                      <Radio
                        {...radio}
                        required
                        test-split-payment-options={radio.value}
                      >
                        <ElementInterpolator
                          template={splitPaymentForm.template}
                          elements={{
                            number: (
                              <Text
                                elementName="span"
                                theme={Text.themes.emphasis}
                              >
                                {number}
                              </Text>
                            ),
                            price: (
                              <Text
                                elementName="span"
                                theme={Text.themes.emphasis}
                              >
                                {price}
                              </Text>
                            ),
                          }}
                        />
                      </Radio>
                    </div>
                  ))}
                </fieldset>
                {splitPaymentForm.hiddenInputs.map(input => (
                  <HiddenInput key={input.name} {...input} />
                ))}
                <Button
                  type="submit"
                  text={splitPaymentForm.submitButtonText}
                  test-split-payment-submit="true"
                  wide
                />
              </form>
            </div>
          ) : null}
        </ContentContainer>
      </div>
    </PersonalTrainerSessionShopLayout>
  );
};

export default PersonalTrainerSessionPaymentPage;
