import React, { useEffect, useState } from 'react';

import Text from '@sats-group/ui-lib/react/text';
import TextInput from '@sats-group/ui-lib/react/text-input';

import type { DateInput as Props } from './date-input.types';

/** Strips the time part from a datetime string.
 * Date input attributes `value`, `max` and `min` don't work when the attribute values contains time information */
const stripTime = (dateString?: string) =>
  dateString ? dateString.split('T')[0] : undefined;

const DateInput: React.FunctionComponent<Props> = ({
  defaultDate,
  invalidDateError,
  label,
  maxDate,
  minDate,
  name,
  required,
}) => {
  // NOTE: Most browsers support native date pickers, so we default to `true`.
  const [supportsNativePicker, setSupportsNativePicker] = useState(true);

  useEffect(() => {
    // NOTE: The check accesses the DOM so it needs to happen after mount.
    // Source: https://silvantroxler.ch/2018/browser-detect-builtin-datepicker/
    const input = document.createElement('input');
    input.setAttribute('type', 'date');
    input.value = '2018-01-01';
    setSupportsNativePicker(Boolean(input.valueAsDate));
  }, []);

  const textInputProps = {
    defaultValue: stripTime(defaultDate),
    label,
    name,
    required,
  };

  if (supportsNativePicker) {
    return (
      <div className="date-input">
        <TextInput
          {...textInputProps}
          type="date"
          min={stripTime(minDate)}
          max={stripTime(maxDate)}
        />
      </div>
    );
  }

  // NOTE: This string is hard coded because this is the format that the native date input uses.
  const formattingLabel = 'YYYY-MM-DD';
  const validationPattern = '\\d{4}-\\d{2}-\\d{2}';

  const validateFallbackDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    // NOTE: If the input value is formatted correctly, this function checks whether the value represents a real date or not.
    const value = e.target.value;
    const isMatch = new RegExp(validationPattern).test(value);
    const isValid = !isMatch || !value || !isNaN(new Date(value).getTime());
    e.target?.setCustomValidity(isValid ? '' : invalidDateError);
  };

  return (
    <div className="date-input">
      <TextInput
        {...textInputProps}
        onChange={validateFallbackDate}
        pattern={validationPattern}
        placeholder={formattingLabel}
      >
        <Text className="date-input__format" size={Text.sizes.small}>
          ({formattingLabel})
        </Text>
      </TextInput>
    </div>
  );
};

export default DateInput;
