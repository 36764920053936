import React from 'react';

import Text from '@sats-group/ui-lib/react/text';

import ContentContainer from 'client/components/content-container/content-container';
import DisplayBootCampEvents from 'client/components/display-boot-camp-events/display-boot-camp-events';
import DropdownWrapper from 'client/components/dropdown-wrapper/dropdown-wrapper';
import DynamicImage from 'client/components/dynamic-image/dynamic-image';
import ElementInterpolator from 'client/components/element-interpolator/element-interpolator';
import Layout from 'components/layout/layout';
import ModalSlideshow from 'components/modal-slideshow/modal-slideshow';

import type { BootCampLandingPage as Props } from './boot-camp-landing-page.types';

const BootCampLandingPage: React.FunctionComponent<Props> = ({
  bootCampConcepts,
  dropdownWrapper,
  eventsHeader,
  featured,
  displayBootCampEvents,
  heading,
  description,
  header,
  idToJumpTo,
  layout,
  results,
  title,
  totalBootCampEvents,
}) => (
  <Layout {...layout}>
    <ModalSlideshow {...header} />
    <div className="boot-camp-page__content-wrapper">
      <ContentContainer className="boot-camp-page__header-wrapper">
        <Text
          className="boot-camp-page__header"
          elementName="h1"
          size={Text.sizes.headline2}
          theme={Text.themes.headline}
          italic
        >
          {title}
        </Text>
        {description ? (
          <Text size={Text.sizes.large}>{description}</Text>
        ) : null}
      </ContentContainer>
      <ContentContainer className="boot-camp-page__concept-wrapper">
        <Text
          size={Text.sizes.headline2}
          elementName="h2"
          className="boot-camp-page__concept-heading"
        >
          {heading}
        </Text>
        {featured ? (
          <a href={featured.url} className="boot-camp-page__featured">
            {featured.image ? (
              <DynamicImage
                className="boot-camp-page__featured-image"
                {...featured.image}
              />
            ) : null}
            <div className="boot-camp-page__featured-text">
              <Text
                size={Text.sizes.headline3}
                elementName="h3"
                type={Text.themes.normal}
                italic
              >
                {featured.name}
              </Text>
              {featured.description ? (
                <Text>{featured.description}</Text>
              ) : null}
            </div>
          </a>
        ) : null}
        <div className="boot-camp-page__concepts-wrapper">
          {bootCampConcepts.map(bootCampConcept => (
            <a
              key={bootCampConcept.name}
              href={bootCampConcept.url}
              className="boot-camp-page__concept"
            >
              {bootCampConcept.image ? (
                <DynamicImage
                  className="boot-camp-page__concept-image"
                  {...bootCampConcept.image}
                />
              ) : null}
              <div className="boot-camp-page__concept-texts">
                <Text
                  size={Text.sizes.large}
                  elementName="h3"
                  theme={Text.themes.normal}
                  italic
                >
                  {bootCampConcept.name}
                </Text>
                {bootCampConcept.description ? (
                  <Text
                    size={Text.sizes.small}
                    className="boot-camp-page__concept-description"
                  >
                    {bootCampConcept.description}
                  </Text>
                ) : null}
              </div>
            </a>
          ))}
        </div>
      </ContentContainer>
      <ContentContainer id={idToJumpTo}>
        <Text elementName="h2" size={Text.sizes.headline2}>
          {eventsHeader}
        </Text>
      </ContentContainer>
      <div className="boot-camp-page__dropdown-wrapper">
        <ContentContainer>
          <DropdownWrapper {...dropdownWrapper} />
        </ContentContainer>
      </div>
      <ContentContainer className="boot-camp-page__events">
        <Text size={Text.sizes.basic}>
          <ElementInterpolator
            template={results}
            elements={{
              number: (
                <Text
                  className="boot-camp-page__number"
                  elementName="span"
                  theme={Text.themes.emphasis}
                >
                  {totalBootCampEvents}
                </Text>
              ),
            }}
          />
        </Text>
        <DisplayBootCampEvents {...displayBootCampEvents} />
      </ContentContainer>
    </div>
  </Layout>
);

export default BootCampLandingPage;
