import React from 'react';

import ProgressBar from '@sats-group/ui-lib/react/progress-bar';
import Text from '@sats-group/ui-lib/react/text';

import ContentContainer from 'components/content-container/content-container';
import MyPageLayout from 'components/my-page-layout/my-page-layout';
import RewardCard from 'components/reward-card/reward-card';

import type { RewardsPage as Props } from './rewards-page.types';

const RewardsPage: React.FunctionComponent<Props> = ({
  cards,
  checkinsProgressValues,
  checkinsThreshold,
  currentRewardTitle,
  daysProgressValues,
  daysThreshold,
  info,
  memberLevelInformation,
  myPageLayout,
  overviewTitle,
}) => (
  <MyPageLayout {...myPageLayout}>
    <ContentContainer>
      <div className="rewards-page">
        <Text elementName="p">{info}</Text>
        {memberLevelInformation ? (
          <div className="rewards-page__personal-rewards">
            <Text
              elementName="h2"
              theme={Text.themes.emphasis}
              size={Text.sizes.headline3}
            >
              {currentRewardTitle}
            </Text>
            <div className="rewards-page__progress-container">
              <Text
                className="rewards-page__current-level"
                elementName="h3"
                theme={Text.themes.emphasis}
                size={Text.sizes.large}
              >
                {memberLevelInformation.currentLevel}
              </Text>
              {daysProgressValues ? (
                <div className="rewards-page__progress">
                  <div className="rewards-page__progress-bar">
                    <ProgressBar {...daysProgressValues} />
                  </div>
                </div>
              ) : null}
              {daysThreshold ? (
                <Text className="rewards-page__progress-label" elementName="p">
                  {daysThreshold}
                </Text>
              ) : null}
              {checkinsProgressValues ? (
                <div className="rewards-page__progress">
                  <div className="rewards-page__progress-bar">
                    <ProgressBar {...checkinsProgressValues} />
                  </div>
                </div>
              ) : null}
              {checkinsThreshold ? (
                <Text className="rewards-page__progress-label">
                  {checkinsThreshold}
                </Text>
              ) : null}
              {memberLevelInformation.descriptions.map(text => (
                <Text
                  className="rewards-page__upgrade-text"
                  elementName="p"
                  key={text}
                  size={Text.sizes.small}
                >
                  {text}
                </Text>
              ))}
            </div>
          </div>
        ) : null}
        <Text
          className="rewards-page__types-overview"
          elementName="h2"
          theme={Text.themes.emphasis}
          size={Text.sizes.headline3}
        >
          {overviewTitle}
        </Text>
        {cards.map(card => (
          <RewardCard key={card.level} {...card} />
        ))}
      </div>
    </ContentContainer>
  </MyPageLayout>
);

export default RewardsPage;
