import type { LinkButton } from '@sats-group/ui-lib/react/link-button/link-button.types';

import type { ImageMedia, VideoMedia } from 'shared/media-type-helper';

import type { Tagline } from '../tagline/tagline.types';

export type Hero = {
  media: ImageMedia | VideoMedia;
  variant?: HeroVariant;
  description?: string;
  disclaimer?: string;
  links: LinkButton[];
  pause: string;
  play: string;
  tagline?: Tagline;
  title: string;
};

export enum HeroVariant {
  SplitInfo = 'variant-split-info',
  Clean = 'variant-clean',
  Default = 'variant-default',
}
