import type { TextInput } from '@sats-group/ui-lib/react/text-input/text-input.types';

import type { ObjectValues } from 'client/types';

export const colors = {
  dark: 'dark',
} as const;

export const sizes = {
  fullWidth: 'full-width',
} as const;

export type SearchModal = {
  color?: ObjectValues<typeof colors>;
  dataset?: { href: string; question: string }[];
  searchInput: TextInput;
  searchKeys?: string[];
  size?: ObjectValues<typeof sizes>;
};
