import Avatar from '@sats-group/icons/80/avatar-subtract';
import React from 'react';

import Text from '@sats-group/ui-lib/react/text';

import DynamicImage from 'components/dynamic-image/dynamic-image';

import type { ProfileCard as Props } from './profile-cards.types';

const ProfileCard: React.FunctionComponent<Props> = ({
  name,
  position,
  metadata,
  image,
  list,
  url,
}) => (
  <a href={url} className="profile-cards__card">
    <div className="profile-cards__intro-wrapper">
      <div className="profile-cards__image">
        {image ? (
          <DynamicImage
            {...image}
            aspectRatio={DynamicImage.aspectRatios.square}
            theme={DynamicImage.themes.round}
          />
        ) : (
          <Avatar />
        )}
      </div>
      <div className="profile-cards__description">
        <Text
          elementName="h3"
          size={Text.sizes.headline3}
          theme={Text.themes.emphasis}
        >
          {name}
        </Text>

        <div className="profile-cards__intro">
          {position ? (
            <Text size={Text.sizes.interface}>{position}</Text>
          ) : null}
          <div className="profile-cards__intro-row">
            {metadata.map((item, i) => (
              <React.Fragment key={item}>
                <Text size={Text.sizes.interface}>{item}</Text>
                {i + 1 < metadata.length ? (
                  <div className="profile-cards__dot-gray"></div>
                ) : null}
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
    </div>
    {list?.length ? (
      <div>
        {list.map(item => (
          <div key={item} className="profile-cards__info">
            <div className="profile-cards__dot"></div>
            <Text size={Text.sizes.small}>{item}</Text>
          </div>
        ))}
      </div>
    ) : null}
  </a>
);

export default ProfileCard;
