import cn from 'classnames';
import React from 'react';

import Text from '@sats-group/ui-lib/react/text';

import CommentCard from 'components/comment-card/comment-card';

import type { Testimonials as Props } from './testimonials.types';

const Testimonials: React.FunctionComponent<Props> = ({
  className,
  description,
  header,
  list,
}) => (
  <div className={cn('testimonials', className)}>
    <Text
      className="testimonials__title"
      size={Text.sizes.headline2}
      theme={Text.themes.normal}
      elementName="h2"
    >
      {header}
    </Text>
    <Text
      className="testimonials__description"
      size={Text.sizes.basic}
      theme={Text.themes.normal}
    >
      {description}
    </Text>

    <div className="testimonials__grid">
      {list.map(item => (
        <CommentCard key={item.title} {...item} />
      ))}
    </div>
  </div>
);

export default Testimonials;
