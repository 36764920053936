import cn from 'classnames';
import React from 'react';

import Text from '@sats-group/ui-lib/react/text';

import type { OpeningHours as Props } from './opening-hours.types';

const OpeningHours: React.FunctionComponent<Props> = ({
  irregular,
  regular,
}) => (
  <div className="opening-hours">
    <div className="opening-hours__containers">
      {irregular && irregular.entries.length ? (
        <div className="opening-hours__container">
          <div className="opening-hours__title opening-hours__title--padded">
            <Text elementName="h3" size={Text.sizes.headline3} tight>
              {irregular.title}
            </Text>
          </div>
          <div className="opening-hours__entries">
            {irregular.entries.map(entry => (
              <div
                className="opening-hours__entry"
                data-type={entry.type}
                key={entry.key}
              >
                {entry.type === 'day' ? (
                  entry.value.map(day => (
                    <div
                      key={day.date}
                      className="opening-hours__irregular-day-entry"
                    >
                      <hgroup className="opening-hours__title">
                        <Text
                          elementName="h4"
                          size={Text.sizes.large}
                          theme={Text.themes.emphasis}
                          tight
                        >
                          {day.title}
                        </Text>
                        <Text elementName="p" tight>
                          {day.date}
                        </Text>
                      </hgroup>
                      <div className="opening-hours__irregular-day">
                        {day.facilities.map(facility => (
                          <div
                            key={facility.name}
                            className="opening-hours__irregular-day-facility"
                          >
                            <Text>{facility.name}</Text>
                            <div>
                              {facility.statuses.map(status => (
                                <Text key={status.key}>{status.value}</Text>
                              ))}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="opening-hours__irregular-period-entry">
                    <hgroup className="opening-hours__title">
                      <Text
                        elementName="h4"
                        size={Text.sizes.large}
                        theme={Text.themes.emphasis}
                        tight
                      >
                        {entry.value.name}
                      </Text>
                      <Text elementName="p" tight>
                        {entry.value.timeSpan}
                      </Text>
                    </hgroup>
                    <div className="opening-hours__container">
                      {entry.value.facilities.map(facility => (
                        <div
                          className="opening-hours__facility"
                          key={facility.key}
                        >
                          {facility.name ? (
                            <Text
                              elementName="h5"
                              size={Text.sizes.basic}
                              theme={Text.themes.emphasis}
                              tight
                            >
                              {facility.name}
                            </Text>
                          ) : null}
                          <div>
                            <dl>
                              {facility.days.map(day => (
                                <div key={day.label}>
                                  <dt>
                                    <Text elementName="span">{day.label}</Text>
                                  </dt>
                                  <dd>
                                    {day.statuses.map(entry => (
                                      <div key={entry.key}>
                                        <Text elementName="span">
                                          {entry.value}
                                        </Text>
                                      </div>
                                    ))}
                                  </dd>
                                </div>
                              ))}
                            </dl>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      ) : null}
      {regular.facilities.length ? (
        <div className="opening-hours__container">
          {regular.title ? (
            <div className="opening-hours__title opening-hours__title--padded">
              <Text elementName="h3" size={Text.sizes.headline3} tight>
                {regular.title}
              </Text>
            </div>
          ) : null}
          {regular.facilities.map(facility => (
            <div className="opening-hours__facility" key={facility.key}>
              <div className="opening-hours__title opening-hours__title--padded">
                {facility.name ? (
                  <Text elementName="h4" theme={Text.themes.emphasis} tight>
                    {facility.name}
                  </Text>
                ) : null}
              </div>
              {facility.timeFrames.map((frame, index) => (
                <div
                  className={cn('opening-hours__time-frame', {
                    'opening-hours__time-frame--muted': index % 2,
                  })}
                  key={frame.key}
                >
                  {frame.header ? (
                    <hgroup className="opening-hours__title opening-hours__title--muted">
                      <Text
                        elementName="h5"
                        size={Text.sizes.small}
                        theme={Text.themes.emphasis}
                        tight
                      >
                        {frame.header.title}
                      </Text>
                      {frame.header.description ? (
                        <Text elementName="p" size={Text.sizes.small} tight>
                          {frame.header.description}
                        </Text>
                      ) : null}
                    </hgroup>
                  ) : null}
                  <dl>
                    {frame.days.map(day => (
                      <div key={day.label}>
                        <dt>
                          <Text elementName="span">{day.label}</Text>
                        </dt>
                        <dd>
                          {day.statuses.map(entry => (
                            <div key={entry.key}>
                              <Text elementName="span">{entry.value}</Text>
                            </div>
                          ))}
                        </dd>
                      </div>
                    ))}
                  </dl>
                </div>
              ))}
            </div>
          ))}
        </div>
      ) : null}
    </div>
  </div>
);

export default OpeningHours;
