import React from 'react';

import Link from '@sats-group/ui-lib/react/link';
import LinkButton from '@sats-group/ui-lib/react/link-button';
import Text from '@sats-group/ui-lib/react/text';

import type { DisplayBootCampEvents as Props } from './display-boot-camp-events.types';

const DisplayBootCampEvents: React.FunctionComponent<Props> = ({
  groups: bootCampEvents,
  nullState,
}) => (
  <div className="display-boot-camp-event">
    {bootCampEvents.map(({ events, key, title }) => (
      <div className="display-boot-camp-event__entry" key={key}>
        <Text
          elementName="h3"
          theme={Text.themes.emphasis}
          size={Text.sizes.section}
        >
          {title}
        </Text>
        <div className="display-boot-camp-event__events">
          {events.map(event => (
            <div
              className="display-boot-camp-event__event"
              data-club-id={event.clubId}
              data-concept-id={event.conceptId}
              key={event.id}
            >
              <div className="display-boot-camp-event__event-time">
                <Text theme={Text.themes.emphasis}>{event.time}</Text>
                <div className="display-boot-camp-event__duration">
                  <Text size={Text.sizes.small}>{event.duration}</Text>
                </div>
              </div>

              <div className="display-boot-camp-event__event-main">
                <Text theme={Text.themes.emphasis}>{event.conceptName}</Text>
                <div className="display-boot-camp-event__info">
                  {event.info.map((info, index) =>
                    index ? (
                      <React.Fragment key={info}>
                        <div className="display-boot-camp-event__dot" />
                        <Text key={info} size={Text.sizes.small}>
                          {info}
                        </Text>
                      </React.Fragment>
                    ) : (
                      <Text key={info} size={Text.sizes.small}>
                        {info}
                      </Text>
                    )
                  )}
                </div>
              </div>

              <div className="display-boot-camp-event__event-action">
                <div className="display-boot-camp-event__button-wrapper">
                  <LinkButton
                    {...event.url}
                    size={LinkButton.sizes.small}
                    wide
                  />
                </div>
                <div className="display-boot-camp-event__spots">
                  <Text size={Text.sizes.small}>{event.availableSpots}</Text>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    ))}
    {nullState ? (
      <div className="display-boot-camp-event__no-upcoming">
        <Text>{nullState.title}</Text>
        {nullState.prompt ? (
          <div className="display-boot-camp-event__no-upcoming-prompt">
            <Text theme={Text.themes.emphasis}>{nullState.prompt.title}</Text>
            <Text>{nullState.prompt.text}</Text>
          </div>
        ) : null}
        <Link {...nullState.link} />
      </div>
    ) : null}
  </div>
);

export default DisplayBootCampEvents;
