import React, { useState } from 'react';

import Button from '@sats-group/ui-lib/react/button';
import HiddenInput from '@sats-group/ui-lib/react/hidden-input';
import Message from '@sats-group/ui-lib/react/message';
import Text from '@sats-group/ui-lib/react/text';
import TextInput from '@sats-group/ui-lib/react/text-input';

import ContentContainer from 'components/content-container/content-container';
import Layout from 'components/layout/layout';
import Spinner from 'components/spinner/spinner';

import type { SetPasswordPage as Props } from './set-password-page.types';

const SetPasswordPage: React.FunctionComponent<Props> = ({
  description,
  endpoint,
  hiddenInputs,
  inputs,
  layout,
  messages,
  submit,
  title,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    if (isSubmitting) {
      event.preventDefault();
    }

    setIsSubmitting(true);
  };

  return (
    <Layout {...layout}>
      <div className="set-password-page">
        {isSubmitting ? <Spinner theme={Spinner.themes.overlay} /> : null}
        <ContentContainer theme={ContentContainer.themes.narrow}>
          <Text
            className="set-password-page__title"
            elementName="h1"
            size={Text.sizes.headline2}
            theme={Text.themes.headline}
            italic
          >
            {title}
          </Text>
        </ContentContainer>
        <ContentContainer theme={ContentContainer.themes.veryNarrow}>
          <div className="set-password-page__content">
            {messages.map(message => (
              <Message key={message.text} {...message} />
            ))}

            {inputs.length > 0 ? (
              <React.Fragment>
                <Text
                  className="set-password-page__description"
                  elementName="p"
                >
                  {description}
                </Text>
                <form
                  action={endpoint}
                  className="set-password-page__form"
                  method="post"
                  onSubmit={handleSubmit}
                >
                  {hiddenInputs.map(input => (
                    <HiddenInput key={input.name} {...input} />
                  ))}
                  {inputs.map(input => (
                    <div
                      key={input.name}
                      className="set-password-page__form--input"
                    >
                      <TextInput {...input} />
                    </div>
                  ))}
                  <Button type="submit" text={submit} wide />
                </form>
              </React.Fragment>
            ) : null}
          </div>
        </ContentContainer>
      </div>
    </Layout>
  );
};

export default SetPasswordPage;
