import React from 'react';

import LinkButton from '@sats-group/ui-lib/react/link-button';
import Text from '@sats-group/ui-lib/react/text';

import List from '../list/list';

import type { UserDetailsList as Props } from './user-details-list.types';

const UserDetailsList: React.FunctionComponent<Props> = ({
  userDetailsList,
}) => (
  <div className="user-details-list">
    <List theme={List.themes.marginLarge}>
      {userDetailsList.map(({ label, link, value }) => (
        <div className="user-details-list__item" key={label + value}>
          <div className="user-details-list__label">
            <Text
              elementName="h3"
              size={Text.sizes.small}
              theme={Text.themes.emphasis}
            >
              {label}
            </Text>
          </div>
          <div className="user-details-list__text">
            <Text elementName="p">{value}</Text>
          </div>
          {link ? (
            <div className={'user-details-list__link'}>
              <LinkButton {...link} variant={LinkButton.variants.secondary} />
            </div>
          ) : null}
        </div>
      ))}
    </List>
  </div>
);

export default UserDetailsList;
