import React, { useState } from 'react';

import Button from '@sats-group/ui-lib/react/button';
import Checkbox from '@sats-group/ui-lib/react/checkbox';
import HiddenInput from '@sats-group/ui-lib/react/hidden-input';
import Modal from '@sats-group/ui-lib/react/modal';
import Text from '@sats-group/ui-lib/react/text';

import CleanLink from 'client/components/clean-link/clean-link';
import { fillRegistrationForm } from 'client/helpers/registration-form-filler';
import RichText from 'components/rich-text/rich-text';
import Spinner from 'components/spinner/spinner';

import RegistrationFormInputs from './registration-form-inputs';
import type { RegistrationForm as Props } from './registration-form.types';

const RegistrationForm: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = ({
  children,
  endpoint,
  header,
  hiddenInputs,
  isDebugMode,
  logIn,
  logInExplanation,
  marketingConsent,
  memberFormInputs,
  otherPayerCheckbox,
  payerFormInputs,
  submitLabel,
  terms,
  termsCheckbox,
  termsButton,
  termsModal,
}) => {
  const [isMarketingConsentModalOpen, setMarketingConsentModalIsOpen] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isOtherPayer, setIsOtherPayer] = useState(false);
  const [isTermsModalOpen, setTermsModalIsOpen] = useState(false);

  const handleSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
    if (isLoading || !terms) {
      e.preventDefault();
      return;
    }

    setIsLoading(true);
  };

  return (
    <div className="registration-form">
      <Text elementName="h3" size={Text.sizes.headline3}>
        {header}
      </Text>
      <form
        action={endpoint}
        className="registration-form__member-form"
        onSubmit={handleSubmit}
        method="POST"
      >
        {hiddenInputs.map(input => (
          <HiddenInput key={input.name} {...input} />
        ))}
        {isDebugMode ? (
          <React.Fragment>
            <Button
              text="Populate fields"
              onClick={() => fillRegistrationForm()}
            />
            <Button
              variant={Button.variants.cta}
              size={Button.sizes.large}
              type="submit"
              text={submitLabel}
            />
          </React.Fragment>
        ) : undefined}
        {otherPayerCheckbox && payerFormInputs ? (
          <Checkbox
            {...otherPayerCheckbox}
            onChange={e => setIsOtherPayer(e.currentTarget.checked)}
          />
        ) : null}
        {isLoading ? <Spinner theme={Spinner.themes.overlaySticky} /> : null}
        {children}
        {otherPayerCheckbox && payerFormInputs && isOtherPayer ? (
          <React.Fragment>
            {logIn ? (
              <React.Fragment>
                <Text>{logInExplanation}</Text>
                <CleanLink {...logIn} />
              </React.Fragment>
            ) : null}
            <RegistrationFormInputs {...payerFormInputs} />
          </React.Fragment>
        ) : null}
        <RegistrationFormInputs
          {...memberFormInputs}
          title={isOtherPayer ? memberFormInputs.title : undefined}
        />
        <Checkbox {...termsCheckbox} />
        {terms ? (
          <React.Fragment>
            {termsButton ? (
              <div>
                <Button
                  {...termsButton}
                  variant={Button.variants.secondary}
                  size={Button.sizes.small}
                  onClick={() => setTermsModalIsOpen(true)}
                />
              </div>
            ) : null}
            {isTermsModalOpen ? (
              <Modal {...termsModal} onClose={() => setTermsModalIsOpen(false)}>
                <RichText {...terms} />
              </Modal>
            ) : null}
          </React.Fragment>
        ) : null}
        {marketingConsent && marketingConsent.terms ? (
          <div className="registration-form__marketing-consent">
            <Checkbox {...marketingConsent.checkbox} />
            <div>
              <Button
                {...marketingConsent.button}
                variant={Button.variants.secondary}
                size={Button.sizes.small}
                onClick={() => setMarketingConsentModalIsOpen(true)}
              />
            </div>
            {isMarketingConsentModalOpen ? (
              <Modal
                {...marketingConsent.modal}
                onClose={() => setMarketingConsentModalIsOpen(false)}
              >
                <RichText {...marketingConsent.terms} />
              </Modal>
            ) : null}
          </div>
        ) : null}

        <Button
          variant={Button.variants.cta}
          size={Button.sizes.large}
          type="submit"
          disabled={!terms}
          text={submitLabel}
        />
      </form>
    </div>
  );
};

export default RegistrationForm;
