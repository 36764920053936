import cn from 'classnames';
import React from 'react';

import CroppedImage from '@sats-group/ui-lib/react/cropped-image';
import Text from '@sats-group/ui-lib/react/text';

import type { MyProfileCard as Props } from './my-profile-card.types';

const MyProfileCard: React.FunctionComponent<Props> = ({
  birthDate,
  birthDateLabel,
  image,
  memberId,
  name,
}) => (
  <div
    className={cn('my-profile-card', {
      'my-profile-card--columns': image,
    })}
  >
    {image ? (
      <div className="my-profile-card__image">
        <CroppedImage
          aspectRatio={CroppedImage.aspectRatios.square}
          {...image}
        />
      </div>
    ) : null}
    <div className="my-profile-card__content">
      <div>
        <Text theme={Text.themes.emphasis}>{name}</Text>
        <Text>{memberId}</Text>
      </div>
      {birthDate ? (
        <div>
          <Text size={Text.sizes.small} theme={Text.themes.emphasis}>
            {birthDateLabel}
          </Text>
          <Text>{birthDate}</Text>
        </div>
      ) : null}
    </div>
  </div>
);

export default MyProfileCard;
