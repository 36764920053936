import React from 'react';

import Text from '@sats-group/ui-lib/react/text';

import Author from 'components/author/author';
import BackgroundImage from 'components/background-image/background-image';
import Breadcrumbs from 'components/breadcrumbs/breadcrumbs';
import ContentContainer from 'components/content-container/content-container';
import Layout from 'components/layout/layout';
import RichText from 'components/rich-text/rich-text';

import type { InspirationalArticlePage as Props } from './inspirational-article-page.types';

const InspirationalArticlePage: React.FunctionComponent<Props> = ({
  author,
  body,
  breadcrumbs,
  category,
  categoryLabel,
  headerImage,
  heading,
  intro,
  layout,
}) => (
  <Layout {...layout}>
    <Breadcrumbs {...breadcrumbs} />
    <article className="inspirational-article-page">
      {headerImage ? (
        <div className="inspirational-article-page__header">
          <BackgroundImage
            {...headerImage}
            focusArea={BackgroundImage.focusAreas.Face}
            className="inspirational-article-page__header-image"
          />
        </div>
      ) : null}
      <section className="inspirational-article-page__main">
        <ContentContainer theme={ContentContainer.themes.medium}>
          <div className="inspirational-article-page__title">
            <Text
              elementName="h1"
              size={Text.sizes.headline2}
              theme={Text.themes.headline}
              italic
            >
              {heading}
            </Text>
          </div>
        </ContentContainer>
        <ContentContainer theme={ContentContainer.themes.narrow}>
          <Text elementName="p" size={Text.sizes.large}>
            {intro}
          </Text>
        </ContentContainer>
        <ContentContainer theme={ContentContainer.themes.narrow}>
          {author ? <Author {...author} /> : null}
          <section className="inspirational-article-page__category">
            <Text elementName="p" size={Text.sizes.small}>
              {categoryLabel}
            </Text>
            <Text
              elementName="p"
              size={Text.sizes.small}
              theme={Text.themes.emphasis}
            >
              {category}
            </Text>
          </section>
        </ContentContainer>
        {body ? (
          <ContentContainer theme={ContentContainer.themes.narrow}>
            <RichText {...body} />
          </ContentContainer>
        ) : null}
      </section>
    </article>
  </Layout>
);

export default InspirationalArticlePage;
