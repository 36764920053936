import FuzzySearch from 'fuzzy-search';
import React, { useEffect, useState } from 'react';

import Filter from '@sats-group/ui-lib/react/filter';
import Search from '@sats-group/ui-lib/react/search';
import Text from '@sats-group/ui-lib/react/text';
import Toggle from '@sats-group/ui-lib/react/toggle';

import debounce from 'client/helpers/debounce';
import isNumber from 'client/helpers/is-number';
import useUrlState from 'client/hooks/use-url-state';
import ContentContainer from 'components/content-container/content-container';
import Layout from 'components/layout/layout';
import LinkBoxList from 'components/link-box-list/link-box-list';
import TextInterpolator from 'components/text-interpolator/text-interpolator';

import type { LinkBoxListPage as Props } from './link-box-list-page.types';

const LinkBoxListPage: React.FunctionComponent<Props> = ({
  description,
  dropdownFilters,
  favoriteIds,
  favoritesToggle,
  layout,
  list,
  searchInput,
  searchOptions,
  showingTemplate,
  title,
}) => {
  const [filteredLength, setFilteredLength] = useState<number>();
  const [visibleLength, setVisibleLength] = useState<number>();
  const [searchResult, setSearchResult] = useState(list.list);

  const [{ favorites }, setState] = useUrlState();

  const isFavoritesEnabled = !!favorites;
  const toggleFavorites = (): void => {
    setState(({ favorites }) => ({
      favorites: favorites === 'true' ? '' : 'true',
    }));
  };

  const countText =
    isNumber(visibleLength) && isNumber(filteredLength) ? (
      <TextInterpolator
        template={showingTemplate}
        tokens={{
          quantity: String(visibleLength),
          total: String(filteredLength),
        }}
      />
    ) : null;

  const search = (searchTerm: string) => {
    if (!searchTerm) {
      setSearchResult(list.list);
      return;
    }

    const searcher = new FuzzySearch(
      list.list,
      searchOptions.searchKey,
      searchOptions.options
    );

    const result = searcher.search(searchTerm);
    setSearchResult(result);
  };

  useEffect(() => {
    if (favorites) {
      setSearchResult(
        searchResult.filter(item => favoriteIds.some(id => item.id === id))
      );
    } else {
      setSearchResult(list.list);
    }
  }, [favorites]);

  return (
    <Layout {...layout}>
      <div className="link-box-list-page">
        <div className="link-box-list-page__container">
          <form>
            <section className="link-box-list-page__section--dark">
              <ContentContainer theme={ContentContainer.themes.wide}>
                <div className="link-box-list-page__header">
                  <Text
                    elementName="h1"
                    size={Text.sizes.headline2}
                    theme={Text.themes.headline}
                    italic
                  >
                    {title}
                  </Text>
                  {description ? (
                    <Text elementName="p">{description}</Text>
                  ) : null}
                  <div className="link-box-list-page__search">
                    <Search
                      onChangeFunc={debounce(value => search(value), 300)}
                      theme={Search.themes.floating}
                      inputSize={Search.inputSizes.small}
                      variant={Search.variants.fixed}
                      {...searchInput}
                    />
                  </div>
                </div>
              </ContentContainer>
            </section>
            <section className="link-box-list-page__section--light">
              <ContentContainer theme={ContentContainer.themes.fullToWide}>
                <div className="link-box-list-page__filters-wrapper">
                  <div className="link-box-list-page__filters">
                    {dropdownFilters.map(filter => (
                      <Filter {...filter} key={filter.title} />
                    ))}
                    {favoritesToggle ? (
                      <div className="link-box-list-page__filters-favorites-toggle">
                        <Toggle
                          checked={isFavoritesEnabled}
                          onClick={() => toggleFavorites()}
                          {...favoritesToggle}
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
              </ContentContainer>
            </section>
          </form>
          <section className="link-box-list-page__section">
            <ContentContainer theme={ContentContainer.themes.wide}>
              <div className="link-box-list-page__content">
                <div
                  className="link-box-list-page__count"
                  data-test-visible-length={visibleLength}
                  data-test-filtered-length={filteredLength}
                >
                  <Text>{countText}</Text>
                </div>

                <LinkBoxList
                  onListChange={({ filteredLength, visibleLength }) => {
                    setFilteredLength(filteredLength);
                    setVisibleLength(visibleLength);
                  }}
                  {...list}
                  list={searchResult}
                />
              </div>
            </ContentContainer>
          </section>
        </div>
      </div>
    </Layout>
  );
};

export default LinkBoxListPage;
