import React from 'react';

import Link from '@sats-group/ui-lib/react/link';
import Text from '@sats-group/ui-lib/react/text';

import ArticleLinkCard from 'client/components/article-link-card/article-link-card';
import ContentContainer from 'client/components/content-container/content-container';
import Layout from 'client/components/layout/layout';

import type { MagazinePage as Props } from './magazine-page.types';

const MagazinePage: React.FunctionComponent<Props> = ({
  categories,
  layout,
  title,
}) => (
  <Layout {...layout}>
    <div className="magazine-page">
      <ContentContainer>
        <div className="magazine-page__title">
          <Text
            elementName="h1"
            size={Text.sizes.headline2}
            theme={Text.themes.headline}
            italic
          >
            {title}
          </Text>
        </div>
        {categories.length ? (
          <div className="magazine-page__categories">
            {categories.map(cat => (
              <div className="magazine-page__category" key={cat.title}>
                <div className="magazine-page__category-title">
                  <Text
                    elementName="h2"
                    size={Text.sizes.headline3}
                    theme={Text.themes.headline}
                    italic
                  >
                    {cat.title}
                  </Text>
                </div>
                {cat.description ? (
                  <div>
                    <Text
                      elementName="p"
                      size={Text.sizes.basic}
                      theme={Text.themes.normal}
                    >
                      {cat.description}
                    </Text>
                  </div>
                ) : null}
                <div className="magazine-page__category-cards">
                  {cat.cards.map(card => (
                    <div key={card.href}>
                      <ArticleLinkCard {...card} />
                    </div>
                  ))}
                </div>
                <div>
                  <Link {...cat.link} />
                </div>
              </div>
            ))}
          </div>
        ) : null}
      </ContentContainer>
    </div>
  </Layout>
);

export default MagazinePage;
