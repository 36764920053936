import React from 'react';

import Text from '@sats-group/ui-lib/react/text';

import ContentContainer from 'components/content-container/content-container';
import ElementInterpolator from 'components/element-interpolator/element-interpolator';
import SocialIcons from 'components/social-icons/social-icons';
import TableOfContents from 'components/table-of-contents/table-of-contents';

import type { Footer as Props } from './footer.types';

const Footer: React.FunctionComponent<Props> = ({
  brand,
  cookieLink,
  noticeTemplate,
  privacyLink,
  socialIcons,
  tableOfContents,
}) => (
  <footer className={`footer footer--${brand}`}>
    <div className="footer__inner">
      <ContentContainer theme={ContentContainer.themes.wide}>
        <div className="footer__content">
          {tableOfContents ? <TableOfContents {...tableOfContents} /> : null}
          <div className="footer__end">
            {socialIcons ? <SocialIcons {...socialIcons} /> : null}
            {cookieLink && privacyLink ? (
              <Text size={Text.sizes.interface} theme={Text.themes.emphasis}>
                <ElementInterpolator
                  template={noticeTemplate}
                  elements={{
                    cookieLink: (
                      <a href={cookieLink.href} key={cookieLink.href}>
                        {cookieLink.text}
                      </a>
                    ),
                    privacyLink: (
                      <a href={privacyLink.href} key={privacyLink.href}>
                        {privacyLink.text}
                      </a>
                    ),
                  }}
                />
              </Text>
            ) : null}
          </div>
        </div>
      </ContentContainer>
    </div>
  </footer>
);

export default Footer;
