import type { NodeRenderer } from '@contentful/rich-text-react-renderer';
import type { Block, Inline } from '@contentful/rich-text-types';
import { isDefined } from 'list-fns';
import React from 'react';

import ContentContainer from 'components/content-container/content-container';
import CTABanner from 'components/cta-banner/cta-banner';
import EmbedYoutube from 'components/embed-youtube/embed-youtube';
import FactBox from 'components/fact-box/fact-box';

import { toCtaBannerProps } from './to-cta-banner-props';
import { toEmbedYoutubeProps } from './to-embed-youtube-props';
import { toFactBoxProps } from './to-fact-box-props';
import { type ImageLink, toImageLinkProps } from './to-image-link-props';
import { toQuoteProps } from './to-quote-props';
import Quote from '../quote/quote';

const EmbeddedEntryRenderer: NodeRenderer = node => {
  const type = node?.data?.target?.sys?.contentType?.sys?.id;
  switch (type) {
    case 'factBox': {
      const props = toFactBoxProps(node);
      return props ? (
        <div data-node-type={node.nodeType}>
          <FactBox {...props} />
        </div>
      ) : null;
    }
    case 'list': {
      if (node.data.target.type === 'ImageLinkList') {
        const items = node.data.target.fields.items
          .map((item: Block | Inline) => {
            if (item.data.target.sys?.contentType?.sys?.id === 'webLink') {
              return toImageLinkProps(item);
            }
          })
          .filter(isDefined);

        return (
          <div className="rich-text__image-link-list">
            {items.map((item: ImageLink) => (
              <a
                className="rich-text__image-link"
                href={item.href}
                key={item.href}
              >
                <img src={item.imageSrc} alt={item.text} />
              </a>
            ))}
          </div>
        );
      }

      const props = toImageLinkProps(node);
      return props ? (
        <div data-node-type={node.nodeType}>
          <a className="rich-text__image-link" href={props.href}>
            <img src={props.imageSrc} alt={props.text} />
          </a>
        </div>
      ) : null;
    }
    case 'productBox': {
      const props = toCtaBannerProps(node);
      return props ? (
        <div data-node-type={node.nodeType}>
          <ContentContainer theme="narrow">
            <CTABanner {...props} />
          </ContentContainer>
        </div>
      ) : null;
    }
    case 'quote': {
      const props = toQuoteProps(node);
      return props ? (
        <div data-node-type={node.nodeType}>
          <ContentContainer theme="narrow">
            <Quote {...props} />
          </ContentContainer>
        </div>
      ) : null;
    }
    case 'webLink': {
      const props = toImageLinkProps(node);
      return props ? (
        <div data-node-type={node.nodeType}>
          <a className="rich-text__image-link" href={props.href}>
            <img src={props.imageSrc} alt={props.text} />
          </a>
        </div>
      ) : null;
    }
    case 'youtubeEmbed': {
      const props = toEmbedYoutubeProps(node);
      return props ? (
        <div data-node-type={node.nodeType}>
          <ContentContainer theme="narrow">
            <EmbedYoutube {...props} />
          </ContentContainer>
        </div>
      ) : null;
    }
    default: {
      return null;
    }
  }
};

export default EmbeddedEntryRenderer;
