import React, { useState } from 'react';

import Button from '@sats-group/ui-lib/react/button';
import HiddenInput from '@sats-group/ui-lib/react/hidden-input';
import Modal from '@sats-group/ui-lib/react/modal';
import Text from '@sats-group/ui-lib/react/text';

import Spinner from 'components/spinner/spinner';

import type { RegretTermination as Props } from './regret-termination-modal.types';

const RegretTermination: React.FunctionComponent<Props> = ({
  cancel,
  endpoint,
  hiddenInputs,
  modal,
  stopCancellation,
  text,
  title,
  toggleModal,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    if (isSubmitting) {
      event.preventDefault();
    }

    setIsSubmitting(true);
  };

  return (
    <div className="regret-termination">
      <Modal onClose={() => toggleModal(false)} {...modal}>
        {isSubmitting ? <Spinner theme={Spinner.themes.overlay} /> : null}
        <form action={endpoint} method="post" onSubmit={handleSubmit}>
          <Text
            className="regret-termination__modal-title"
            theme={Text.themes.headline}
            italic
            size={Text.sizes.headline2}
          >
            {title}
          </Text>
          {hiddenInputs.map(input => (
            <HiddenInput {...input} key={input.name} />
          ))}
          <Text className="regret-termination__modal-text">{text}</Text>
          <Button
            variant={Button.variants.ctaSecondaryWhite}
            onClick={() => toggleModal(false)}
            className="regret-termination__modal-button"
            text={cancel}
          />
          <Button
            type="submit"
            variant={Button.variants.cta}
            text={stopCancellation}
          />
        </form>
      </Modal>
    </div>
  );
};

export default RegretTermination;
