import cn from 'classnames';
import * as React from 'react';

import LinkButton from '@sats-group/ui-lib/react/link-button';
import Text from '@sats-group/ui-lib/react/text';

import { themes, variants } from './cta-banner.types';
import type { CTABanner as Props } from './cta-banner.types';

const CTABanner: React.FunctionComponent<React.PropsWithChildren<Props>> & {
  themes: typeof themes;
  variants: typeof variants;
} = ({ link, text, variant, theme = themes.dark, title, children }) => (
  <div
    className={cn('cta-banner', {
      [`cta-banner--theme-${theme}`]: theme,
      [`cta-banner--variant-${variant}`]: variant,
    })}
  >
    <Text
      className="cta-banner__title"
      elementName="h2"
      size={Text.sizes.headline3}
      theme={Text.themes.headline}
      italic={variant === variants.cta}
    >
      {title}
    </Text>
    <Text>{text}</Text>
    {children ? <>{children}</> : null}
    <div className="cta-banner__button">
      <LinkButton
        variant={
          variant === variants.cta
            ? LinkButton.variants.cta
            : theme === themes.dark
              ? LinkButton.variants.ctaSecondaryWhite
              : LinkButton.variants.ctaSecondary
        }
        {...link}
      />
    </div>
  </div>
);

CTABanner.themes = themes;
CTABanner.variants = variants;

export default CTABanner;
