import React from 'react';

import Text from '@sats-group/ui-lib/react/text';

import ArrowLink from 'components/arrow-link/arrow-link';
import ContentContainer from 'components/content-container/content-container';
import MyMembershipCards from 'components/my-membership-cards/my-membership-cards';
import MyPageLayout from 'components/my-page-layout/my-page-layout';

import type { MyPage as Props } from './my-page.types';

const MyPage: React.FunctionComponent<Props> = ({
  settings,
  activity,
  myPageLayout,
  otherMembers,
  primaryMemberships,
}) => (
  <MyPageLayout {...myPageLayout}>
    <div className="my-page">
      <div className="my-page__primary">
        <ContentContainer>
          <MyMembershipCards {...primaryMemberships} />
        </ContentContainer>
      </div>
      <ContentContainer>
        <div className="my-page__secondary">
          <nav aria-label={settings.title}>
            <Text
              elementName="h2"
              size={Text.sizes.headline2}
              theme={Text.themes.emphasis}
            >
              {settings.title}
            </Text>
            <div className="my-page__settings">
              <ArrowLink {...settings.paymentMethod} small />
              <ArrowLink {...settings.payments} small />
              <ArrowLink {...settings.profile} small />
              <ArrowLink {...settings.communicationSettings} small />
            </div>
          </nav>
          <nav aria-label={activity.title}>
            <Text
              elementName="h2"
              size={Text.sizes.headline2}
              theme={Text.themes.emphasis}
            >
              {activity.title}
            </Text>
            <div className="my-page__settings">
              <ArrowLink {...activity.upcomingTrainings} small />
              <ArrowLink {...activity.rewards} small />
              <ArrowLink {...activity.bookingRestriction} small />
            </div>
          </nav>
          {otherMembers.items.length ? (
            <div className="my-page__other-members">
              <Text
                elementName="h2"
                size={Text.sizes.headline2}
                theme={Text.themes.emphasis}
              >
                {otherMembers.title}
              </Text>
              <div className="my-page__other-members-list">
                {otherMembers.items.map(link => (
                  <ArrowLink {...link} key={link.href} />
                ))}
              </div>
            </div>
          ) : null}
        </div>
      </ContentContainer>
    </div>
  </MyPageLayout>
);

export default MyPage;
