import ChevronDown from '@sats-group/icons/24/arrow-down';
import React from 'react';
import Collapse from 'react-tiny-collapse';

import Button from '@sats-group/ui-lib/react/button';
import useToggle from '@sats-group/ui-lib/react/hooks/use-toggle';
import Text from '@sats-group/ui-lib/react/text';

import { mod } from 'client/helpers/add-bem-modifiers';
import CircleProgress from 'components/circle-progress/circle-progress';

import CheckoutProgressStep from './checkout-progress-step';
import type { CheckoutProgress as Props } from './checkout-progress.types';

const CheckoutProgress: React.FunctionComponent<Props> = ({
  checkoutProgressMobile,
  steps,
}) => {
  const [isOpen, toggle] = useToggle(false);

  return (
    <div className="checkout-progress">
      <div className="checkout-progress__current">
        <Text
          className="checkout-progress__indicator"
          theme={Text.themes.emphasis}
          size={Text.sizes.small}
        >
          {checkoutProgressMobile.currentStep}/
          {checkoutProgressMobile.totalSteps}
          <div className="checkout-progress__progress">
            <CircleProgress
              max={checkoutProgressMobile.totalSteps}
              value={checkoutProgressMobile.currentStep}
              size={50}
            />
          </div>
        </Text>

        <div>
          <Text size={Text.sizes.large}>{checkoutProgressMobile.title}</Text>
          {checkoutProgressMobile.nextStep ? (
            <Text
              className="checkout-progress__next-step"
              size={Text.sizes.small}
            >
              {checkoutProgressMobile.nextStep}
            </Text>
          ) : null}
        </div>
        <div className="checkout-progress__icon-button-container">
          <Button
            ariaLabel={
              isOpen
                ? checkoutProgressMobile.collapseSteps
                : checkoutProgressMobile.expandSteps
            }
            className={mod('checkout-progress__trigger-icon', {
              flipped: isOpen,
            })}
            leadingIcon={<ChevronDown />}
            onClick={toggle}
          />
        </div>
      </div>
      <Collapse isOpen={isOpen} className="checkout-progress__collapse">
        <ol className="checkout-progress__steps">
          {steps.map(step => (
            <CheckoutProgressStep key={step.title} {...step} />
          ))}
        </ol>
      </Collapse>
    </div>
  );
};

export default CheckoutProgress;
