import React from 'react';

import Link from '@sats-group/ui-lib/react/link';
import Text from '@sats-group/ui-lib/react/text';

import type { PaymentReceiptRow as Props } from './payment-receipt-group.types';

const PaymentReceiptRow: React.FunctionComponent<Props> = ({
  amount,
  date,
  link,
  status,
  statusCode,
}) => (
  <li className="payment-receipt-group__item">
    <div className="payment-receipt-group__date-wrapper">
      <Text
        className="payment-receipt-group__date"
        elementName="p"
        theme={Text.themes.emphasis}
        size={Text.sizes.small}
      >
        {date}
      </Text>
    </div>
    <div className="payment-receipt-group__price-wrapper">
      <Text
        className="payment-receipt-group__price"
        elementName="p"
        size={Text.sizes.small}
      >
        {amount}
      </Text>
    </div>
    <div className="payment-receipt-group__status-wrapper">
      <Text
        className={`payment-receipt-group__status payment-receipt-group__status--${statusCode}`}
        elementName="p"
        size={Text.sizes.small}
        theme={Text.themes.emphasis}
      >
        {status}
      </Text>
    </div>
    <Text className="payment-receipt-group__link" size={Text.sizes.small}>
      <Link {...link} rel="noopener noreferrer" target="_blank" />
    </Text>
  </li>
);

export default PaymentReceiptRow;
