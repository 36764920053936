import React from 'react';

import Chip from '@sats-group/ui-lib/react/chip';
import Text from '@sats-group/ui-lib/react/text';

import type { Categories as Props } from './categories.types';

const Categories: React.FunctionComponent<Props> = ({ categories, title }) => (
  <div className="categories">
    <Text
      className="categories__title"
      elementName="h2"
      theme={Text.themes.normal}
      inline
      size={Text.sizes.headline3}
    >
      {title}
    </Text>
    <div className="categories__links">
      {categories.map(category => (
        <Chip {...category} key={category.href} />
      ))}
    </div>
  </div>
);

export default Categories;
