import React, { useState } from 'react';
import Chat from '@sats-group/icons/24/comment';
import Mail from '@sats-group/icons/24/mail';
import Phone from '@sats-group/icons/24/phone';

import LinkButton from '@sats-group/ui-lib/react/link-button';
import Message from '@sats-group/ui-lib/react/message';
import Text from '@sats-group/ui-lib/react/text';

import SalesforceMessagingTrigger from '../salesforce-messaging-trigger/salesforce-messaging-trigger';

import RichText from '../rich-text/rich-text';

import ContactUsItem from './contact-us-item';
import type { ContactUs as Props } from './contact-us.types';
import SalesforceChatTrigger from '../salesforce-chat-trigger/salesforce-chat-trigger';

const ContactUs: React.FunctionComponent<Props> = ({
  chat,
  form,
  message,
  mode,
  phone,
  title,
}) => {
  const [chatMessage, setChatMessage] = useState<
    Parameters<typeof Message>[0] | undefined
  >(chat.message);

  return (
    <div className="contact-us">
      <Text
        elementName="h2"
        theme={Text.themes.emphasis}
        size={Text.sizes.headline3}
      >
        {title}
      </Text>
      {message ? (
        <div className={'contact-us__message'}>
          <Message {...message} />
        </div>
      ) : null}
      <div className="contact-us__items">
        <ContactUsItem
          actions={
            <React.Fragment>
              {chat.salesforceChatTrigger ? (
                <SalesforceChatTrigger
                  {...chat.salesforceChatTrigger}
                  onMessage={setChatMessage}
                />
              ) : null}
              {chat.salesforceMessagingTrigger ? (
                <SalesforceMessagingTrigger
                  {...chat.salesforceMessagingTrigger}
                  onMessage={setChatMessage}
                />
              ) : null}
            </React.Fragment>
          }
          defaultOpen={mode !== 'closed'}
          icon={<Chat />}
          id="contact-us__chat"
          message={chatMessage}
          text={
            chat.operationsInformation ? (
              <RichText {...chat.operationsInformation} />
            ) : undefined
          }
          title={chat.title}
        />
        {form.showForm ? (
          <ContactUsItem
            actions={
              <LinkButton
                {...form.link}
                variant={LinkButton.variants.secondary}
              />
            }
            defaultOpen={mode !== 'closed'}
            icon={<Mail />}
            id="contact-us__form"
            text={<Text>{form.formDescription}</Text>}
            title={form.title}
          />
        ) : null}

        <ContactUsItem
          actions={
            phone.phoneNumber ? (
              <LinkButton
                href={`tel:${phone.phoneNumber}`}
                text={phone.phoneNumber}
                variant={LinkButton.variants.secondary}
              />
            ) : undefined
          }
          defaultOpen={false}
          icon={<Phone />}
          id="contact-us__phone"
          text={
            phone.operationsInformation ? (
              <RichText {...phone.operationsInformation} />
            ) : undefined
          }
          title={phone.title}
        />
      </div>
    </div>
  );
};

export default ContactUs;
