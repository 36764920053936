import cn from 'classnames';
import React from 'react';
import Text from '@sats-group/ui-lib/react/text';

import type { Quote as Props } from './quote.types';
import DynamicImage from '../dynamic-image/dynamic-image';

const Quote: React.FunctionComponent<Props> = ({
  background,
  focusable,
  image,
  quote,
  signature,
  tall,
}) => (
  <div
    className={cn('quote', {
      'quote--dark': background === 'dark',
      'quote--light': background === 'light',
      'quote--tall': tall,
    })}
    tabIndex={focusable ? 0 : undefined}
  >
    <div
      className={cn('quote__content', {
        'quote__content--split': image && !tall,
        'quote__content--tall': tall,
      })}
    >
      {image ? (
        <div className="quote__image">
          <DynamicImage
            {...image}
            aspectRatio="cropped-image--square"
            defaultHeight={198}
            defaultWidth={198}
            theme={DynamicImage.themes.round}
          />
        </div>
      ) : null}
      <div className="quote__text">
        <div
          className={cn('quote__quote', {
            'quote__quote--dark': background === 'light',
            'quote__quote--light': background === 'dark',
          })}
        >
          <Text italic size={Text.sizes.large}>
            “
          </Text>
          <Text italic size={Text.sizes.large}>
            <blockquote>{quote}</blockquote>&nbsp;”
          </Text>
        </div>
        <div
          className={cn('quote__signature', {
            'quote__signature--dark': background === 'light',
            'quote__signature--light': background === 'dark',
          })}
        >
          <Text size={Text.sizes.small}>–&nbsp;{signature}</Text>
        </div>
      </div>
    </div>
  </div>
);

export default Quote;
