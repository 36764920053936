import SvgBack from '@sats-group/icons/24/back';
import SvgImages from '@sats-group/icons/32/images';
import cn from 'classnames';
import React from 'react';

import Text from '@sats-group/ui-lib/react/text';

import DynamicImage from '../dynamic-image/dynamic-image';

import type { ClubElement as Props } from './club-element.types';

const ClubElement: React.FunctionComponent<Props> = ({
  image,
  link,
  name,
  primaryMetadata,
  secondaryMetadata,
  small,
}) => (
  <div className="club-element">
    {React.createElement(
      link ? 'a' : 'div',
      link
        ? {
            className: cn([
              'club-element__content',
              'club-element__content--clickable',
              small
                ? 'club-element__content--small'
                : 'club-element__content--link',
            ]),
            href: link.href,
          }
        : {
            className: cn('club-element__content', {
              'club-element__content--small': small,
            }),
          },
      <React.Fragment>
        <div className="club-element__primary">
          <Text size={Text.sizes.headline3} theme={Text.themes.emphasis}>
            {name}
          </Text>
          {primaryMetadata.length ? (
            <div className="club-element__metadata">
              {primaryMetadata.map(meta => (
                <div key={meta.key}>
                  {meta.title ? (
                    <Text size={Text.sizes.small} theme={Text.themes.emphasis}>
                      {meta.title}
                    </Text>
                  ) : null}
                  {meta.info.map(item => (
                    <Text key={item} size={Text.sizes.small}>
                      {item}
                    </Text>
                  ))}
                </div>
              ))}
            </div>
          ) : null}
        </div>
        {small ? null : (
          <div className="club-element__secondary">
            {secondaryMetadata.map(meta => (
              <div key={meta.key}>
                {meta.title ? (
                  <Text size={Text.sizes.small}>{meta.title}</Text>
                ) : null}
                {meta.info.map(item => (
                  <Text key={item} size={Text.sizes.small}>
                    {item}
                  </Text>
                ))}
              </div>
            ))}
          </div>
        )}
        <div className="club-element__tertiary">
          {image ? (
            <DynamicImage {...image} />
          ) : (
            <div className="club-element__image-fallback">
              <SvgImages />
            </div>
          )}
        </div>
        {small ? null : link ? (
          <div className="club-element__action">
            <Text size={Text.sizes.small}>{link.text}</Text>
            <div className="club-element__action-flourish">
              <SvgBack />
            </div>
          </div>
        ) : null}
      </React.Fragment>
    )}
  </div>
);

export default ClubElement;
