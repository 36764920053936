import React from 'react';

import Message from '@sats-group/ui-lib/react/message';
import Text from '@sats-group/ui-lib/react/text';
import VisuallyHidden from '@sats-group/ui-lib/react/visually-hidden';

import ContentBlocks from 'components/content-blocks/content-blocks';
import ContentContainer from 'components/content-container/content-container';
import Hero from 'components/hero/hero';
import Layout from 'components/layout/layout';
import NavigationCard from 'components/navigation-card/navigation-card';
import UpcomingTrainingsList from 'components/upcoming-trainings-list/upcoming-trainings-list';
import UspList from 'components/usp-list/usp-list';

import type { LandingPage as Props } from './landing-page.types';

const LandingPage: React.FunctionComponent<Props> = ({
  blocks,
  greeting,
  hero,
  layout,
  shortcuts,
  title,
  upcomingClasses,
  uniqueSellingPoints,
}) => (
  <Layout {...layout}>
    <div className="landing-page">
      {hero ? (
        <div className="landing-page__hero">
          <Hero {...hero} />
        </div>
      ) : (
        <VisuallyHidden>
          <h1>{title}</h1>
        </VisuallyHidden>
      )}

      {uniqueSellingPoints ? (
        <ContentContainer theme={ContentContainer.themes.wide}>
          <UspList {...uniqueSellingPoints} />
        </ContentContainer>
      ) : null}

      {greeting ? (
        <ContentContainer theme={ContentContainer.themes.wide}>
          <div className="landing-page__greeting">
            <Text
              elementName="h2"
              size={Text.sizes.headline3}
              theme={Text.themes.normal}
            >
              {greeting}
            </Text>
          </div>
        </ContentContainer>
      ) : null}

      {shortcuts.length ? (
        <ContentContainer theme={ContentContainer.themes.wide}>
          <div className="landing-page__shortcuts">
            {shortcuts.map(item => (
              <NavigationCard {...item} key={item.href} tall />
            ))}
          </div>
        </ContentContainer>
      ) : null}

      {upcomingClasses ? (
        <ContentContainer theme={ContentContainer.themes.wide}>
          <div className="landing-page__upcoming-classes">
            <Text
              elementName="h2"
              theme={Text.themes.normal}
              size={Text.sizes.large}
            >
              {upcomingClasses.title}
            </Text>

            {upcomingClasses.messages.map(message => (
              <Message {...message} key={message.text} />
            ))}

            {upcomingClasses.classes.map(entry => (
              <div key={entry.title}>
                <UpcomingTrainingsList
                  {...entry}
                  titleProps={{
                    elementName: 'h3',
                    size: Text.sizes.large,
                    theme: Text.themes.emphasis,
                  }}
                />
              </div>
            ))}
          </div>
        </ContentContainer>
      ) : null}

      {blocks ? (
        <ContentContainer theme={ContentContainer.themes.wide}>
          <div className="landing-page__blocks">
            <ContentBlocks {...blocks} />
          </div>
        </ContentContainer>
      ) : null}
    </div>
  </Layout>
);
export default LandingPage;
