import React from 'react';

import Message from '@sats-group/ui-lib/react/message';

import ContentContainer from 'components/content-container/content-container';
import Hero from 'components/hero/hero';
import PreviewLayout from 'components/preview-layout/preview-layout';

import type { HeroPreview as Props } from './hero-preview.types';

const HeroPreview: React.FunctionComponent<Props> = ({ hero }) => (
  <PreviewLayout>
    <div className="hero-preview">
      {hero ? (
        <Hero {...hero} />
      ) : (
        <ContentContainer>
          <Message text="No hero props" />
        </ContentContainer>
      )}
    </div>
  </PreviewLayout>
);

export default HeroPreview;
