import React from 'react';

import Text from '@sats-group/ui-lib/react/text';

import ContentContainer from 'components/content-container/content-container';
import Layout from 'components/layout/layout';

import type { LeadsFormConfirmationPage as Props } from './leads-form-confirmation-page.types';

const LeadsFormConfirmationPage: React.FunctionComponent<Props> = ({
  intro,
  layout,
  title,
}) => (
  <Layout {...layout}>
    <div className="leads-form-page">
      <ContentContainer>
        <Text
          className="leads-form-page__text"
          elementName="h1"
          theme={Text.themes.headline}
          italic
          size={Text.sizes.headline2}
        >
          {title}
        </Text>
        <Text
          className="leads-form-page__text"
          elementName="h2"
          size={Text.sizes.headline3}
        >
          {intro}
        </Text>
      </ContentContainer>
    </div>
  </Layout>
);

export default LeadsFormConfirmationPage;
