import Check from '@sats-group/icons/24/check';
import React from 'react';

import Text from '@sats-group/ui-lib/react/text';

import type { MembershipExplanation as Props } from './membership-explanation.types';

const MembershipExplanation: React.FunctionComponent<Props> = ({
  benefits,
}) => (
  <div className="membership-explanation">
    <ul className="membership-explanation__benefits">
      {benefits.map(benefit => (
        <li className="membership-explanation__benefit" key={benefit}>
          <div className="membership-explanation__flourish">
            <Check />
          </div>
          <Text>{benefit}</Text>
        </li>
      ))}
    </ul>
  </div>
);

export default MembershipExplanation;
