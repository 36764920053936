/// <reference lib="dom" />
import ArrowDownIcon from '@sats-group/icons/24/arrow-down';
import cn from 'classnames';
import React, { useEffect, useRef, useState } from 'react';

import Text from '@sats-group/ui-lib/react/text';

import ContentContainer from 'components/content-container/content-container';
import RichText from 'components/rich-text/rich-text';

import type { FactBox as Props } from './fact-box.types';

const FactBox: React.FunctionComponent<Props> = ({ body, heading }) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const [factBoxHeight, setFactBoxHeight] = useState<string>();
  const factBox = useRef<HTMLDivElement>(null);
  const toggle = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (factBox.current && toggle.current) {
      // NOTE: Adding the height of the toggle div to the total height so the toggle div doesn't cover the text.
      setFactBoxHeight(
        `${factBox.current.scrollHeight + toggle.current.offsetHeight}px`
      );
    }
  }, [factBox, toggle]);

  return (
    <ContentContainer theme={ContentContainer.themes.narrow}>
      <div
        ref={factBox}
        className={cn('fact-box', {
          'fact-box--open': expanded,
          'fact-box--closed': !expanded,
        })}
        style={expanded ? { maxHeight: factBoxHeight } : undefined}
      >
        <div className="fact-box__heading">
          <Text
            elementName="h2"
            size={Text.sizes.headline3}
            theme={Text.themes.emphasis}
          >
            {heading}
          </Text>
        </div>
        <RichText {...body} />
        <div
          className={cn('fact-box__gradient', {
            'fact-box__gradient--fade-out': expanded,
            'fact-box__gradient--fade-in': !expanded,
          })}
        />
        <div
          ref={toggle}
          className="fact-box__toggle"
          onClick={() => setExpanded(!expanded)}
        >
          <div className="fact-box__toggle-border" />
          <div
            className={cn('fact-box__icon-wrapper', {
              'fact-box__icon-wrapper--open': expanded,
              'fact-box__icon-wrapper--close': !expanded,
            })}
          >
            <ArrowDownIcon />
          </div>
          <div className="fact-box__toggle-border" />
        </div>
      </div>
    </ContentContainer>
  );
};

export default FactBox;
