import { useEffect } from 'react';

import type {
  EmbeddedService,
  HiddenPreChatFields,
} from '../types/salesforce-bootstrap-embedded-service';

export type Init = Parameters<EmbeddedService['init']>;
export type Prechat = HiddenPreChatFields;
export type Settings = EmbeddedService['settings'];

const initEmbeddedMessaging = (
  init: Init,
  settings: Settings,
  prechat: HiddenPreChatFields,
  postInit: () => void
) => {
  try {
    if (!window.embeddedservice_bootstrap) {
      return;
    }

    window.embeddedservice_bootstrap.settings.language = settings.language;

    window.addEventListener('onEmbeddedMessagingReady', () => {
      if (!window.embeddedservice_bootstrap) {
        return;
      }

      const chatDataContainer = document.querySelector('[data-chat]');
      if (chatDataContainer) {
        try {
          const memberData = JSON.parse(chatDataContainer.innerHTML);

          window.embeddedservice_bootstrap.prechatAPI.setVisiblePrechatFields({
            _firstName: {
              value: memberData.firstName,
              isEditableByEndUser: true,
            },
            _lastName: {
              value: memberData.lastName,
              isEditableByEndUser: true,
            },
            _email: {
              value: memberData.email,
              isEditableByEndUser: true,
            },
            MobilePhone: {
              value: memberData.mobile,
              isEditableByEndUser: true,
            },
          });
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error('Error loading Embedded Messaging: ', err);
        }
      }
    });

    window.addEventListener('onEmbeddedMessagingReady', () => {
      if (!window.embeddedservice_bootstrap) {
        return;
      }
      // eslint-disable-next-line no-console
      console.log('Received the onEmbeddedMessagingReady event…');

      const chatDataContainer = document.querySelector('[data-chat]');
      if (chatDataContainer) {
        try {
          const memberData = JSON.parse(chatDataContainer.innerHTML);
          window.embeddedservice_bootstrap.prechatAPI.setHiddenPrechatFields({
            ...prechat,
            ExerpMemberId: memberData.id,
          });
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error('Error loading Embedded Messaging: ', err);
        }
      } else {
        window.embeddedservice_bootstrap.prechatAPI.setHiddenPrechatFields(
          prechat
        );
      }
    });

    window.embeddedservice_bootstrap.init(...init);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('Error loading Embedded Messaging: ', err);
  }

  postInit();
};

export const useSalesforceMessaging = (
  src: string,
  init: Init,
  settings: Settings,
  prechat: HiddenPreChatFields,
  postInit: () => void
) =>
  useEffect(() => {
    if (window.embeddedservice_bootstrap) {
      initEmbeddedMessaging(init, settings, prechat, postInit);
    } else {
      const script = document.createElement('script');
      script.onload = () =>
        initEmbeddedMessaging(init, settings, prechat, postInit);
      script.setAttribute('src', src);
      postInit();
      document.body.appendChild(script);
    }
  }, []);
