import React from 'react';

import LinkCard from '@sats-group/ui-lib/react/link-card';
import Text from '@sats-group/ui-lib/react/text';

import CheckoutConfirmationSummary from 'components/checkout-confirmation-summary/checkout-confirmation-summary';
import CheckoutLayout from 'components/checkout-layout/checkout-layout';
import ContentContainer from 'components/content-container/content-container';
import ElementInterpolator from 'components/element-interpolator/element-interpolator';
import List from 'components/list/list';
import RichText from 'components/rich-text/rich-text';

import type { CheckoutConfirmationPage as Props } from './checkout-confirmation-page.types';

const CheckoutConfirmationPage: React.FunctionComponent<Props> = ({
  email,
  error,
  layout,
  links = [],
  passwordTemplate,
  passwordTitle,
  personalDetails,
  membershipSummary,
  text,
}) => (
  <CheckoutLayout {...layout}>
    <div className="checkout-confirmation-page">
      {error ? (
        <ContentContainer theme={ContentContainer.themes.narrow}>
          <div className="checkout-confirmation-page__error">{error}</div>
        </ContentContainer>
      ) : null}
      <ContentContainer theme={ContentContainer.themes.narrow}>
        {membershipSummary ? (
          <div className="checkout-confirmation-page__membership-summary">
            <CheckoutConfirmationSummary {...membershipSummary} />
          </div>
        ) : null}

        {personalDetails.map(details => (
          <div
            className="checkout-confirmation-page__personal-information"
            key={details.title}
          >
            <Text
              elementName="h2"
              size={Text.sizes.headline3}
              theme={Text.themes.normal}
            >
              {details.title}
            </Text>
            <dl className="checkout-confirmation-page__list">
              {details.fields.map(({ label, value }) => (
                <React.Fragment key={label}>
                  <dt>{label}</dt>
                  <dd>{value}</dd>
                </React.Fragment>
              ))}
            </dl>
          </div>
        ))}

        {passwordTemplate && email ? (
          <div className="checkout-confirmation-page__password">
            <Text elementName="h2" theme={Text.themes.normal}>
              {passwordTitle}
            </Text>
            <ElementInterpolator
              template={passwordTemplate}
              elements={{
                email: (
                  <Text elementName="span" theme={Text.themes.emphasis}>
                    {email}
                  </Text>
                ),
              }}
            />
          </div>
        ) : null}

        {text ? (
          <div className="checkout-confirmation-page__text">
            <RichText {...text} />
          </div>
        ) : null}

        {links.length ? (
          <List
            className="checkout-confirmation-page__links"
            theme={List.themes.grid}
          >
            {links.map(link => (
              <LinkCard key={link.href} {...link} />
            ))}
          </List>
        ) : null}
      </ContentContainer>
    </div>
  </CheckoutLayout>
);

export default CheckoutConfirmationPage;
