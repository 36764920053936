import React, { useState } from 'react';

import Button from '@sats-group/ui-lib/react/button';
import Select from '@sats-group/ui-lib/react/select';
import Text from '@sats-group/ui-lib/react/text';

import CheckoutLayout from 'components/checkout-layout/checkout-layout';
import CheckoutSummaryContent from 'components/checkout-summary-content/checkout-summary-content';
import CheckoutSummaryWrapper from 'components/checkout-summary-wrapper/checkout-summary-wrapper';
import ContentContainer from 'components/content-container/content-container';
import InfoBox from 'components/info-box/info-box';
import RegistrationForm from 'components/registration-form/registration-form';
import Spinner from 'components/spinner/spinner';

import type { RegistrationPage as Props } from './registration-page.types';

const RegistrationPage: React.FC<Props> = ({
  editorialCard,
  form,
  layout,
  priceExplanation,
  startDate: startDates,
  summary,
}) => {
  const [showStartDates, setShowStartDates] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // NOTE: If the options were a list of links instead,
  // we could get rid of this handler completely.
  const handleChangeStartDate = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setIsLoading(true);
    const url = new URL(window.location.href);
    url.searchParams.delete('sessionId');

    if (event.target.value) {
      url.searchParams.set('startDate', event.target.value);
    } else {
      url.searchParams.delete('startDate');
    }

    window.location.assign(url.href);
  };

  return (
    <CheckoutLayout {...layout}>
      <div className="registration-page">
        <ContentContainer>
          <div className="registration-page__content">
            {editorialCard ? (
              <div className="registration-page__card">
                <InfoBox {...editorialCard} />
              </div>
            ) : null}

            <div className="registration-page__main">
              {isLoading ? (
                <Spinner theme={Spinner.themes.centered} />
              ) : (
                <>
                  <div>
                    <Text elementName="h2" size={Text.sizes.headline3}>
                      {startDates.title}
                    </Text>
                    {startDates.description ? (
                      <Text>{startDates.description}</Text>
                    ) : null}
                    {startDates.select.options &&
                    startDates.select.options.length ? (
                      <div className="registration-page__start-date-input">
                        {showStartDates ? (
                          <Select
                            {...startDates.select}
                            onChange={handleChangeStartDate}
                          />
                        ) : (
                          <Button
                            variant={Button.variants.secondary}
                            size={Button.sizes.small}
                            text={startDates.button}
                            onClick={() => setShowStartDates(true)}
                          />
                        )}
                      </div>
                    ) : null}
                  </div>
                  <div>
                    <RegistrationForm {...form} />
                  </div>
                </>
              )}
            </div>

            <CheckoutSummaryWrapper>
              <CheckoutSummaryContent {...summary} isLoading={isLoading} />
              {priceExplanation ? (
                <div className="registration-page__price-explanation">
                  <Text elementName="p" size={Text.sizes.small}>
                    {priceExplanation}
                  </Text>
                </div>
              ) : null}
            </CheckoutSummaryWrapper>
          </div>
        </ContentContainer>
      </div>
    </CheckoutLayout>
  );
};

export default RegistrationPage;
