import CloseIcon from '@sats-group/icons/24/close';
import React from 'react';
import ReactDOM from 'react-dom';

import useEscape from '@sats-group/ui-lib/react/hooks/use-escape';
import { useIsMounted } from '@sats-group/ui-lib/react/hooks/use-is-mounted';
import TabTrapper from '@sats-group/ui-lib/react/modal/tab-trapper';

import type { FullscreenModal as Props } from './fullscreen-modal.types';

const FullscreenModal: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = ({ children, closeButtonText, hide = () => {} }) => {
  const isMounted = useIsMounted();

  useEscape(hide);

  // NOTE: It's really important that the first render in the client matches the server HTML (`null`). Otherwise ReactDOM.hydrate messes up the HTML.
  if (typeof document === 'undefined' || !isMounted) {
    return null;
  }

  return ReactDOM.createPortal(
    <div className="fullscreen-modal">
      <div className="fullscreen-modal__inner">
        <TabTrapper isActive={true}>
          <div className="fullscreen-modal__nav">
            <button
              className="fullscreen-modal__close"
              aria-label={closeButtonText}
              onClick={hide}
            >
              <CloseIcon />
            </button>
          </div>
          <div className="fullscreen-modal__children">{children}</div>
        </TabTrapper>
      </div>
      <div className="fullscreen-modal__background" onClick={hide} />
    </div>,
    document.body
  );
};

export default FullscreenModal;
