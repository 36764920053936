import React from 'react';

import type { YoutubePlayer as Props } from './youtube-player.types';

const YoutubePlayer: React.FunctionComponent<Props> = ({
  autoplay,
  id,
  title,
}) => (
  <div className="youtube-player">
    <iframe
      allow="autoplay; picture-in-picture;"
      allowFullScreen
      src={`https://www.youtube-nocookie.com/embed/${id}?autoplay=${
        autoplay ? 1 : 0
      }&rel=0`}
      title={title}
    />
  </div>
);

export default YoutubePlayer;
