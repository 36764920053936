import type { Radio } from '@sats-group/ui-lib/react/radio/radio.types';

import type { ObjectValues } from 'client/types';

export const themes = {
  horizontal: 'horizontal',
} as const;

export type RadioList = {
  defaultCheckedValue?: string;
  label: string;
  options: Radio[];
  showOnlyOneCustomError?: boolean;
  theme?: ObjectValues<typeof themes>;
};
