import Text from '@sats-group/ui-lib/react/text';
import cn from 'classnames';
import React from 'react';

import type { Tagline as Props } from './tagline.types';

const Tagline: React.FC<Props> = ({ onDark, words }) => (
  <div className="tagline">
    {words.map((word, index) => (
      <div
        className={cn('tagline__word', {
          'tagline__word--last': index === words.length - 1,
          'tagline__word--on-dark': onDark,
        })}
        key={word}
      >
        <Text size={Text.sizes.large} theme="headline">
          {word}
        </Text>
      </div>
    ))}
  </div>
);

export default Tagline;
