import React from 'react';

import Expander from '@sats-group/ui-lib/react/expander';
import Text from '@sats-group/ui-lib/react/text';

import ContactUs from 'components/contact-us/contact-us';
import ContentBlocks from 'components/content-blocks/content-blocks';
import ContentContainer from 'components/content-container/content-container';
import Layout from 'components/layout/layout';
import MemberCareCategoryGroup from 'components/member-care-category-group/member-care-category-group';
import RichText from 'components/rich-text/rich-text';
import SearchModal from 'components/search-modal/search-modal';

import type { MemberCarePage as Props } from './member-care-page.types';
import Hero from 'client/components/hero/hero';
import { HeroVariant } from 'client/components/hero/hero.types';

const MemberCarePage: React.FunctionComponent<Props> = ({
  hero,
  blocks,
  categories,
  categoriesTitle,
  contactUs,
  highlights,
  layout,
  search,
}) => (
  <Layout {...layout}>
    <div className="member-care-page">
      {hero ? <Hero {...hero} variant={HeroVariant.SplitInfo} /> : null}
      <ContentContainer>
        <section className="member-care-page__search">
          {search ? (
            <div className="member-care-page__search">
              <SearchModal {...search} size={SearchModal.sizes.fullWidth} />
            </div>
          ) : null}
        </section>
        <section className="member-care-page__categories">
          <Text
            className="member-care-page__categories-title"
            elementName="h2"
            theme={Text.themes.emphasis}
            size={Text.sizes.headline3}
          >
            {categoriesTitle}
          </Text>
          <MemberCareCategoryGroup categories={categories} />
        </section>
        {highlights ? (
          <section className="member-care-page__highlights">
            <Expander {...highlights} itemRenderer={RichText} />
          </section>
        ) : null}
      </ContentContainer>
      {blocks ? (
        <section className="member-care-page__blocks-section">
          <div className="member-care-page__blocks">
            <ContentContainer>
              <ContentBlocks {...blocks} />
            </ContentContainer>
          </div>
        </section>
      ) : null}
    </div>
    <ContentContainer>
      <div className="member-care-page__contact-us">
        <ContactUs {...contactUs} />
      </div>
    </ContentContainer>
  </Layout>
);

export default MemberCarePage;
